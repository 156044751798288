<div class="modal-header">
  <div class="modal-header-with-alert-icon add-gap">
    <div class="modal-heading">
      <h1 class="modal-header-title">Having issues with your asset?</h1>
      <p class="modal-header-subtitle">Please tell us more</p>
    </div>
  </div>
  <button class="modal-header-action" type="button" (click)="close()">
    <img src="assets/24x24/close.svg" alt="" />
  </button>
</div>
<div class="modal-body">
  <div class="container">
    <div class="width-100">
      <h3>Asset Details</h3>
      <div class="row width-100">
        <div class="col">
          <p>Asset Name</p>
          <p>Asset Code</p>
        </div>
        <div class="col">
          <p>:</p>
          <p>:</p>
        </div>
        <div class="col">
          <p>
            {{ assetDetails?.assetName || "-" | titlecase }}
          </p>
          <p>
            {{ assetDetails?.assetCode || "-" }}
          </p>
        </div>
      </div>
      <form [formGroup]="popupForm">
        <h3>Select Issue(s)*</h3>
        <div>
          <app-drop-down
            from="select_an_issue"
            [options]="issueList"
            titleName="subCategory"
            valueName="id"
            title="Select  Issue"
            formArrayName="subCategories"
            (value)="getValue($event, 'subCategory')"
            (index)="getIndex($event)"
            (emitIssueDiscription)="getOtherIssue($event)"
            [isDropDownofSelectIssue]="true"
          ></app-drop-down>
        </div>
        <div>
          <h3>Describe Your Issue *</h3>
          <app-textarea
            cols="30"
            rows="10"
            formControlName="transactionReason"
            characterCounter
            maxLength="255"
            placeholder="Tell us a little more about the issue..."
            [isRequired]="true"
            [isLabelPresent]="false"
          >
          </app-textarea>
        </div>
        <div>
          <h3>Mark Asset For*</h3>
          <app-generic-drop-down
            [options]="optionsRelatedToTypeOfRepair"
            [shouldEmitOption]="true"
            (value)="getTypeOfRepair($event)"
            title="Select Option
          "
          ></app-generic-drop-down>
        </div>
        <div *ngIf="displayVendorList">
          <h3>Vendor*</h3>
          <app-generic-drop-down
            [options]="vendorNames"
            valueName="id"
            titleName="name"
            (value)="onSelectVendor($event)"
            title="Select Vendor"
            [shouldEmitDisplayValueAndEmittedValue]="true"
          >
          </app-generic-drop-down>
        </div>
        <div *ngIf="displayAssigneeList">
          <h3>Assignee</h3>
          <app-generic-drop-down
            [options]="assigneeNames"
            (value)="onSelectAssignee($event)"
            title="Select Assignee"
            [shouldEmitOption]="true"
          >
          </app-generic-drop-down>
        </div>
        <div>
          <h3>Attachment (Optional)</h3>
          <app-file-upload
            (emitFileInfo)="selectedFiles($event)"
            formControlName="file"
            [multiple]="true"
            [hasImageAndVideo]="true"
            [fileTypesForValidations]="fileTypes"
          >
            <p>Image (JPG, under 2 MB, Up to 4 images)</p>
            <p>Video (MP4, under 20 MB, Up to 1 video)</p>
          </app-file-upload>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="button">
    <app-button
      theme="primary"
      size="xsmall"
      (click)="onCheckOutForRepair()"
      [disabled]="disableButton || popupForm.invalid"
      >Check Out</app-button
    >
  </div>
</div>
