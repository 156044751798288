<div class="asset" *ngIf="assets && assets.assetTypeName; else noAssetsFound">
  <div class="asset-content">
    <p class="asset-name">
      <span *ngIf="assets.assetTypeName.substring(0,3) === 'Eko'"
        ><img src="assets/eko-images/eko_logo.svg" alt="eko-logo" />
      </span>
      <span>{{assets.assetTypeName}}</span>
    </p>
    <p class="asset-description">{{assets.modelName}}</p>
    <p class="asset-modal">{{assets.assetCode}}</p>
  </div>
  <div class="asset-image" *ngIf="assets.assetTypeName.substring(0,3)!=='Eko'">
    <img
      src="assets/80x80/{{assets?.assetTypeName?.toLowerCase()}}.svg"
      alt="asset-icon"
      height="80"
    />
  </div>
  <div class="asset-image" *ngIf="assets.assetTypeName.substring(0,3)==='Eko'">
    <img src="assets/80x80/eko device cr2.svg" alt="asset-icon" height="80" />
  </div>
</div>

<ng-template #noAssetsFound>
  <div class="asset no-asset">
    <div class="asset-content">
      <p class="asset-name">No assets assigned</p>
      <p class="asset-description">Generate ticket to request an asset</p>
    </div>
  </div>
</ng-template>
