import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';

@Component({
  selector: 'app-user-profile-details',
  templateUrl: './user-profile-details.component.html',
  styleUrls: ['./user-profile-details.component.scss'],
  animations: [
    trigger('basicAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class UserProfileDetailsComponent implements OnInit {
  constructor(private commonHttpService: CommonHttpService) {}

  user: any;
  loaderView: boolean = false;

  ngOnInit(): void {
    this.loaderView = true;
    this.commonHttpService.getUserProfile().subscribe({
      next: (res) => {
        this.user = Object(res).data;
        this.loaderView = false;
      },
      error: () => {
        this.loaderView = false;
      },
    });
  }
}
