import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ActivatedRoute,
  ActivationEnd,
  NavigationEnd,
  ParamMap,
  Router,
} from '@angular/router';
import {
  ASSETS_MENU,
  ASSET_SEARCH_OPTIONS,
  ASSET_STATUS,
  ISearchData,
  getAssetsTableDataInventory,
  PAGINATION_DATA,
  ASSET_SORT_COLUMNS,
  ROUTES,
  EKO_ASSET_STATUS,
  PROVIDER,
  ASSET_INFO_HEADINGDS,
} from '@common/interfaces';

import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { UploadDownloadService } from 'libs/common/src/lib/service/upload-download.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-inventory',
  templateUrl: './admin-inventory.component.html',
  styleUrls: ['./admin-inventory.component.scss'],
})
export class AdminInventoryComponent implements OnInit {
  tableDetails: any;
  @Input() navigationOption: any;
  @Output() addAsset = new EventEmitter();
  titleForClientDropDown!: string;
  isAddAssetClicked = false;

  assetTag: string = '';
  ticketDate: string = '';
  assetName: string = '';
  status: string = '';
  searchColumn!: string;
  searchText!: string;
  ticketRaisedBy: string = '';
  allocatedFilter: boolean = true;
  availableFilter: boolean = false;
  outForRepairFilter: boolean = false;
  allFilter: boolean = false;
  pages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  PAGINATION_DATA = PAGINATION_DATA;
  pageSize = '5';
  button: boolean = true;
  AssetTeamtableDetails: any;
  assetStatusClicked = false;
  assetMenu = ASSETS_MENU;
  assetStatus = ASSET_STATUS;
  params!: string;
  count!: number;
  ASSET_SEARCH_OPTIONS = ASSET_SEARCH_OPTIONS;
  loaderView: boolean = false;
  textOnButton = 'Download Asset List';
  isEkoAdmin: boolean = 
      JSON.parse(localStorage.getItem('userInfo') || '{}')?.roles[0] ===
      'EKO-ADMIN';
  currentProvier: string = this.isEkoAdmin ? PROVIDER.EKO_CLIENT : PROVIDER.CODITAS;
  currentProvider: any;
  constructor(
    private commonHttpService: CommonHttpService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private uploadAndDownloadService: UploadDownloadService,
    private toastrService: ToastrService
  ) {}
  @ViewChild('app-asset-details') assetDetails: any;
  sortDigit = 'DESC';
  currentPageNumber = 1;
  tableConfig: any = [
    {
      title: 'Asset Code',
      hasMultiKeys: true,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        isAssetTag: true,
        keys: [
          { 
            key: 'assetCode', 
            from: 'asset', 
            handleAction: (assetId: string) => { 
              this.navigateToAssetDetails(assetId);
            }
          },
          { key: 'name', from: 'asset_type' },
        ],
      },
    },
    {
      title: 'Asset Location',
      icon: 'sort-asc',
      hasMultiKeys: false,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      config: {
        keys: { key: 'location', from: 'asset' },
        isAsset: true,
        isLoanStatus: true,
      },
    },
    {
      title: 'Asset name',
      hasMultiKeys: false,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: { key: 'name', from: 'asset' },
        isAsset: true,
      },
    },
    {
      title: 'Asset status',
      hasMultiKeys: false,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: { key: 'loan_status', from: 'asset' },
        isAsset: true,
        isLoanStatus: true,
      },
    },
    {
      title: 'Loanee',
      hasMultiKeys: true,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: [
          { key: 'firstName', from: 'user' },
          { key: 'lastName', from: 'user' },
          { key: 'email', from: 'user' },
        ],
        isUserInfo: true,
      },
    },
  ];
  tableConfigForCoditas = this.tableConfig;
  editAssetClicked = false;
  assetId!: string;
  ngOnInit(): void {
    if (this.router.url.includes('asset/')) {
      const length = this.router.url.length;
      this.assetId = this.router.url.split('/')[length - 1];
      this.editAssetClicked = true;
      this.isAddAssetClicked = false;
    } else if (this.router.url.endsWith('asset')) {
      this.isAddAssetClicked = true;
      this.editAssetClicked = false;
    }
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        this.assetId = Object(event).snapshot.params.id;
        if (this.assetId) {
          this.editAssetClicked = true;
        }
        if (
          this.router.url.endsWith('inventory') ||
          this.router.url.includes('inventory?')
        ) {
          this.isAddAssetClicked = false;
          this.editAssetClicked = false;
        }
      }
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['provider'] === 'EKO CLIENT') {
        this.currentProvider = PROVIDER.EKO_CLIENT;
        this.titleForClientDropDown = 'Eko';
        this.tableConfig = [
          {
            title: 'Asset Code',
            hasMultiKeys: true,
            sortAction: (col: string) => {
              this.handleSortAction(col);
            },
            icon: 'sort-asc',
            config: {
              isAssetTag: true,
              keys: [
                { 
                  key: 'assetCode', 
                  from: 'asset', 
                  handleAction: (assetId: string) => { 
                    this.navigateToAssetDetails(assetId);
                  }
                },
                { key: 'name', from: 'asset_type' },
              ],
            },
          },
          {
            title: 'Asset name',
            hasMultiKeys: false,
            sortAction: (col: string) => {
              this.handleSortAction(col);
            },
            icon: 'sort-asc',
            config: {
              keys: { key: 'name', from: 'asset' },
              isAsset: true,
            },
          },
          {
            title: 'Asset Location',
            hasMultiKeys: false,
            icon: 'sort-asc',
            sortAction: (col: string) => {
              this.handleSortAction(col);
            },
            config: {
              keys: { key: 'location', from: 'asset' },
              isAsset: true,
              isLoanStatus: true,
            },
          },
          {
            title: 'Assigned To',
            hasMultiKeys: true,
            sortAction: (col: string) => {
              this.handleSortAction(col);
            },
            icon: 'sort-asc',
            config: {
              keys: [
                { key: 'firstName', from: 'user' },
                { key: 'lastName', from: 'user' },
                { key: 'email', from: 'user' },
              ],
              isUserInfo: true,
            },
          },
          {
            title: 'Onboarding Date',
            hasMultiKeys: false,
            sortAction: (col: string) => {
              this.handleSortAction(col);
            },
            icon: 'sort-asc',
            config: {
              keys: { key: 'createdAt', from: 'asset' },
              isAsset: true,
              isLoanStatus: true,
              isDate: true,
            },
          },
          {
            title: 'Asset status',
            hasMultiKeys: false,
            sortAction: (col: string) => {
              this.handleSortAction(col);
            },
            icon: 'sort-asc',
            config: {
              keys: { key: 'loan_status', from: 'asset' },
              isAsset: true,
              isLoanStatus: true,
            },
          },
        ];
      } else {
        this.currentProvider = PROVIDER.CODITAS;
        this.titleForClientDropDown = 'Coditas';
        this.tableConfig = this.tableConfigForCoditas;
      }
      this.pageSize = params['size'] || '5';
      this.searchText = params['search'] || '';
      this.searchColumn = params['column'] || '';
      this.currentPageNumber = params['page'] || 1;
      this.status =
        params['status'] && params['status'] !== 'ALL' ? params['status'] : '';
      const param = {
        status: this.status,
        pageNumber: params['page'] || '1',
      };
      const pageSize = sessionStorage.getItem('pageSize');
      if (pageSize) {
        this.pageSize = pageSize;
      }
      this.loadCounters(this.currentProvider);
      this.getAllAssets(param);
      this.changeDetectorRef.detectChanges();
    });
  }
  ngDoCheck() {
    this.assetStatusClicked =
      this.router.url.includes('asset-details') ||
      this.editAssetClicked ||
      false;
    if (this.assetId) {
      this.editAssetClicked = true;
    }
  }

  search(searchData: ISearchData) {
    this.searchColumn = searchData.filterBy;
    this.searchText = searchData.searchStr?.trim();
    this.textOnButton = 'Download Asset List';
    this.getAllAssets();
  }

  loadCounters(provider?: string) {
    this.commonHttpService
      .getAssetStatus(this.currentProvider)
      .subscribe((res: any) => {
        const data = Object(res).data;

        if (this.currentProvider === PROVIDER.CODITAS) {
          this.assetMenu[0].count = res.data?.assignedAssetCount;
          this.assetMenu[1].count = res.data?.unAssignedAssetCount;
          this.assetMenu[2].count = res.data?.outForRepairAssetCount;
          this.assetMenu[3].count = res.data?.inHouseRepairAssetCount;
          this.assetMenu[4].count = res.data?.disabledAssetCount;
          this.assetMenu[5].count = res.data?.totalAssets;
        } else {
          this.assetMenu = EKO_ASSET_STATUS;
          this.assetMenu[0].count = res.data?.unAssignedAssetCount;
          this.assetMenu[1].count = res.data?.assignedAssetCount;
          this.assetMenu[2].count = res.data?.outForRepairAssetCount + res.data?.inHouseRepairAssetCount;
          this.assetMenu[3].count = res.data?.disabledAssetCount;
          this.assetMenu[4].count = res.data?.totalAssets;
        }
      });
  }
  getAllAssets(param?: any) {
    this.loaderView = true;
    const params = {
      searchColumn: this.searchColumn || '',
      searchText: this.searchText || '',
      assetStatus: this.status,
      pageNumber: param?.pageNumber || '1',
      itemsPerPage: this.pageSize || '5',
      sortColumn: param?.sortColumn,
      sortDirection: param?.sortDirection,
      provider: this.currentProvider,
    };
    if (this.currentProvider === 'EKO CLIENT' && this.status === 'REPAIR') {
      params.assetStatus = 'REPAIR';
    }

    const loadRequest = this.commonHttpService.getAssetsByStatus(params);
    if (loadRequest) {
      loadRequest.subscribe({
        next: (response) => {
          const ticketsData = Object(response).data;
          this.AssetTeamtableDetails = getAssetsTableDataInventory(
            ticketsData.assets
          );
          this.count = ticketsData.count;
          this.commonService.searchParam.next({
            searchColumn: param?.searchColumn,
            searchText: param?.searchText,
            sortColumn: param?.sortColumn,
            sortDirection: param?.sortDirection,
          });
          this.loaderView = false;
        },
        error: () => {
          this.loaderView = false;
        },
      });
      this.currentPageNumber = param?.pageNumber;
      this.sortDigit = this.sortDigit === 'DESC' ? 'ASC' : 'DESC';
      this.changeDetectorRef.detectChanges();
    }
  }

  resetassetDetailVerifyData() {
    this.commonService.resetAssetDetailVerifyData();
  }

  pageSizeChange(pageSize: string) {
    this.pageSize = pageSize;
    this.getAllAssets();
  }

  handleSortAction(col: string) {
    const param = {
      sortColumn: '',
      sortDirection: this.sortDigit,
      pageNumber: this.currentPageNumber,
    };
    const iconConfig = this.tableConfig.filter(
      (response: any) => response.title === col
    );
    iconConfig[0].icon = this.sortDigit === 'ASC' ? 'sort-asc' : 'sort-desc';

    switch (col) {
      case 'Asset Code':
        param.sortColumn = ASSET_SORT_COLUMNS.ASSET_TAG;
        break;

      case 'Asset Location':
        param.sortColumn = ASSET_SORT_COLUMNS.ASSET_LOCATION;
        break;

      case 'Asset name':
        param.sortColumn = ASSET_SORT_COLUMNS.ASSET_NAME;
        break;

      case 'Loanee':
        param.sortColumn = ASSET_SORT_COLUMNS.LOANEE;
        break;

      case 'Asset status':
        param.sortColumn = ASSET_SORT_COLUMNS.LOAN_STATUS;
        break;

      case 'Assigned To':
        param.sortColumn = ASSET_SORT_COLUMNS.ASSIGNED_TO;
        break;

      case 'Onboarding Date':
        param.sortColumn = ASSET_SORT_COLUMNS.ONBOARDING_DATE;
        break;

      default:
        param.sortColumn = '';
        break;
    }
    this.getAllAssets(param);
  }
  editAssetClick(event: Event) {
    // this.addAssetClicked.emit(true)
  }
  addAssetIsClicked(event: Event) {
    this.isAddAssetClicked = true;
    this.editAssetClicked = false;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.endsWith('inventory')) {
          this.isAddAssetClicked = false;
        } else if (event.url.endsWith('asset')) {
          this.isAddAssetClicked = true;
        }
      }
    });
  }

  downloadAssetList(param?: any) {
    this.commonService.makeDownloadReportButtonDisabled.next({
      from: ASSET_INFO_HEADINGDS.ASSET_STATUS,
      flag: true,
    });

    const params = {
      searchColumn: this.searchColumn || '',
      searchText: this.searchText || '',
      assetStatus: this.status,
      pageNumber: param?.pageNumber || '1',
      itemsPerPage: this.pageSize || '5',
      sortColumn: param?.sortColumn,
      sortDirection: param?.sortDirection,
      isDownload: true,
      provider: this.currentProvider,
    };

    if (this.currentProvider === 'EKO CLIENT' && this.status === 'REPAIR') {
      params.assetStatus = 'OUT_FOR_REPAIR';
    }

    this.commonHttpService.downloadAssetList(params).subscribe({
      next: (response) => {
        const { downloadURL } = Object(response).data;
        const databaseFileDownloadEl = document.createElement('a');

        databaseFileDownloadEl.href = downloadURL;
        databaseFileDownloadEl.target = '_blank';
        databaseFileDownloadEl.click();

        this.commonService.makeDownloadReportButtonDisabled.next({
          from: ASSET_INFO_HEADINGDS.ASSET_STATUS,
          flag: false,
        });
      },

      error: (error: HttpErrorResponse) => {
        const { errorList } = error.error;
        this.toastrService.error(
          errorList || 'Could not able to download the asset reports.',
          'Download Failed!',
          { toastClass: 'toaster-wrapper' }
        );
        this.commonService.makeDownloadReportButtonDisabled.next({
          from: ASSET_INFO_HEADINGDS.ASSET_STATUS,
          flag: false,
        });
      },
    });
  }

  onClientChange(client: string) {
    if (client === 'Eko') {
      this.assetMenu = EKO_ASSET_STATUS;
      this.loadCounters(PROVIDER.EKO_CLIENT);
      this.currentProvider = PROVIDER.EKO_CLIENT;
      const queryParams = { provider: 'EKO CLIENT' };
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
      });
    } else {
      this.assetMenu = ASSETS_MENU;
      this.currentProvider = PROVIDER.CODITAS;
      const queryParams = { provider: 'CODITAS' };
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
      });
    }

    this.loadCounters();
    this.getAllAssets(this.params);
  }

  navigateToAssetDetails(assetId: string) {
    const buildURL = this.commonService.buildURL(
      `${ROUTES.ASSET_DETAILS + assetId}`,
      {
        page: this.currentPageNumber,
        size: this.pageSize,
        search: this.searchText,
        column: this.searchColumn,
        status: this.status,
        provider: this.currentProvider
      },
    )
    this.router.navigateByUrl(buildURL);
  }
}
