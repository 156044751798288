import { Dialog, DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  IFileInfo,
  IIssuesList,
  IRaiseAnIssueFormData,
  PROGRESS_STATUS,
  ROUTES,
} from '@common/interfaces';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { GenericConfirmationPopUpComponent } from '../generic-confirmation-pop-up/generic-confirmation-pop-up.component';
import { GenericPopUpComponent } from '../generic-pop-up/generic-pop-up.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-raise-ticket-modal',
  templateUrl: './raise-ticket-modal.component.html',
  styleUrls: ['./raise-ticket-modal.component.scss'],
})
export class RaiseTicketModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  fileTypes = [
    'image/png',
    'image/jpg',
    'image/svg+xml',
    'image/jpeg',
    'video/mp4',
    'video/mpeg',
  ];

  locations: any;
  successUploadCounts: any[] = [];
  userDetails: any;
  options: any[] = ['No records found...'];
  asset: any;
  asset_type: string = '';
  asset_id: string[] = [];
  isDisable: boolean = false;
  subCategoryOptions!: IIssuesList[];
  issueOptions: any;
  assetTypeId!: string;
  subCategories: string[] = [];
  isAssetSelected: boolean = false;
  issueArray: string[] = [];
  countData!: number[];
  filesArray: IFileInfo[] = [];
  issues!: string;
  uploadInProgress: boolean = false;
  whiteSpaceRegex = '^s';
  assetTypes: any;

  otherOptionId!: string;
  otherIssueDescription!: string;
  fileKeys: string[] = [];

  // raise-an-issue form
  popupForm = new UntypedFormGroup({
    location: new UntypedFormControl('', [Validators.required]),
    option: new UntypedFormControl('', [Validators.required]),
    subCategories: new UntypedFormControl(this.issueArray, [
      Validators.required,
    ]),
    description: new UntypedFormControl('', [
      Validators.required,
      CommonService.cannotContainSpace,
      Validators.minLength(3),
    ]),
    file: new UntypedFormControl(''),
  });

  constructor(
    private commonHttpService: CommonHttpService,
    private commonService: CommonService,
    private dialogRef: DialogRef,
    private dialog: Dialog,
    private router: Router,
    @Inject(DIALOG_DATA) public data: any
  ) {}

  isAdmin!: boolean;

  ngDoCheck() {
    if (
      this.issueArray?.includes(this.otherOptionId) &&
      !this.popupForm?.get('issueDescription')
    ) {
      this.popupForm.addControl(
        'issueDescription',
        new UntypedFormControl('', [
          Validators.required,
          CommonService.cannotContainSpace,
          Validators.minLength(3),
        ])
      );
    } else if (
      !this.issueArray?.includes(this.otherOptionId) &&
      this.popupForm?.get('issueDescription')
    ) {
      this.popupForm.removeControl('issueDescription');
    }
  }
  ngOnInit(): void {
    this.isAdmin =
      JSON.parse(localStorage.getItem('userInfo') as string).roles[0] ===
      'ADMIN';
    this.getLocations();
    this.getAssetTypes();
    this.commonHttpService.getUserAsset().subscribe({
      next: (res) => {
        this.userDetails = Object(res).data;
        if (this.userDetails.length) {
          this.options.pop();
          for (let i = 0; i < this.userDetails.length; i++) {
            this.asset = this.userDetails[i].asset;
            const string = `${this.asset.name}`;
            this.options.push({
              assetName: this.asset.name,
              assetCode: this.asset.assetCode,
              assetType: this.asset?.asset_type?.assetTypeCode,
            });
          }
        }
      },
    });
  }

  getLocations() {
    this.commonHttpService.getAssestLocationsList().subscribe({
      next: ({ data }: any) => {
        if (data) {
          this.locations = Object.entries(data).map(
            ([locationKey, locationValue]) => ({
              locationKey,
              locationValue,
            })
          );
        }
      },
      error: (err) => {},
    });
  }

  getAssetTypes() {
    this.commonHttpService.getAllAssetType().subscribe({
      next: (response) => {
        this.assetTypes = Object(response).data;
      },
    });
  }
  close() {
    this.popupForm.reset();
    this.closeModal.emit('raise-ticket-modal');
  }
  getValue(value: any, type: string) {
    if (type === 'option') {
      this.asset_id = [];
      this.issueArray = [];
      this.issues = '';
      this.commonService.resetMultiSelect.next(true);
      this.popupForm.get('option')?.setValue(value?.assetName);

      this.isAssetSelected = true;
      this.assetTypes.forEach((asset: any) => {
        if (value.assetType === asset?.assetTypeCode) {
          this.assetTypeId = asset?.id;
        }
      });
    }
    if (type === 'subCategory') {
      this.popupForm.get('subCategories')?.setValue(value);
      this.issueArray = [...value[0]];
      this.issues = value[1];
      this.isAssetSelected = true;
    }
    if (type === 'location') {
      this.popupForm.get('location')?.setValue(value);
    }
  }

  getIndex(index: number) {
    this.asset_id.push(this.userDetails[index].asset.id);
    this.asset_type = this.userDetails[index].asset.asset_type.id;

    const endPoint = `?ticketType=REPAIR/REPLACEMENT&assetTypeId=${this.asset_type}`;
    this.commonHttpService.getSubCategories(endPoint).subscribe((response) => {
      this.subCategoryOptions = [...response.data].map((item) => {
        if (item.subCategory === 'Other') {
          this.otherOptionId = item.id;
        }
        item.isChecked = false;
        return item;
      });
    });
  }

  onSubmit() {
    this.openConfirmationDialog();
  }
  upload(raiseAnIssueFormData: IRaiseAnIssueFormData): void {
    this.commonHttpService.raiseIssue(raiseAnIssueFormData).subscribe({
      next: (response) => {
        if (response instanceof HttpResponse) {
          const { data } = response.body;
          this.issueArray = data.ticket_sub_categories.map(
            (category: { sub_category: { subCategory: any } }) => {
              return category.sub_category.subCategory;
            }
          );
          const alertBoxConfig = {
            ticketId: data.id,
            content: 'Your ticket has been generated successfully.',
            color: '#ECFDF3',
            icon: 'tick',
            status: 'success',
            linkText: 'View Ticket',
            showOnlySnackbar: false,
            hasMargin: true,
          };
          const popUpConfig = {
            headerIcon: 'success',
            title: 'Ticket Generated',
            subtitle:
              'Ticket for reported an issue has been generated successfully',
            data: {
              'Asset Details': {
                'Asset Name': data.assetName,
                'Asset ID': data.assetCode,
              },
              'Issue Description': {
                'Issue Type': this.issues,
                "Description": data.description,
              },
              'Ticket Details': {
                'Ticket No.': `#${data.ticketNumber}`,
              },
            },
            primaryButtonText: 'Proceed to Dashboard',
            secondaryButtonText: 'View Ticket',
          };
          const dialogRef = this.dialog.open<string>(GenericPopUpComponent, {
            panelClass: 'center-dialog-box',
            backdropClass: 'backdrop',
            data: popUpConfig,
            width: '50%',
          });
          dialogRef.closed.subscribe((result) => {
            if (result === 'Proceed to Dashboard') {
              if (this.isAdmin) {
                this.router.navigate([ROUTES.ASSET_TEAM_DASHBOARD_PERSONAL]);
                this.dialogRef.close(alertBoxConfig);
              } else {
                this.router.navigate([ROUTES.USER_DASHBOARD]);
                this.dialogRef.close(alertBoxConfig);
              }
            } else if (result === 'View Ticket') {
              this.router.navigate([
                ROUTES.TICKET_DETAILS + data.id,
              ]);

              this.dialogRef.close(alertBoxConfig);
            }
          });
        }
      },
      error: (error: HttpErrorResponse) => {
        const selectedAsset = [...this.userDetails].filter(
          (el) => el.asset.name === this.popupForm.value.option
        )[0];
        const alertBoxConfig = {
          content: 'Ticket could not be generated.',
          color: '#FFFBFA',
          icon: 'fail',
          status: 'fail',
          linkText: '',
          showOnlySnackbar: false,
          popupAlertConfig: {
            assetDetails: {
              assetName: this.popupForm.value.option,
              assetId: selectedAsset.asset.assetCode,
            },
            issueDescription: {
              description: this.popupForm.value.description,
            },
            isErrorState: true,
          },
        };

        const popUpConfig = {
          headerIcon: 'error',
          title: 'Ticket could not be generated',
          subtitle:
            'Looks like you lost your connection. Please check your connection and try again.',
          data: {
            'Asset Details': {
              'Asset Name': this.popupForm.value.option,
              'Asset ID': selectedAsset.asset.assetCode,
            },
            'Issue Description': {
              'Issue Type': this.issues,
              Description: this.popupForm.value.description,
            },
          },
          primaryButtonText: 'Cancel',
          secondaryButtonText: 'Try Again',
        };
        const dialogRef = this.dialog.open<string>(GenericPopUpComponent, {
          panelClass: 'center-dialog-box',
          backdropClass: 'backdrop',
          data: popUpConfig,
          width: '50%',
        });
        dialogRef.closed.subscribe((result) => {
          if (result === 'Try Again') {
            this.isDisable = false;
          } else {
            this.dialogRef.close();

            if (this.isAdmin) {
              this.router.navigate([ROUTES.ASSET_TEAM_DASHBOARD]);
            } else {
              this.router.navigate([ROUTES.USER_DASHBOARD]);
            }
          }
        });
      },
    });
  }

  closeDialog() {
    this.popupForm.reset();
    this.dialogRef.removePanelClass('dialog-box');
    this.dialogRef.addPanelClass('close-dialog');
    const current = this;
    setTimeout(() => {
      current.dialogRef.close();
    }, 1000);
  }
  openConfirmationDialog() {
    const dialogRef = this.dialog.open<string>(
      GenericConfirmationPopUpComponent,
      {
        panelClass: 'center-dialog-box',
        height: '11.75rem',
        backdropClass: 'backdrop',
        disableClose: true,
        data: {
          header: 'Do you want to submit the form?',
          description: 'This action cannot be undone',
          primaryBtn: 'Submit Form',
          secondaryBtn: 'Go Back',
          image: 'cautionImage',
        },
      }
    );
    dialogRef.closed.subscribe((response:any) => {

      if (response.buttonText === 'Submit Form') {
        this.isDisable = true;
        this.popupForm.get('subCategories')?.setValue(this.issueArray);

        const raiseAnIssueFormData = {} as IRaiseAnIssueFormData;
        raiseAnIssueFormData['location'] = this.popupForm.value.location;
        raiseAnIssueFormData['description'] =
          this.popupForm.value.description.trim().replace(/\n/g,' ');
        raiseAnIssueFormData['type'] = 'REPAIR/REPLACEMENT';
        raiseAnIssueFormData['assets'] = [...this.asset_id];
        raiseAnIssueFormData['title'] = 'Issue Reported';
        raiseAnIssueFormData['subCategories'] = [...this.issueArray];
        raiseAnIssueFormData['tempFileKeys'] = [...this.fileKeys];
        raiseAnIssueFormData['assetType'] = this.assetTypeId;
        if (
          this.otherIssueDescription?.trim()?.length &&
          this.issueArray.includes(this.otherOptionId)
        ) {
          raiseAnIssueFormData['issueDescription'] =
            this.popupForm.value.issueDescription.trim();
        }

        if (raiseAnIssueFormData) {
          this.upload(raiseAnIssueFormData);
        }
      }
    });
  }
  openCloseConfirmationDialog() {
    const dialogRef = this.dialog.open<string>(
      GenericConfirmationPopUpComponent,
      {
        panelClass: 'center-dialog-box',
        height: '11.75rem',
        backdropClass: 'backdrop',
        disableClose: true,
        data: {
          header: 'Are you sure you want to cancel?',
          description: 'All progress in this session will be lost',
          primaryBtn: 'Discard',
          secondaryBtn: 'Go Back',
          image: 'cautionImage',
        },
      }
    );
    dialogRef.closed.subscribe((response) => {
      if (response === 'Discard') {
        this.popupForm.reset();
        this.dialogRef.removePanelClass('dialog-box');
        this.dialogRef.addPanelClass('close-dialog');
        const current = this;
        setTimeout(() => {
          current.dialogRef.close();
        }, 1000);
      }
    });
  }

  async selectedFiles(files: IFileInfo[]) {
    if (files.length) {
      for (const fileData of files) {
        await this.uploadFile([fileData]);
        // await this.uploadFile([fileData], files);
      }
    }
  }


  getOtherIssue(otherIssueDescription: string) {
    this.otherIssueDescription = otherIssueDescription;

    this.popupForm
      .get('issueDescription')
      ?.setValue(this.otherIssueDescription);
  }

  async uploadFile(files: IFileInfo[]) {
    this.uploadInProgress = true;

    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (this.shouldUploadFile(file) && !this.isAlreadyUploaded(file)) {
        await this.uploadAndHandleFile(file);
      } else {
        files[index].showProgressBar = false;
      }
    }
  }

  shouldUploadFile(file: IFileInfo): boolean {
    return (
      file.progressStatus !== PROGRESS_STATUS.FAILED &&
      file.progressValue !== 100
    );
  }

  async uploadAndHandleFile(file: IFileInfo) {
    this.uploadInProgress = true;

    const subscription = this.commonService.getProgress().subscribe((value) => {
      file.progressValue = value;
      file.showRemoveFileBtn = false;
    });

    try {
      const fileInfo = [{ originalname: file.originalname, mimetype: file.mimetype, size: file.size }];
      const loadRequest: Observable<Object> = this.commonHttpService.uploadFiles(fileInfo);
      this.uploadInProgress = false;
      subscription?.unsubscribe();
      loadRequest.subscribe({
        next: async (response: any) => {
          if (this.shouldUploadFile(file)  && !this.isAlreadyUploaded(file)) {
            file.progressValue = 50;
            const s3response = await this.handleFileUpload(response.presignedUrls, [file]);

            if (response.presignedUrls.length === s3response.length) {
              file.progressValue = 100;
              file.showRemoveFileBtn = false;
              file.progressStatus = PROGRESS_STATUS.SUCCESS;

              const tempFileKeys = response.presignedUrls[0].key;
              this.fileKeys.push(tempFileKeys);
            }
          }
        },
        error: (error) => {
          file.showRemoveFileBtn = true;
          file.progressStatus = PROGRESS_STATUS.FAILED;
        }
      })
    } catch (error) {
      console.error(error);
    }
  }

  isAlreadyUploaded(file: IFileInfo): boolean {
    return file.progressValue === 100;
  }

  handleFileUpload(
    preSignedUrls: { key: string; presignedUrl: string }[],
    files: IFileInfo[]
  ) {
    return this.commonHttpService.uploadFilesToPresignedUrls(
      preSignedUrls,
      files
    );
  }
}
