<div class="modal-header">
  <div class="modal-header-with-alert-icon add-gap">
    <div
      class="modal-alert-icon {{
        assetDetails?.isError ? 'danger' : 'success'
      }}"
    >
      <img
        src="assets/24x24/{{
          assetDetails?.isError ? 'alert-circle.svg' : 'check-circle.svg'
        }}"
        alt=""
      />
    </div>
    <div class="modal-heading">
      <h1 class="modal-header-title">
        {{ assetDetails?.title }}
      </h1>
      <p class="modal-header-subtitle">
        {{ assetDetails?.description }}
      </p>
    </div>
  </div>
  <button class="modal-header-action" type="button" (click)="close()">
    <img src="assets/24x24/close.svg" alt="" />
  </button>
</div>
<div class="modal-body add-edit-asset-box">
  <div class="container">
    <div class="width-100">
      <h3>Asset details</h3>
      <div class="row width-100">
        <div class="col">
          <p>Asset Code</p>
          <p>Asset Category</p>
        </div>
        <div class="col">
          <p>:</p>
          <p>:</p>
        </div>
        <div class="col">
          <p>
            {{ assetDetails?.assetCode | uppercase }}
          </p>
          <p>
            {{ assetDetails?.assetName.split(" ")[0] || "-" }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="button" *ngIf="!assetDetails?.isError">
    <app-button
      class="width-50"
      size="xsmall"
      theme="secondary"
      (click)="goToAssetDetails()"
      >View Asset Details
    </app-button>
  </div>
</div>
