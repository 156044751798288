import { animate, style, transition, trigger } from '@angular/animations';
import { Dialog } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ASSETS_MENU, MODALS, TICKETS_MENU } from '@common/interfaces';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { ModalService } from 'libs/common/src/lib/service/modal.service';
@Component({
  selector: 'app-asset-team-dashboard-personal',
  templateUrl: './asset-team-dashboard-personal.component.html',
  styleUrls: ['./asset-team-dashboard-personal.component.scss'],
  animations: [
    trigger('basicAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class AssetTeamDashboardPersonalComponent implements OnInit {
  ticketStatus = TICKETS_MENU;
  assetStatus = ASSETS_MENU;
  showComponent: boolean = false;
  alertBox: any;
  snackBarConfig: any;
  constructor(
    private modalService: ModalService
  ) { }
  ngOnInit(): void { }

  triggerModal(modal: string) {
    if (modal === MODALS.RAISE_TICKET_MODAL) {
      this.modalService.triggerModal(modal)?.subscribe((result) => {
        if (result) this.showAlertModal(result, 'raise_issue');
      })
    } else if (modal === MODALS.REQUEST_ASSET_MODAL) {
      this.modalService.triggerModal(modal)?.subscribe((result) => {
        if (result) this.showAlertModal(result, 'request_an_asset');
      })
    }
    else if (modal === MODALS.ACCESS_INSTALLATION_MODAL) {
      this.modalService.triggerModal(modal)?.subscribe((result) => {
        if (result) this.showAlertModal(result, 'access_installation');
      })
    }
  }

  showAlertModal(alertModalConfig: any, from: string) {
    if (from === 'raise_issue') {
      this.snackBarConfig = alertModalConfig;
      this.alertBox = true;
    } else if (from === 'request_an_asset') {
      this.snackBarConfig = alertModalConfig;
      this.alertBox = true;
    }
    else if (from === 'access_installation') {
      this.snackBarConfig = alertModalConfig;
      this.alertBox = true;
    }
  }
}
