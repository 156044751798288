<main class="split-screen">
  <div class="loader-container" *ngIf="loaderView">
    <img src="assets/32x32/coditas.gif" class="loader-logo" loading="lazy" />
  </div>
  <section class="left">
    <div class="image">
      <img
        src="assets/32x32/stessa-logo.svg"
        height="48"
        class="image"
        alt="stessa-logo"
      />
    </div>
    <div class="headings">
      <p class="signin-heading">Welcome to our</p>
      <p class="signin-heading">Assets Tracking Platform</p>
      <p class="signin-subheading">
        Sign in using your Coditas G-Suite account:
      </p>
    </div>
    <div class="button">
      <div id="g_id_signin" class="g_id_signin"></div>
    </div>
    <div class="subheading">
      <p class="subheading-note">Having trouble logging in?</p>
      <a class="button-text" routerLink="../contact-admin"> Contact Admin </a>
    </div>
    <div class="footer">
      <span class="footer-text">© 2023 Coditas. All rights reserved.</span>
      <div class="privacy-policy-text">
        <span routerLink="../terms-and-conditions">Terms and conditions</span>
        <span class="pipe">|</span>
        <span routerLink="../privacy-policy">Privacy Policy</span>
      </div>
    </div>
  </section>
  <section class="right"></section>
</main>
