import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  SimpleChange,
  ChangeDetectorRef,
} from '@angular/core';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { PagerService } from 'libs/common/src/lib/service/pager.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  // Current page number
  currentPageNumber: number = 1;
  // Total records count
  totalRecordsCount: number = 0;
  // Total pages
  totalPages: number = 0;
  // Pager
  pager: any = {};

  @Input() count!: number;

  constructor(
    private commonHttpService: CommonHttpService,
    private pagerService: PagerService,
    private changeDetectorRef: ChangeDetectorRef,
    private commonService: CommonService
  ) {}

  // API route
  @Input() apiRoute: string = '';

  // Records per page
  @Input() recordsPerPage: number = 0;

  @Input() data!: any;

  // Response data
  @Output() responseData = new EventEmitter<any>();

  // Loading
  @Output() loading = new EventEmitter<boolean>();

  // Reload
  @Input() reload: EventEmitter<boolean> | undefined;

  params = {
    searchColumn: '',
    searchText: '',
    sortColumn: '',
    sortDirection: '',
    pageNumber: '1',
    itemsPerPage: '5',
  };

  // On init
  ngOnInit() {
    this.watchReload();
  }

  // To be emitted from parent component
  watchReload() {
    if (this.reload) {
      this.reload.subscribe((reload: any) => {
        if (reload) {
          this.getData(this.currentPageNumber);
        }
      });
    }
  }

  // Fetch new page data
  next() {
    this.getData(this.currentPageNumber + 1);
  }

  // Fetch previous page data
  prev() {
    this.getData(this.currentPageNumber - 1);
  }

  // Fetch data from API
  getData(pageNo: any) {
    this.loading.emit(true);
    this.params.pageNumber = `${pageNo}`;
    this.currentPageNumber = Number(pageNo);
    this.setPagination(pageNo);
    this.responseData.emit(this.params);
  }

  // Set pagination data and pager data
  setPagination(pageNo: any) {
    pageNo = Number(pageNo);
    this.currentPageNumber = pageNo;
    this.pager = this.pagerService.getPager(
      this.totalRecordsCount,
      pageNo,
      this.recordsPerPage
    );
  }

  getTickets() {
    this.params.pageNumber = `${this.currentPageNumber}`;
    this.params.itemsPerPage = `${this.recordsPerPage}`;
    this.totalRecordsCount = this.count;
    this.responseData.emit(this.params);
    this.totalPages = Math.ceil(this.count / this.recordsPerPage);
    this.setPagination(this.currentPageNumber);
    this.loading.emit(false);
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    const recordsPerPage =
      changes['recordsPerPage'] && changes['recordsPerPage'].currentValue;
    if (recordsPerPage) {
      this.totalPages = Math.ceil(this.count / this.recordsPerPage);
      const count =
        this.totalPages <= this.currentPageNumber
          ? this.totalPages
          : this.currentPageNumber;
      this.getData(count);
    }
    if (changes['count']?.currentValue) {
      this.currentPageNumber = 1;
      this.commonService.searchParam.subscribe({
        next: (response) => {
          this.params.searchColumn = response?.searchColumn || '';
          this.params.searchText = response?.searchText || '';
          this.params.sortColumn = response?.sortColumn || '';
          this.params.sortDirection = response?.sortDirection || '';
          this.params.pageNumber = response?.pageNumber || '';
        },
      });
      this.currentPageNumber =
        +this.params.pageNumber || this.currentPageNumber;
      this.params.itemsPerPage = `${this.recordsPerPage}`;
      this.totalPages = Math.ceil(this.count / this.recordsPerPage);
      this.totalRecordsCount = this.count;
      this.pager = this.pagerService.getPager(
        this.totalRecordsCount,
        this.currentPageNumber,
        this.recordsPerPage
      );
      this.responseData.emit(this.params);
    }
  }

  // Track by
  trackByFn(index: any, item: any) {
    return item; // or item.id
  }
}
