<router-outlet #outlet="outlet"></router-outlet>
<ng-container *ngIf="!outlet.isActivated">
  <section>
    <app-alert-box
      [config]="snackBarConfig"
      *ngIf="alertBox && snackBarConfig"
      (close)="alertBox = false"
    ></app-alert-box>
    <div class="asset-team-container">
      <div class="row">
        <div class="dash-section-wrapper">
          <app-user-profile-details></app-user-profile-details>
        </div>
        <div class="dash-section-wrapper">
          <app-generate-ticket-section
            (toggleModalEmitter)="triggerModal($event)"
          ></app-generate-ticket-section>
        </div>
      </div>
      <div class="dash-section-wrapper">
        <app-user-assets-section></app-user-assets-section>
      </div>

      <div
        class="dash-section-wrapper"
        *ngIf="tableDetails"
        [ngClass]="{ loader: loaderView }"
      >
        <app-loader *ngIf="loaderView"></app-loader>
        <div>
          <app-table
            [tableConfig]="tableConfig"
            [tableData]="tableDetails"
            [count]="count"
            [filterOptions]="filterOptions"
            [activeMenu]="pageSize"
            (activeFilter)="filterBy($event)"
            (paginatorEmitter)="getUserTickets($event)"
            (pageSizeChange)="pageSizeChange($event)"
            class="width-100"
            #table
          >
          </app-table>
        </div>
      </div>
      <!-- <router-outlet
      (activate)="ticketStatusClicked = !ticketStatusClicked"
    ></router-outlet> -->
    </div>
  </section>
</ng-container>
