<div class="asset-team-container">
  <div class="header" *ngIf="userInfo">
    <h1 class="title">Welcome, {{ userInfo.firstName | titlecase }}!</h1>
    <p class="subtitle">Manage all your assets in one place.</p>
  </div>
  <div class="content-box">
    <div class="header-section">
      <h1 class="heading">
        Welcome to <br />
        Coditas Asset Management Platform
      </h1>
      <p class="version">Version 1.0.0</p>
    </div>
    <div class="details">
      <div class="details-container">
        <div class="row">
          <div class="icon">
            <img src="assets/40x40/monitor.svg" width="40" alt="" />
          </div>
          <div class="content">
            <h3>Simplified way of managing company assets</h3>
            <p>View all your assigned assets and access information</p>
          </div>
        </div>
        <div class="row">
          <div class="icon">
            <img src="assets/40x40/tag.svg" width="40" alt="" />
          </div>
          <div class="content">
            <h3>Raise tickets and track status easily</h3>
            <p>Request repairs and replacement or new assets with ease.</p>
          </div>
        </div>
        <div class="row">
          <div class="icon">
            <img src="assets/40x40/box.svg" width="40" alt="" />
          </div>
          <div class="content">
            <h3>Easy access.</h3>
            <p>Access via web or mobile</p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-section">
      <!-- <a
        href="#"
        routerLink="../employee-dashboard"
        #button
        [scrollTop]="
          button.scrollIntoView({ behavior: 'smooth', block: 'start' })
        "
      >
        Next
      </a> -->
      <div class="bottom-corner">
        <app-button
          [disabled]="false"
          size="xsmall"
          theme="tertiary"
          routerLink="../employee-dashboard"
          >Next
          <img src="assets/12x12/side-arrow.svg" alt="log out" class="image" />
        </app-button>
      </div>
    </div>
  </div>
</div>
