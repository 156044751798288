import { Dialog, DialogRef } from '@angular/cdk/dialog';
import {
  HttpErrorResponse,
  HttpEventType,
  HttpResponse,
} from '@angular/common/http';
import {
  Component,
  EventEmitter,
  IterableDiffers,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  PROGRESS_STATUS,
  UPLOAD_LIST_TYPES,
} from 'libs/common/src/lib/constants/constants';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { GenericConfirmationPopUpComponent } from '../generic-confirmation-pop-up/generic-confirmation-pop-up.component';
import { IFileInfo } from '@common/interfaces';
import { UploadDownloadService } from 'libs/common/src/lib/service/upload-download.service';
import { GenericPopUpComponent } from '../generic-pop-up/generic-pop-up.component';

@Component({
  selector: 'app-bulk-upload-list',
  templateUrl: './bulk-upload-list.component.html',
  styleUrls: ['./bulk-upload-list.component.scss'],
})
export class BulkUploadListComponent implements OnInit {
  @Output() closeModal = new EventEmitter();

  popupForm = new UntypedFormGroup({
    upload_list_type: new UntypedFormControl('', Validators.required),
    files: new UntypedFormControl('', Validators.required),
  });

  iterableDiffer: any;
  options = UPLOAD_LIST_TYPES;
  progressInfos: any[] = [];
  response!: boolean;
  fileInfo!: IFileInfo;
  uploadInProgress = false;
  uploadCount!: any;
  uploadListTypeHolder!: string;
  fileTypes = ['text/csv'];

  constructor(
    private commonHttpService: CommonHttpService,
    private iterableDiffers: IterableDiffers,
    private dialogRef: DialogRef,
    private dialog: Dialog,
    private uploadAndDownloadService: UploadDownloadService
  ) {
    this.iterableDiffer = this.iterableDiffers.find([]).create();
  }

  ngOnInit(): void {}

  close(uploadCount?: any) {
    this.progressInfos = [];
    this.popupForm.reset();
    const current = this;
    const hasErrors =
      uploadCount && uploadCount.some((count: any) => count.status === 'fail');

    if (!hasErrors) {
      this.dialogRef.removePanelClass('download-upload-dialog');
      this.dialogRef.addPanelClass('close-upload-download-dialog');
      setTimeout(() => {
        current.dialogRef.close(uploadCount);
      }, 1000);
    }
  }

  closeDialog() {
    this.openCloseConfirmationDialog();
  }
  getValue(value: string) {
    this.uploadListTypeHolder = value;
    this.popupForm.get('upload_list_type')?.setValue(value);
  }

  onSubmit() {
    this.openConfirmationDialog();
  }

  upload(file: File, fileType: string): void {
    if (file && fileType === 'asset_list') {
      this.uploadInProgress = true;
      this.fileInfo.showRemoveFileBtn = false;
      this.fileInfo.showProgressBar = true;

      let formData = new FormData();
      formData.append('uploadedFile', file);
      this.commonHttpService.bulkAssetListUpload(formData).subscribe({
        next: (res: any) => {
          if (res.type === HttpEventType.UploadProgress && file) {
            this.fileInfo.progressValue = Math.round(
              (100 * res.loaded) / res.total
            );
            this.fileInfo.progressStatus = PROGRESS_STATUS.SUCCESS;
          } else if (res instanceof HttpResponse) {
            this.fileInfo.progressStatus = PROGRESS_STATUS.SUCCESS;
            this.fileInfo.showRemoveFileBtn = true;
            const uploadCounts = {
              status: 'success',
              fileName: file.name,
              successCount: res.body.successfulUploads,
              totalCount: res.body.totalCount,
              failedCount: res.body.unsuccessfulUploads,
            };

            this.openSuccessBulkUploadModal(uploadCounts);

            if (res.body.data?.length)
              this.saveDataInCSV('Asset List', res.body.data);
          }
        },
        error: (error: HttpErrorResponse) => {
          const errorReason = Object(error).error.error.errorList;

          this.fileInfo.progressStatus = PROGRESS_STATUS.FAILED;
          this.fileInfo.showProgressBar = false;
          this.fileInfo.showRemoveFileBtn = true;
          this.openFailedBulkModal(error);
          this.uploadInProgress = false;
        },
      });
    } else if (file && fileType === 'asset_allocation_list') {
      this.uploadInProgress = true;
      this.fileInfo.showRemoveFileBtn = false;
      this.fileInfo.showProgressBar = true;
      let formData = new FormData();
      formData.append('uploadedFile', file);
      this.commonHttpService.bulkAssetAllocationListUpload(formData).subscribe({
        next: (res: any) => {
          if (res.type === HttpEventType.UploadProgress && file) {
            this.fileInfo.progressValue = Math.round(
              (100 * res.loaded) / res.total
              );
            } else if (res instanceof HttpResponse) {
            this.fileInfo.showRemoveFileBtn = true;
            this.fileInfo.progressStatus = PROGRESS_STATUS.SUCCESS;
            const uploadCounts = {
              status: 'success',
              fileName: file.name,
              successCount: res.body.successfulUploads,
              totalCount: res.body.totalCount,
              failedCount: res.body.unsuccessfulUploads,
            };

            this.openSuccessBulkUploadModal(uploadCounts);

            if (res.body.data?.length)
              this.saveDataInCSV('Asset Allocation List', res.body.data);
          }
        },
        error: (error: HttpErrorResponse) => {
          const errorReason = Object(error).error.error.errorList;

          this.fileInfo.progressStatus = PROGRESS_STATUS.FAILED;
          this.fileInfo.showProgressBar = false;
          this.fileInfo.showRemoveFileBtn = true;
          this.openFailedBulkModal(error);
          this.uploadInProgress = false;
        },
      });
    } else if (file && fileType === 'asset_list_update') {
      this.uploadInProgress = true;
      this.fileInfo.showRemoveFileBtn = false;
      this.fileInfo.showProgressBar = true;
      let formData = new FormData();
      formData.append('uploadedFile', file);
      this.commonHttpService.bulkAssetListUpdateUpload(formData).subscribe({
        next: (res: any) => {
          if (res.type === HttpEventType.UploadProgress && file) {
            this.fileInfo.progressValue = Math.round(
              (100 * res.loaded) / res.total
            );
          } else if (res instanceof HttpResponse) {
            this.fileInfo.showRemoveFileBtn = true;
            this.fileInfo.progressStatus = PROGRESS_STATUS.SUCCESS;
            const uploadCounts = {
              status: 'success',
              fileName: file.name,
              successCount: res.body.successfulUploads,
              totalCount: res.body.totalCount,
              failedCount: res.body.unsuccessfulUploads,
            };

            this.openSuccessBulkUploadModal(uploadCounts);
            if (res.body.data?.length)
              this.saveDataInCSV('Asset List Update', res.body.data);
          }
        },
        error: (error: HttpErrorResponse) => {
          const errorReason = Object(error).error.error.errorList;

          this.fileInfo.progressStatus = PROGRESS_STATUS.FAILED;
          this.fileInfo.showProgressBar = false;
          this.fileInfo.showRemoveFileBtn = true;

          this.openFailedBulkModal(error);
          this.uploadInProgress = false;
        },
      });
    } else if (file && fileType === 'asset_out_for_repair') {
      this.uploadInProgress = true;
      this.fileInfo.showRemoveFileBtn = false;
      this.fileInfo.showProgressBar = true;
      let formData = new FormData();
      formData.append('uploadedFile', file);
      this.commonHttpService.bulkAssetOutForRepairUpload(formData).subscribe({
        next: (res: any) => {
          if (res.type === HttpEventType.UploadProgress && file) {
            this.fileInfo.progressValue = Math.round(
              (100 * res.loaded) / res.total
            );
          } else if (res instanceof HttpResponse) {
            this.fileInfo.showRemoveFileBtn = true;
            this.fileInfo.progressStatus = PROGRESS_STATUS.SUCCESS;
            const uploadCounts = {
              status: 'success',
              fileName: file.name,
              successCount: res.body.successfulUploads,
              totalCount: res.body.totalCount,
              failedCount: res.body.unsuccessfulUploads,
            };

            this.openSuccessBulkUploadModal(uploadCounts);

            if (res.body.data?.length)
              this.saveDataInCSV('Asset Out for Repair List', res.body.data);

          }
        },
        error: (error: HttpErrorResponse) => {
          const errorReason = Object(error).error.error.errorList;

          this.fileInfo.progressStatus = PROGRESS_STATUS.FAILED;
          this.fileInfo.showProgressBar = false;
          this.fileInfo.showRemoveFileBtn = true;
          this.openFailedBulkModal(error);
          this.uploadInProgress = false;
        },
      });
    } else if (file && fileType === 'mark_asset_ewaste') {
      this.fileInfo.showRemoveFileBtn = false;
      this.fileInfo.showProgressBar = true;
      let formData = new FormData();
      formData.append('uploadedFile', file);
      this.uploadInProgress = true;
      this.commonHttpService.uploadMarkAssetAsEwaste(formData).subscribe({
        next: (res: any) => {
          if (res.type === HttpEventType.UploadProgress && file) {
            this.fileInfo.progressValue = Math.round(
              (100 * res.loaded) / res.total
            );
          } else if (res instanceof HttpResponse) {
            this.fileInfo.showRemoveFileBtn = true;
            this.fileInfo.progressStatus = PROGRESS_STATUS.SUCCESS;
            const uploadCounts = {
              status: 'success',
              fileName: file.name,
              successCount: res.body.successfulUploads,
              totalCount: res.body.totalCount,
              failedCount: res.body.unsuccessfulUploads,
            };
            this.openSuccessBulkUploadModal(uploadCounts);

            if (res.body.data?.length) {
              this.saveDataInCSV('Assets Marked as E-Waste', res.body.data);
            }
          }
        },
        error: (error: HttpErrorResponse) => {
          const errorReason = Object(error).error.error.errorList;

          this.fileInfo.progressStatus = PROGRESS_STATUS.FAILED;
          this.fileInfo.showProgressBar = false;
          this.fileInfo.showRemoveFileBtn = true;
          this.openFailedBulkModal(error);
          this.uploadInProgress = false;
        },
      });

    } else {
      this.fileInfo.progressStatus = PROGRESS_STATUS.FAILED;
      this.fileInfo.showProgressBar = false;
      this.fileInfo.showRemoveFileBtn = true;
      this.uploadInProgress = false;
    }
  }
  ngDoCheck() {}

  selectedFiles(files: any) {
    if (
      files[0] &&
      files[0].file.progressStatus !== PROGRESS_STATUS.FAILED
    ) {
      this.fileInfo = files[0] as IFileInfo;
      this.fileInfo.showProgressBar = false;
      this.popupForm.patchValue({
        upload_list_type: this.uploadListTypeHolder,
        files: this.fileInfo.file,
      });
    }
  }
  saveDataInCSV(name: string, data: Array<any>) {
    let csvContent = this.uploadAndDownloadService.saveDataInCSV(data);
    const exportCSV = document.createElement('a');
    exportCSV.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
    exportCSV.target = '_blank';
    exportCSV.download = name + '.csv';
    exportCSV.click();
  }

  openConfirmationDialog() {
    const dialogRef = this.dialog.open<string>(
      GenericConfirmationPopUpComponent,
      {
        panelClass: 'center-dialog-box',
        backdropClass: 'backdrop',
        disableClose: true,
        data: {
          header: 'Do you want to upload the file?',
          description: 'This action cannot be undone',
          primaryBtn: 'Upload File',
          secondaryBtn: 'Cancel',
          image: 'cautionImage',
        },
      }
    );
    dialogRef.closed.subscribe((response) => {
      if (response === 'Upload File') {
        this.response = true;
        const selectedFiles = this.popupForm.value.files;
        const fileType = this.popupForm.value.upload_list_type;
        this.progressInfos = [];

        if (selectedFiles) {
          this.fileInfo.showRemoveFileBtn = true;
          this.fileInfo.showProgressBar = false;
          this.fileInfo.progressValue = 0;
          this.upload(selectedFiles, fileType);
        }
      }
    });
  }
  openCloseConfirmationDialog() {
    const dialogRef = this.dialog.open<string>(
      GenericConfirmationPopUpComponent,
      {
        panelClass: 'center-dialog-box',
        backdropClass: 'backdrop',
        disableClose: true,
        data: {
          header: 'Are you sure you want to cancel?',
          description: 'All progress in this session will be lost',
          primaryBtn: 'Discard',
          secondaryBtn: 'Go Back',
          image: 'cautionImage',
        },
      }
    );
    dialogRef.closed.subscribe((response) => {
      if (response === 'Discard') {
        this.progressInfos = [];
        this.popupForm.reset();
        this.dialogRef.removePanelClass('download-upload-dialog');
        this.dialogRef.addPanelClass('close-upload-download-dialog');
        const current = this;
        setTimeout(() => {
          current.dialogRef.close();
        }, 1000);
      }
    });
  }

  openSuccessBulkUploadModal(res: any) {
    const popUpConfig = {
      headerIcon: 'success',
      title: 'Uploaded Documents Summary',
      subtitle: 'Selected documents have been uploaded successfully',
      data: [ { ...res } ],
      hasSummary: true,
    };
    const dialogRef = this.dialog.open<string>(GenericPopUpComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      data: popUpConfig,
      width: '50%',
    });
    dialogRef.closed.subscribe((response) => { this.close(); });
  }

  openFailedBulkModal(error: HttpErrorResponse) {
    const popUpConfig = {
      headerIcon: 'error',
      title: 'Uploaded Documents Summary',
      subtitle:
        error.error?.error?.errorList ||
        'Looks like you lost your connection. Please check your connection and try again.',
      hasSummary: true,
    };
    const dialogRef = this.dialog.open<string>(GenericPopUpComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      data: popUpConfig,
      width: '50%',
    });
    dialogRef.closed.subscribe((response) => {});
  }
}
