<app-modal [open]="alertBoxConfig.isOpen" width="43.75rem">
    <div class="modal-header">
      <div class="modal-header-with-alert-icon add-gap">
        <div class="modal-alert-icon" [ngClass]="{
            success: alertBoxConfig.status === 'success',
            danger: alertBoxConfig.status === 'fail'
          }">
          <img *ngIf="alertBoxConfig.icon" src="assets/24x24/{{ alertBoxConfig.icon }}" alt="" />
        </div>
        <div class="modal-heading">
          <h1 class="modal-header-title">
            {{ alertBoxConfig.heading }}
          </h1>
          <p class="modal-header-subtitle">
            {{ alertBoxConfig.content }}
          </p>
        </div>
      </div>
    </div>
    <div class="modal-body"></div>
  </app-modal>