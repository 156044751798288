import { Input } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent {
  @Input() allComments!: any[];
  userId!: string;

  ngOnInit() {
    this.userId = localStorage.getItem('userId') as string;
  }
  setDateForCommentSection(commentData: any, index: number) {
    if (index === 0) {
      return true;
    }
    return commentData.createdAt.slice(0, 10) ===
      this.allComments[index - 1].createdAt.slice(0, 10)
      ? false
      : true;
  }
}
