import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
  animations: [
    trigger('basicAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class TermsAndConditionsComponent {
  hasHeader = false;
  backButtonIcon = 'assets/20x20/arrow-back.svg';
  constructor(private router: Router) {}

  displayBack() {
    if (this.router.url.includes('home')) {
      this.hasHeader = false;
      return false;
    } else {
      this.hasHeader = true;
      return true;
    }
  }

  getClass() {
    if (!this.hasHeader) {
      return 'user-module-container';
    }
    return 'individual-container';
  }
  getWrapperClass() {
    if (!this.hasHeader) {
      return 'user-module-wrapper';
    }
    return 'individual-wrapper';
  }
}
