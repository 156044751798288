import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IIssuesList } from '@common/interfaces';
import { CommonService } from 'libs/common/src/lib/service/common.service';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss'],
})
export class DropDownComponent implements OnInit,OnChanges {
  @Output() value = new EventEmitter<any>();
  @Output() index = new EventEmitter<number>();
  @Output() emitIssueDiscription = new EventEmitter<string>();
  @Input() label?= '';
  @Input() from!: string;
  @Input() options: any;
  @Input() title!: string;
  @Input() titleName!: string;
  @Input() valueName!: string;
  @Input() selectedItems!: string;
  @Input() showDropContainerTop = false;
  @Input() disabled = false;
  @Input() formArrayName!: string;
  @Input() isDropDownofSelectIssue: boolean = false;
  @Input() onClickOfCancel: boolean = false;
  @Input() approverDropDown: boolean = false;
  @Input() dropDownForApproverList: boolean = false;

  otherIssue!: string;
  showDropDownOptions: boolean = false;
  icon = '';
  isOptionSelected = false;
  isOthersSelected!: boolean;
  constructor(
    private elementRef: ElementRef,
    private commonService: CommonService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.onClickOfCancel){
      this.issuesArray = [];
    }
  }
  ngOnInit(): void {
    this.commonService.resetMultiSelect.subscribe({
      next: () => {
        this.issueIdArray = [];
        this.issuesArray = [];
        this.title = 'Select Type of Issue';
        this.issues = '';
      },
    });
  }

  @HostListener('document:click', ['$event.target'])
  public onPageClick(targetElement: any) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    clickedInside
      ? (this.showDropDownOptions = !this.showDropDownOptions)
      : (this.showDropDownOptions = false);
    this.showOptions();
  }

  showOptions(): void {
    if (this.disabled) {
      this.showDropDownOptions = false;
      return;
    }
  }
  emitIssue() {
    this.emitIssueDiscription.emit(this.otherIssue)
  }
  getOption(value: string, index: number): void {
    this.title = value;
    this.value.emit(this.title);
    this.index.emit(index);
    this.showDropDownOptions = false;
  }
  getAssetType(assetTypeId: string, assetName: string) {
    this.title = assetName;
    this.value.emit(assetTypeId);
    this.showDropDownOptions = false;
  }
  getAddAssetType(assetTypeId: string, assetName: string) {
    this.title = assetName;
    this.value.emit(assetName + '  ' + assetTypeId);
    this.showDropDownOptions = false;
  }
  getOptions(option: string) {
    this.title = option;
    this.value.emit(option);
    this.showDropDownOptions = false;
  }
  selectOption(value: string, title: string) {
    this.isOptionSelected = true;
    this.title = title;
    this.value.emit(value);
    this.showDropDownOptions = false;
  }
  getDropDownClass() {
    return 'drop-down-of-issue';
  }
  issues: string = '';
  issuesArray: string[] = [];
  issueIdArray: string[] = [];

  addToList(issue: string, issueId: string) {

    if (!this.issues) {
      this.issues += issue;
    } else {
      this.issues += ',' + issue;
    }
    this.issueIdArray.push(issueId);
    this.issuesArray.push(issue);
    this.value.emit([this.issueIdArray, this.issues]);
  }
  removeFromList(issueId: string, issue: string) {
    this.issueIdArray = this.issueIdArray.filter((value) => {
      return value !== issueId;
    });
    this.issues = this.issues
      .split(',')
      .filter((item) => {
        return item !== issue;
      })
      .join(',');
    this.issuesArray = this.issues.split(',');
    if (this.issuesArray[0] === '') {
      this.issuesArray = [];
    }
    this.value.emit([this.issueIdArray, this.issues]);
  }
  remove(issue: string) {
    let issueId = '';
    this.options.forEach((item: IIssuesList) => {
      if (item.subCategory === issue) {
        issueId = item.id;
        item.isChecked = false;
      }
    });

    this.removeFromList(issueId, issue);
  }
  issueClicked(input: any, id: string, isChecked: boolean) {
    let issue!: string;
    isChecked = !isChecked;
    this.options.forEach((item: any) => {
      if (item.id === id) {
        issue = item.subCategory;
        item.isChecked = isChecked;
      }
    });
    if (isChecked) {
      this.addToList(issue, id);
    } else {
      this.removeFromList(input.value, issue);
    }
    if (this.issuesArray.includes('Other')) {
      this.isOthersSelected = true;
    }
    else {
      this.isOthersSelected = false;
    }
  }
}
