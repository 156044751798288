import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from '@common/interfaces';


@Component({
  selector: 'app-add-edit-alert-model',
  templateUrl: './add-edit-alert-model.component.html',
  styleUrls: ['./add-edit-alert-model.component.scss']
})
export class AddEditAlertModelComponent {

  constructor(
    public dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) public data: any,
    private router: Router
  ) {

  }
  @Output() closeModal = new EventEmitter();
  assetDetails!: any;
  ngOnInit(): void {
    this.assetDetails = this.data;
  }

  close() {
    this.dialogRef.close();
  }
  goToAssetDetails() {
    this.dialogRef.close();
    this.router.navigate([
      `${ROUTES.ASSET_DETAILS}${this.assetDetails.assetId}`,
      {
        status: 'ALL',
        page: 1,
        size: 5,
        search: '',
        column: 'null',
      },
    ]);
  }

}
