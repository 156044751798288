import { ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { OnInit } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  @Input() title!: string
  clients = ["Coditas", "Eko"];

  @Output() selectClient = new EventEmitter<string>();
  constructor(private elementRef: ElementRef) { }
  ngOnInit(): void { }
  getOption(option: string) {
    this.selectClient.emit(option);
  }
}
