<div class="container">
  <div class="modal-header">
    <div class="modal-heading">
      <h1 class="modal-header-title">Edit Ticket</h1>
      <p class="modal-header-subtitle">
        Edit ticket assignee and Service level agreement
      </p>
    </div>
    <button
      class="modal-header-action"
      type="button"
      (click)="onClose('cancel')"
    >
      <img src="assets/24x24/close.svg" alt="" />
    </button>
  </div>
  <div class="modal-body max-height">
    <form class="popup-form" [formGroup]="editTicketForm">
      <div class="form-control">
        <label class="label">Select Assignee</label>
        <app-generic-drop-down
          [options]="assigneeNames"
          [title]="assignedTo"
          titleName="name"
          valueName="value"
          (value)="getValue($event)"
          [shouldEmitEmittedValue]="true"
        ></app-generic-drop-down>
      </div>
      <div
        class="form-control"
        *ngIf="ticketData.ticketSubcategories.length !== 0"
      >
        <label class="label">Priority</label>
        <app-generic-drop-down
          [options]="priorityList"
          [title]="priorityName"
          titleName="name"
          valueName="id"
          [shouldEmitDisplayValueAndEmittedValue]="true"
          (value)="getPriority($event)"
          [disabled]="hasPriority || priorityChangeCount === 1"
        ></app-generic-drop-down>
        <p class="priority-subtitle" *ngIf="priorityChangeCount === 1">
          You can change the priority only once.
        </p>
      </div>
      <div
        class="form-control"
        *ngIf="ticketData.ticketSubcategories.length !== 0"
      >
        <label class="label">Reason</label>
        <app-textarea
          class="text-area"
          cols="30"
          rows="10"
          formControlName="description"
          characterCounter
          maxLength="255"
          (countEvent)="displayCount($event)"
          [countData]="countData"
          placeholder="Please update priority by specifying the reason..."
          [isRequired]="isDiscriptionRequired"
          [isLabelPresent]="false"
          [backgroundNeeded]="true"
          [disabled]="isPriorityChanged"
        >
        </app-textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="button">
      <app-button
        class="spacing"
        type="submit"
        [disabled]="disableButton"
        size="xsmall"
        theme="primary"
        (click)="onSubmit()"
        >Update
      </app-button>
    </div>
  </div>
</div>
