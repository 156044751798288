import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UiComponentsModule } from '@shared/ui-components';
import { QRCodeModule } from 'angularx-qrcode';
import { DashboardModule } from '../dashboard.module';
import { AdminBulkUploadComponent } from './admin-bulk-upload/admin-bulk-upload.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminInventoryComponent } from './admin-inventory/admin-inventory.component';
import { AdminTicketPersonalComponent } from './admin-ticket-personal/admin-ticket-personal.component';
import { AdminTicketComponent } from './admin-ticket/admin-ticket.component';
import { AssetDetailsComponent } from './asset-details/asset-details.component';
import { AssetTeamDashboardPersonalComponent } from './asset-team-dashboard-personal/asset-team-dashboard-personal.component';
import { AssetTeamDashboardComponent } from './asset-team-dashboard/asset-team-dashboard.component';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';

@NgModule({
  declarations: [
    AssetTeamDashboardComponent,
    AdminTicketComponent,
    AdminInventoryComponent,
    AdminBulkUploadComponent,
    AssetDetailsComponent,
    EmployeeDetailsComponent,
    AdminDashboardComponent,
    AssetTeamDashboardPersonalComponent,
    AdminTicketPersonalComponent,
  ],
  imports: [
    QRCodeModule,
    UiComponentsModule,
    RouterModule,
    DashboardModule,
    CommonModule,
    ReactiveFormsModule,
  ],
  providers: [],
  exports: [
    AssetTeamDashboardComponent,
    AdminTicketComponent,
    AdminInventoryComponent,
    AdminBulkUploadComponent,
    AssetDetailsComponent,
    EmployeeDetailsComponent,
    AdminDashboardComponent,
    AssetTeamDashboardPersonalComponent,
    AdminTicketPersonalComponent,
  ],
})
export class AdminModule {}
