import { Dialog } from '@angular/cdk/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  IIssuesList,
  ITicketSnackbarConfig,
  ROUTES,
  IFileInfo,
  PROGRESS_STATUS,
} from '@common/interfaces';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { GenericConfirmationPopUpComponent } from 'libs/shared/ui-components/src/lib/generic-confirmation-pop-up/generic-confirmation-pop-up.component';
import { FormServiceService } from '../form-service.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-contact-admin',
  templateUrl: './contact-admin.component.html',
  styleUrls: ['./contact-admin.component.scss'],
})
export class ContactAdminComponent implements OnInit {
  fileData!: File;
  nameOfFile!: string;
  isDisable: boolean = false;
  fileTypes = [
    'image/png',
    'image/jpg',
    'image/svg+xml',
    'image/jpeg',
    'video/mp4',
    'video/mpeg',
  ];
  snackBarConfig!: ITicketSnackbarConfig;
  progressInfos: any[] = [];
  locations: any;
  asset: any;
  asset_type: string = '';
  asset_id: string[] = [];
  subCategoryOptions!: IIssuesList[];
  issueOptions: any;
  subCategories: string[] = [];
  isAssetSelected: boolean = false;
  issueArray: string[] = [];
  userDetails: any;
  assetCodeErrorMsg!: string;
  issues!: string;
  uploadInProgress = false;
  filesArray: IFileInfo[] = [];
  options: {
    assetName: string;
  }[] = [];
  fileKeys: string[] = [];
  fileInfo!: IFileInfo;
  assetTypes!: { name: string; id: string; assetTypeCode: string }[];
  constructor(
    private formService: FormServiceService,
    private commonService: CommonService,
    private router: Router,
    private commonHttpService: CommonHttpService,
    private dialog: Dialog
  ) {}

  ngOnInit(): void {
    this.commonHttpService.getAllAssetType().subscribe((response) => {
      this.assetTypes = Object(response).data;
      this.assetTypes.forEach((element: { name: string }) => {
        this.options.push({
          assetName: element?.name,
        });
      });
    });
    this.onAssetCodeValueChange();
    this.getLocations();
  }

  contactAdminForm = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
      CommonService.isCoditasEmail,
    ]),
    mobile: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(10),
    ]),
    location: new UntypedFormControl('', [Validators.required]),
    option: new UntypedFormControl('', [Validators.required]),
    subCategories: new UntypedFormControl(this.issueArray, [
      Validators.required,
    ]),
    asset_details: new UntypedFormControl('', [Validators.required]),
    issue: new UntypedFormControl('', [
      Validators.required,
      CommonService.cannotContainSpace,
    ]),
    file: new UntypedFormControl(''),
  });

  coditasEmailValidator() {
    return (control: any) => {
      const email = control.value.toLowerCase();
      if (email && !email.endsWith('@coditas.com')) {
        return { coditasEmail: true };
      }
      return null;
    };
  }

  getLocations() {
    this.commonHttpService.getAssestLocationsList().subscribe({
      next: ({ data }: any) => {
        if (data) {
          this.locations = Object.entries(data).map(
            ([locationKey, locationValue]) => ({
              locationKey,
              locationValue,
            })
          );
        }
      },
      error: (err) => {},
    });
  }
  imageCount = 0;
  videoCount = 0;
  isValidFiles = true;
  failedFilesProgress: any[] = [];
  unAddedFiles!: IFileInfo[];
  getUnAddedFiles(file: IFileInfo[]) {
    const unAddedFiles = file.filter((file) => !this.filesArray.includes(file));
    file.filter((file) => {
      if (this.filesArray.includes(file)) {
        file.showProgressBar = false;
      }
    });
    return unAddedFiles;
  }

  async selectedFiles(files: IFileInfo[]) {
    if (files.length) {
      for (const fileData of files) {
        await this.uploadFile([fileData]);
        // await this.uploadFile([fileData], files);
      }
    }
  }

  getFileName(fileFormData: File[]) {
    this.fileData = fileFormData[0];
    this.nameOfFile = fileFormData[0].name;
  }

  submitContactAdminForm() {
    this.openConfirmationDialog();
  }

  onAssetCodeValueChange() {
    this.contactAdminForm.controls['asset_details'].valueChanges.subscribe(
      () => {
        if (this.contactAdminForm.controls['asset_details'].value.length >= 8) {
          this.commonHttpService
            .validateAssetCode(
              this.contactAdminForm.controls['asset_details'].value,
              this.asset_type
            )
            .subscribe({
              next: () => {
                this.assetCodeErrorMsg = '';
              },
              error: (errorResponse: HttpErrorResponse) => {
                this.assetCodeErrorMsg =
                  errorResponse?.error?.error?.errorList[0].msg;
              },
            });
        } else {
          this.assetCodeErrorMsg = '';
        }
      }
    );
  }
  upload(idx: number, contactAdminFormData: any, file?: File): void {
    this.formService.submitContactAdminForm(contactAdminFormData).subscribe({
      next: (res: any) => {
        if (Object(res).data) {
          const dialogRef = this.dialog.open(
            GenericConfirmationPopUpComponent,
            {
              panelClass: 'center-dialog-box',
              backdropClass: 'backdrop',
              disableClose: true,
              data: {
                header: 'Form submitted successfully',
                description: 'Your request has been submitted successfully',
                primaryBtn: 'Back to Login Page',
                image: 'successImage',
              },
            }
          );
          dialogRef.closed.subscribe((response) => {
            this.router.navigate([ROUTES.SIGN_IN]);
          });
        }
      },
      error: (error: HttpErrorResponse) => {
        const errorMsg = error.error.error.errorList[0]?.msg;
        const dialogRef = this.dialog.open<string>(
          GenericConfirmationPopUpComponent,
          {
            panelClass: 'center-dialog-box',
            backdropClass: 'backdrop',
            disableClose: true,
            data: {
              header: 'Form could not be submitted',
              description:
                errorMsg ||
                'Looks like there was some error in submitting your request.',
              primaryBtn: 'Try Again',
              secondaryBtn: 'Cancel',
              image: 'failureImage',
            },
          }
        );
        dialogRef.closed.subscribe((response) => {
          if (response === 'Try Again') {
            this.submitContactAdminForm();
          }
        });
        this.progressInfos[idx].status = PROGRESS_STATUS.FAILED;
      },
    });
  }
  getValue(value: string, type: string) {
    if (type === 'option') {
      this.asset_id = [];
      this.issueArray = [];
      this.issues = '';
      this.commonService.resetMultiSelect.next(true);
      this.contactAdminForm.get('option')?.setValue(value);
      this.isAssetSelected = true;
    }
    if (type === 'subCategory') {
      this.contactAdminForm.get('subCategories')?.setValue(value);
      this.issueArray = [...value[0]];
      this.issues = value[1];
      this.isAssetSelected = true;
    }
    if (type === 'location') {
      this.contactAdminForm.get('location')?.setValue(value);
    }
  }

  getIndex(index: number) {
    this.asset_type = this.assetTypes[index].id;
    const endPoint = `?ticketType=REPAIR/REPLACEMENT&assetTypeId=${this.asset_type}`;
    this.commonHttpService
      .getPublicSubCategories(endPoint)
      .subscribe((response) => {
        this.subCategoryOptions = [...response.data].filter((item) => {
          if (item.subCategory !== 'Other') {
            item.isChecked = false;
            return item;
          }
          return;
        });
      });
  }
  openConfirmationDialog() {
    const dialogRef = this.dialog.open<string>(
      GenericConfirmationPopUpComponent,
      {
        panelClass: 'center-dialog-box',
        backdropClass: 'backdrop',
        disableClose: true,
        data: {
          header: 'Do you want to submit the form?',
          description: 'This action cannot be undone',
          primaryBtn: 'Submit Form',
          secondaryBtn: 'Go Back',
          image: 'cautionImage',
        },
      }
    );
    dialogRef.closed.subscribe((response: any) => {
      if (response.buttonText === 'Submit Form') {
        this.isDisable = this.isDisable ? false : true;

        const contactAdminFormData = {} as any;
        contactAdminFormData['assetCodes'] = [
          this.contactAdminForm.value.asset_details,
        ];
        contactAdminFormData['description'] =
          this.contactAdminForm.value.issue.trim();
        contactAdminFormData['email'] = this.contactAdminForm.value.email;
        contactAdminFormData['externalUserName'] =
          this.contactAdminForm.value.username;
        contactAdminFormData['phoneNumber'] =
          this.contactAdminForm.value.mobile;
        contactAdminFormData['location'] = this.contactAdminForm.value.location;
        contactAdminFormData['type'] = 'REPAIR/REPLACEMENT';
        contactAdminFormData['title'] = 'Issue Reported';
        contactAdminFormData['subCategories'] = [...this.issueArray];
        contactAdminFormData['assetType'] = this.asset_type;

        if (this.fileKeys?.length) {
          contactAdminFormData['uploadedFileKeys'] = [...this.fileKeys];
        }
        this.upload(0, contactAdminFormData);
      }
    });
  }

  async uploadFile(files: IFileInfo[]) {
    this.uploadInProgress = true;

    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (this.shouldUploadFile(file) && !this.isAlreadyUploaded(file)) {
        await this.uploadAndHandleFile(file);
      } else {
        files[index].showProgressBar = false;
      }
    }
  }

  shouldUploadFile(file: IFileInfo): boolean {
    return (
      file.progressStatus !== PROGRESS_STATUS.FAILED &&
      file.progressValue !== 100
    );
  }

  async uploadAndHandleFile(file: IFileInfo) {
    this.uploadInProgress = true;

    const subscription = this.commonService.getProgress().subscribe((value) => {
      file.progressValue = value;
      file.showRemoveFileBtn = false;
    });

    try {
      const fileInfo = [
        {
          originalname: file.originalname,
          mimetype: file.mimetype,
          size: file.size,
        },
      ];
      const loadRequest: Observable<Object> =
        this.commonHttpService.uploadFiles(fileInfo);
      this.uploadInProgress = false;
      subscription?.unsubscribe();
      loadRequest.subscribe({
        next: async (response: any) => {
          if (this.shouldUploadFile(file) && !this.isAlreadyUploaded(file)) {
            file.progressValue = 50;
            const s3response = await this.handleFileUpload(
              response.presignedUrls,
              [file]
            );

            if (response.presignedUrls.length === s3response.length) {
              file.progressValue = 100;
              file.showRemoveFileBtn = false;
              file.progressStatus = PROGRESS_STATUS.SUCCESS;

              const tempFileKeys = response.presignedUrls[0].key;
              this.fileKeys.push(tempFileKeys);
            }
          }
        },
        error: () => {
          file.showRemoveFileBtn = true;
          file.progressStatus = PROGRESS_STATUS.FAILED;
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  isAlreadyUploaded(file: IFileInfo): boolean {
    return file.progressValue === 100;
  }

  handleFileUpload(
    preSignedUrls: { key: string; presignedUrl: string }[],
    files: IFileInfo[]
  ) {
    return this.commonHttpService.uploadFilesToPresignedUrls(
      preSignedUrls,
      files
    );
  }

  // Form validation methods
  get usernameHasError() {
    const usernameControl = this.contactAdminForm.controls['username'];
    return usernameControl.dirty && usernameControl.errors;
  }

  get emailHasError() {
    const emailControl = this.contactAdminForm.controls['email'];
    return emailControl.dirty && emailControl.errors;
  }

  get emailHasCoditasError(): boolean {
    const emailControl = this.contactAdminForm.controls['email'];
    return emailControl.hasError('isCoditasEmail');
  }

  get mobileHasError() {
    const mobileControl = this.contactAdminForm.controls['mobile'];
    return mobileControl.dirty && mobileControl.errors;
  }

  get assetDetailsHasError() {
    const assetDetailsControl = this.contactAdminForm.controls['asset_details'];
    return assetDetailsControl.dirty && assetDetailsControl.errors;
  }

  get issueHasError() {
    const issueControl = this.contactAdminForm.controls['issue'];
    return issueControl.dirty && issueControl.errors;
  }
}
