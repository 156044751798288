<app-admin-header
  coditasRoute="/home/asset-team-dashboard/coditas"
  personalRoute="/home/asset-team-dashboard/personal"
  personalTabPermission="DASHBOARD__PERSONAL"
></app-admin-header>
<app-alert-box
  [config]="snackBarConfig"
  *ngIf="alertBox && snackBarConfig"
  (close)="alertBox = false"
></app-alert-box>

<div class="asset-team-container">
  <div class="row">
    <div class="dash-section-wrapper">
      <app-user-profile-details></app-user-profile-details>
    </div>

    <div class="dash-section-wrapper">
      <app-generate-ticket-section
        (toggleModalEmitter)="triggerModal($event)"
      ></app-generate-ticket-section>
    </div>
  </div>

  <div class="dash-section-wrapper">
    <app-user-assets-section></app-user-assets-section>
  </div>
</div>
