<div class="main-container">
  <input
    type="text"
    class="input"
    [value]="valueSelected"
    placeholder="Type to Search"
    (keyup)="getInput($event)"
  />
  <div class="options" *ngIf="show">
    <div
      *ngFor="let option of options"
      class="option-items"
      (click)="getValue(option)"
    >
      <span>{{ option }}</span>
    </div>
  </div>
</div>
