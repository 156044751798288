import { Component, OnInit } from '@angular/core';
import { IErrorConfig, ROUTES } from '@common/interfaces';


@Component({
  selector: 'app-no-internet',
  templateUrl: './no-internet.component.html',
  styleUrls: ['./no-internet.component.scss']
})
export class NoInternetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  errorObject: IErrorConfig = {
    errorCode: '404 Error!',
    errorMessage: 'Not Found',
    errorDescription: 'Page you want access is not available, Or you might typed incorrect URL.',
    actionName: 'Go To Login',
    navigateTo: ROUTES.SIGN_IN
  }
}
