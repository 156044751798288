<section class="wrapper">
  <section class="popup-header">
    <div class="header-text">
      <img [src]="imageAddress" />
      <div class="header">
        <h1 class="primary-header">{{ header }}</h1>
        <p class="description">{{ description }}</p>
      </div>
    </div>
    <div>
      <div class="close" (click)="closeDialog()">
        <img src="assets/24x24/close.svg" alt="" />
      </div>
    </div>
  </section>
  <section class="popup-body">
    <form [formGroup]="confirmationForm" *ngIf="confirmationForm">
      <ng-container *ngFor="let field of fields">
        <div class="field" *ngIf="field.field == 'dropdown'">
          <label class="label">{{ field.label }}</label>
          <app-generic-drop-down
            [options]="field.options"
            [title]="field.title"
            [shouldEmitOption]="field.shouldEmitOption"
            (value)="getOption($event, field.controlName)"
            [formControlName]="field.controlName"
          ></app-generic-drop-down>
        </div>
        <div class="field" *ngIf="field.field == 'text-field'"  [formControlName]="field.controlName">
          <label class="text-label">{{ field.label }}</label>
          <app-text-field
            class="text-field"
            type="text"
            [(ngModel)]="value"
            [formControlName]="field.controlName"
            [required]="true"
            [placeholder]="field.placeholder"
            [disabled]="false"
          ></app-text-field>
        </div>
        <div
          class="field"
          *ngIf="field.field == 'text-area'"
          [formControlName]="field.controlName"
        >
          <label class="label">{{ field.label }}</label>
          <app-textarea
            cols="30"
            rows="10"
            [formControlName]="field.controlName"
            characterCounter
            maxLength="255"
            [placeholder]="field.placeholder"
          >
          </app-textarea>
        </div>
        <div *ngIf="field.field == 'ticketClosureTempFileKey'">
          <label class="label">{{ field.label }}</label>
          <app-file-upload
            (emitFileInfo)="selectedFiles($event)"
            [formControlName]="field.controlName"
            [multiple]="false"
            [hasImageAndVideo]="true"
            [fileTypesForValidations]="fileTypes"
          >
            <p>PDF / Image (JPG, under 2 MB)</p>
          </app-file-upload>
        </div>
      </ng-container>
    </form>
  </section>
  <section class="popup-footer">
    <ng-container *ngIf="secondaryBtn">
      <app-button
        size="xsmall"
        theme="secondary"
        (click)="onClick(secondaryBtn)"
      >
        {{ secondaryBtn }}
      </app-button>
    </ng-container>
    <ng-container>
      <app-button
        size="xsmall"
        theme="primary"
        (click)="onClick(primaryBtn)"
        [disabled]="confirmationForm.invalid"
        >{{ primaryBtn }}
      </app-button>
    </ng-container>
  </section>
</section>
