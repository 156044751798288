import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextareaComponent,
      multi: true,
    },
  ],
})
export class TextareaComponent implements OnInit, ControlValueAccessor {
  @Input() backgroundNeeded: boolean = false;
  @Input() placeholder: string = '';
  @Input() cols: string = '';
  @Input() rows: string = '';
  @Input() isRequired: boolean = false;
  @Input() countData!: number[];
  @Input() value: string = '';
  @Input() isLabelPresent: boolean = true;
  @Input() maxLength!: string;
  @Input() disabled: boolean = false;

  onChange: Function = (event: Event) => { };
  onTouched: Function = () => { };

  constructor() { }

  writeValue(obj: string): void {
    this.value = obj;
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  ngOnInit(): void { }
}
