import { DIALOG_DATA, Dialog, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { GenericPopUpComponent } from '../generic-pop-up/generic-pop-up.component';

interface IUpdatedData {
  assignedTo?: string;
  id: string;
  priorityId?: string;
  priorityChangeReason?: string;
}
@Component({
  selector: 'app-edit-ticket',
  templateUrl: './edit-ticket.component.html',
  styleUrls: ['./edit-ticket.component.scss'],
})
export class EditTicketComponent implements OnInit {
  adminData: any[] = [];
  priorityList: any[] = [];
  ticketData: any;
  selectedAdmin!: string;
  assigneeSelected!: boolean;
  userId: any;
  oldAssignee: any;
  assignedTo = '';
  newAssignee!: string;
  index!: number;
  priorityName!: string;
  priorityId!: string;
  countData: any;
  hasPriority: boolean = false;
  assigneeNames: { name: string; value: string }[] = [];
  editTicketForm = new UntypedFormGroup({
    assignee: new UntypedFormControl('', [Validators.required]),
    priority: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl(null, [
      CommonService.cannotContainSpace,
    ]),
  });
  disableButton: boolean = true;
  priorityChangeCount!: number;
  isPriorityChanged: boolean = true;
  message: string = '';
  isAssigneeChanged: boolean = false;
  selectPriorityForFirstTime: boolean = false;
  textarea = document.querySelector('.text-area');
  isDescriptionEntered: boolean = false;
  isDiscriptionRequired: boolean = false;
  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private commonHttpService: CommonHttpService,
    private dialog: Dialog
  ) { }
  ngOnInit(): void {
    this.ticketData = this.data?.ticket;

    this.adminData = this.data?.assignees;
    this.commonHttpService
      .getPriorities(this.data.ticketId)
      .subscribe((response) => {
        this.priorityList = Object(response).data;
      });
    this.priorityChangeCount = this.ticketData?.ticketPriority?.priorityChangeCount
    this.priorityName =
      this.ticketData?.ticketPriority?.priorityName || 'Select priority';
    this.priorityId = this.ticketData?.ticketPriority?.priorityId;
    if (this.ticketData.ticketDetails.assignedTo) {
      const admin = this.adminData.filter((admin) => {
        return admin.userId === this.ticketData.ticketDetails.assignedTo;
      })[0];
      this.assignedTo = admin.firstName + ' ' + admin.lastName;
    } else {
      this.assignedTo = 'Select assignee';
    }
    if (!this.ticketData.ticketPriority.priorityId) {
      this.hasPriority = false;
    }
    this.adminData.forEach((user: any) => {
      const assignee = {
        name: user.firstName + ' ' + user.lastName,
        value: user.userId,
      };
      if (user.userId !== this.ticketData.ticketDetails.assignedTo) {
        this.assigneeNames.push(assignee);
      }
    });

    this.onDescriptionFieldChange();
  }
  onDescriptionFieldChange() {
    this.editTicketForm.controls['description'].valueChanges.subscribe(
      (value) => {
        if (value?.length >= 3) {
          this.disableButton = false;
        } else {
          this.isDescriptionEntered = false;
          this.disableButton = true;
        }
      }
    );
  }
  getPriority(value: string) {
    if (
      this.priorityList.length === 1 &&
      this.ticketData.ticketPriority.priorityId
    ) {
      this.selectPriorityForFirstTime = false;
      this.isPriorityChanged = false;
    } else if (this.priorityList.length === 1 && !this.hasPriority) {
      this.disableButton = false;
      this.priorityId = value.split('  ')[1];
      this.selectPriorityForFirstTime = true;
      this.isPriorityChanged = false;
    } else if (this.priorityList.length > 1) {
      if (this.ticketData.ticketPriority.priorityId === value.split('  ')[1]) {
        this.disableButton = true;
        this.isPriorityChanged = true;
        this.isDiscriptionRequired = false;
      } else {
        this.isDiscriptionRequired = true;
        if (this.isDescriptionEntered) {
          this.disableButton = false;
          this.isPriorityChanged = false;
          this.priorityId = value.split('  ')[1];
        } else {
          this.disableButton = true;
          this.isPriorityChanged = false;
          this.priorityId = value.split('  ')[1];
        }
      }
    } else {
      this.isPriorityChanged = true;
      this.priorityId = value.split('  ')[1];
    }
  }

  getValue(assigneeId: string) {
    const { name, value } = this.assigneeNames.filter(
      (assignee) => assignee.value === assigneeId
    )[0];
    this.userId = value;
    this.editTicketForm.get('assignee')?.setValue(this.userId);
    this.oldAssignee = this.assignedTo;
    this.newAssignee = name;
    this.disableButton = false;
    this.isAssigneeChanged = true;
    if (!this.ticketData.ticketDetails.assignedTo) {
      this.hasPriority = false;
    }
  }

  triggerModal(modal: string) {
    if (modal === 'assignee-confirmation-popup') {
      const popupConfig = {
        headerIcon: 'warning',
        title: 'Are you sure you want to change assignee',
        subtitle: 'Performing this action will change assignee for this ticket',
        data: {
          Details: {
            'Ticket No': this.ticketData.ticketDetails.ticketNo,
            'Ticket Type':
              this.ticketData.ticketDetails.type === 'REPAIR/REPLACEMENT'
                ? 'Report An Issue'
                : 'Request an Asset',
            'Old Assignee': this.oldAssignee,
            'New Assignee': this.newAssignee,
          },
        },
        primaryButtonText: 'Cancel',
        secondaryButtonText: 'Change Assignee',
      };
      const dialogRef = this.dialog.open<string>(GenericPopUpComponent, {
        panelClass: 'center-dialog-box',
        backdropClass: 'backdrop',
        data: popupConfig,
        width: '50%',
      });
      dialogRef.closed.subscribe((result) => {
        if (result === 'Change Assignee') {
          this.updateTicket();
        } else {
          this.disableButton = false;
          this.assignedTo = this.oldAssignee;
        }
      });
    }
  }
  displayCount(value: any) {
    this.countData = value;
  }
  onSubmit() {
    this.disableButton = true;
    if (this.isAssigneeChanged && this.assignedTo !== 'Select assignee') {
      this.triggerModal('assignee-confirmation-popup');
      this.message = 'Assignee changed Successfully';
    } else if (this.isAssigneeChanged && !this.isPriorityChanged) {
      this.message = 'Assignee and Priority changed successfully';
      this.updateTicket();
    } else if (this.isAssigneeChanged) {
      this.message = 'Assignee changed Successfully';
      this.updateTicket();
    } else if (!this.isPriorityChanged) {
      this.message = 'Priority changed Successfully';
      this.updateTicket();
    }
  }
  onClose(value: string) {
    this.editTicketForm.reset();
    this.dialogRef.removePanelClass('dialog-box');
    this.dialogRef.addPanelClass('close-dialog');
    const current = this;
    setTimeout(() => {
      current.dialogRef.close(value);
    }, 1000);
  }
  updateTicket() {
    let updatedData: IUpdatedData = {
      assignedTo: this.userId,
      id: this.data.ticketId,
      priorityId: this.priorityId,
      priorityChangeReason: this.editTicketForm.value.description,
    };
    this.commonHttpService.updateTicketAssignee(updatedData).subscribe({
      next: () => {
        this.onClose(this.message);
      },
      error: (error) => {
        this.onClose('error');
      },
    });
  }
}
