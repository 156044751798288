<div class="main-container">
  <!-- For raise ticket section -->
  <div class="drop-down-container" *ngIf="from === 'raise_issue'">
    <div
      class="head"
      [ngClass]="{ disabled: disabled }"
      (click)="showOptions()"
    >
      <div class="head-title" [ngClass]="{ focus: showDropDownOptions }">
        <div class="selected-item">
          <img
            class="icons"
            *ngIf="icon"
            src="assets/24x24/{{ icon }}.svg"
            alt="{{ icon }}"
          />
          <span class="title">{{ title }}</span>
        </div>
        <img class="icons" src="assets/12x12/sort-asc.svg" alt="expand" />
      </div>
    </div>
    <div
      class="options"
      *ngIf="showDropDownOptions"
      [ngClass]="{ 'is-down': showDropContainerTop }"
    >
      <div
        class="option"
        (click)="getOption(option.assetName || option, i)"
        *ngFor="let option of options; let i = index"
        [ngClass]="{ active: option === title }"
      >
        <div
          class="option-item asset-details"
          *ngIf="option.assetName; else isNotAssetDetailsObject"
        >
          <span class="option-name">{{ option.assetName | titlecase }}</span>
          <small class="option-asset-code">{{ option.assetCode }}</small>
        </div>
        <ng-template #isNotAssetDetailsObject>
          <div class="option-item" [ngClass]="{ active: option === title }">
            <span
              class="option-name"
              *ngIf="option !== title; else otherOptions"
              >{{ option | titlecase }}</span
            >
            <ng-template #otherOptions>
              <span class="option-name">{{ title | titlecase }}</span>
              <img src="assets/12x12/check.svg" alt="" srcset="" />
            </ng-template>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <!-- For 'request an asset' section -->
  <div class="drop-down-container" *ngIf="from === 'request_asset'">
    <div class="head" (click)="showOptions()">
      <div class="head-title" [ngClass]="{ focus: showDropDownOptions }">
        <div class="selected-item">
          <img
            class="icons"
            *ngIf="icon"
            src="assets/24x24/{{ icon }}.svg"
            alt="{{ icon }}"
          />
          <span class="title">{{ title }}</span>
        </div>
        <img class="icons" src="assets/12x12/sort-asc.svg" alt="expand" />
      </div>
    </div>
    <div
      class="options"
      *ngIf="showDropDownOptions"
      [ngClass]="{ 'is-down': showDropContainerTop }"
    >
      <div class="option" *ngFor="let option of options; let i = index">
        <div
          class="option-item"
          (click)="getAssetType(option.assetId, option.assetName)"
        >
          <span class="option-name">{{ option.assetName }}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- For 'add an asset' section -->
  <div class="drop-down-container" *ngIf="from === 'add_asset'">
    <div class="head" (click)="showOptions()">
      <div class="head-title" [ngClass]="{ focus: showDropDownOptions }">
        <div class="selected-item">
          <img
            class="icons"
            *ngIf="icon"
            src="assets/24x24/{{ icon }}.svg"
            alt="{{ icon }}"
          />
          <span class="title">{{ title }}</span>
        </div>
        <img class="icons" src="assets/12x12/sort-asc.svg" alt="expand" />
      </div>
    </div>
    <div
      class="options"
      *ngIf="showDropDownOptions"
      [ngClass]="{ 'is-down': showDropContainerTop }"
    >
      <div class="option" *ngFor="let option of options; let i = index">
        <div
          class="option-item"
          (click)="getAddAssetType(option.assetId, option.assetName)"
        >
          <span class="option-name">{{ option.assetName }}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- For 'bulk-upload' section -->
  <div class="drop-down-container" *ngIf="from === 'bulk_upload_list'">
    <div class="head" (click)="showOptions()">
      <div class="head-title" [ngClass]="{ focus: showDropDownOptions }">
        <div class="selected-item">
          <img
            class="icons"
            *ngIf="icon"
            src="assets/24x24/{{ icon }}.svg"
            alt="{{ icon }}"
          />
          <span class="title">{{ title }}</span>
        </div>
        <img class="icons" src="assets/12x12/sort-asc.svg" alt="expand" />
      </div>
    </div>
    <div
      class="options"
      *ngIf="showDropDownOptions"
      [ngClass]="{ 'is-down': showDropContainerTop }"
    >
      <div class="option" *ngFor="let option of options; let i = index">
        <div
          class="option-item"
          (click)="getAssetType(option.id, option.title)"
        >
          <span class="option-name">{{ option.title }}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- For 'bulk-download' section -->
  <div class="drop-down-container" *ngIf="from === 'bulk_download_list'">
    <p *ngIf="label" class="label">{{ label }}</p>
    <div class="head" (click)="showOptions()">
      <div class="head-title" [ngClass]="{ focus: showDropDownOptions }">
        <div class="selected-item">
          <img
            class="icons"
            *ngIf="icon"
            src="assets/24x24/{{ icon }}.svg"
            alt="{{ icon }}"
          />
          <span class="title">{{ title }}</span>
        </div>
        <img class="icons" src="assets/12x12/sort-asc.svg" alt="expand" />
      </div>
    </div>
    <div
      class="options"
      *ngIf="showDropDownOptions"
      [ngClass]="{ 'is-down': showDropContainerTop }"
    >
      <div class="option" *ngFor="let option of options; let i = index">
        <div
          class="option-item"
          (click)="getAssetType(option.id, option.title)"
        >
          <span class="option-name">{{ option.title }}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- For 'search' section -->
  <div class="drop-down-container" *ngIf="from === 'search_section'">
    <div class="head" (click)="showOptions()">
      <div class="head-title" [ngClass]="{ focus: showDropDownOptions }">
        <div class="selected-item">
          <img
            class="icons"
            *ngIf="icon"
            src="assets/24x24/{{ icon }}.svg"
            alt="{{ icon }}"
          />
          <span class="title">{{ title }}</span>
        </div>
        <img class="icons" src="assets/12x12/sort-asc.svg" alt="expand" />
      </div>
    </div>
    <div
      class="options"
      *ngIf="showDropDownOptions"
      [ngClass]="{ 'is-down': showDropContainerTop }"
    >
      <div class="option" *ngFor="let option of options; let i = index">
        <div
          class="option-item"
          (click)="getAssetType(option.value, option.title)"
        >
          <span class="option-name">{{ option.title }}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- For 'common' section -->
  <div class="drop-down-container" *ngIf="from === 'common'">
    <div class="head" (click)="showOptions()">
      <div class="head-title" [ngClass]="{ focus: showDropDownOptions }">
        <div class="selected-item">
          <img
            class="icons"
            *ngIf="icon"
            src="assets/24x24/{{ icon }}.svg"
            alt="{{ icon }}"
          />
          <span class="title">{{ title }}</span>
        </div>
        <img class="icons" src="assets/12x12/sort-asc.svg" alt="expand" />
      </div>
    </div>
    <div
      class="options"
      *ngIf="showDropDownOptions"
      [ngClass]="{ 'is-down': showDropContainerTop }"
    >
      <div class="option" *ngFor="let option of options; let i = index">
        <div class="option-item" (click)="getOptions(option)">
          <span class="option-name">{{ option }}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- For 'common' section -->
  <div class="drop-down-container" *ngIf="from === 'common-dropdown'">
    <div class="head" (click)="showOptions()">
      <div class="head-title" [ngClass]="{ focus: showDropDownOptions }">
        <div class="selected-item">
          <img
            class="icons"
            *ngIf="icon"
            src="assets/24x24/{{ icon }}.svg"
            alt="{{ icon }}"
          />
          <span class="title">{{ title }}</span>
        </div>
        <img class="icons" src="assets/12x12/sort-asc.svg" alt="expand" />
      </div>
    </div>
    <div
      class="options"
      *ngIf="showDropDownOptions"
      [ngClass]="{ 'is-down': showDropContainerTop }"
    >
      <div class="option" *ngFor="let option of options; let i = index">
        <div
          class="option-item"
          (click)="selectOption(option[valueName], option[titleName])"
        >
          <span class="option-name">{{ option[titleName] }}</span>
        </div>
      </div>
    </div>
  </div>
  <!--for select an issue subcategory-->

  <div class="drop-down-container" *ngIf="from === 'select_an_issue'">
    <div class="head" (click)="showOptions()">
      <div
        class="head-title"
        [ngClass]="{ focus: showDropDownOptions, disabled: disabled }"
      >
        <div class="selected-item">
          <img
            class="icons"
            *ngIf="icon"
            src="assets/24x24/{{ icon }}.svg"
            alt="{{ icon }}"
          />
          <span class="title" *ngIf="!issues">{{ title }}</span>

          <span class="title" *ngIf="issues && approverDropDown"
            >Approvers ({{ issuesArray.length }})</span
          >
          <span class="title" *ngIf="issues && !approverDropDown"
            >Issues ({{ issuesArray.length }})</span
          >
        </div>

        <img class="icons" src="assets/12x12/sort-asc.svg" alt="expand" />
      </div>
    </div>
    <div
      class="options"
      *ngIf="showDropDownOptions"
      [ngClass]="{ 'change-layout': dropDownForApproverList }"
    >
      <div *ngIf="dropDownForApproverList" class="approverList-title">
        <span class="recommended-title">Recommended</span>
      </div>
      <div class="option" *ngFor="let option of options; let i = index">
        <hr *ngIf="i === 3 && dropDownForApproverList" class="separator" />
        <div
          class="option-item checkbox-container"
          [ngClass]="{ 'approver-field': i === 3 && dropDownForApproverList }"
          (click)="
            issueClicked(inputCheckbox, option[valueName], option['isChecked']);
            $event.stopImmediatePropagation()
          "
        >
          <div class="field">
            <input
              #inputCheckbox
              type="checkbox"
              class="checkbox"
              [ngClass]="{'approver-checkbox':approverDropDown}"
              [id]="option[valueName]"
              [checked]="option['isChecked']"
              [name]="option[valueName]"
              [value]="option[valueName]"
            />
            <label
              class="approver-optionLabel"
              [for]="option[valueName]"
              (click)="$event.stopPropagation()"
              *ngIf="dropDownForApproverList; else otherLabel"
            >
            <span class="title-text">{{option.titlename}}</span>
            <span class="email-text">{{option.email}}</span>
            </label>
            <ng-template #otherLabel>
              <label
                class="option-name"
                [for]="option[valueName]"
                (click)="$event.stopPropagation()"
                >{{ option[titleName] }}</label
              >
            </ng-template>
          </div>
          <div
            *ngIf="option[titleName] === 'Other' && isOthersSelected"
            class="text-field-width"
          >
            <app-textarea
              cols="30"
              rows="1"
              [isRequired]="true"
              [(ngModel)]="otherIssue"
              characterCounter
              maxLength="48"
              placeholder="Type other issues here"
              (ngModelChange)="emitIssue()"
              (click)="$event.stopPropagation()"
            >
            </app-textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="chips-wrapper">
      <span class="not-selected" *ngIf="issuesArray[0] === '' || !issueIdArray"
        >Please Select An Issue</span
      >
      <div class="issues-chips" *ngFor="let issue of issuesArray">
        <span class="issue" *ngIf="issuesArray[0] !== ''">{{ issue }}</span>
        <img
          class="close-icon"
          src="assets/10x10/close.svg"
          alt=""
          (click)="remove(issue)"
          *ngIf="issuesArray[0] !== ''"
        />
      </div>
    </div>
  </div>
</div>
