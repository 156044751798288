import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Output() modalClosed = new EventEmitter();
  @Input() title: string = '';
  @Input() subTitle: string = '';
  @Input() open: boolean = false;

  @Input() width: string = '31.25rem';
  @Input() height: string = 'auto';
  constructor() { }

  ngOnInit(): void { }

  closeModal(): void {
    this.open = false;
    this.modalClosed.emit();
  }

  addWidth() {
    return {
      width: this.width,
      height: this.height,
    };
  }
}
