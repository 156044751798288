<app-header *ngIf="hasHeader"></app-header>
<section class="container">
  <section *ngIf="displayBack()">
    <a routerLink="../signin" class="back-button-container">
      <span class="back-button-icon">
        <!-- <img class="back-icon" src="assets/20x20/arrow-back.svg" /> -->
        <img src="assets/20x20/arrow-back.svg" class="back-icon" />
      </span>
      <span class="back-button-text">Back</span>
    </a>
  </section>
  <main class="common-wrapper" [ngClass]="getWrapperClass()">
    <section
      class="common-privacy-policy-container privacy-policy-container"
      [ngClass]="getClass()"
      [@basicAnimation]
    >
      <header>
        <p class="main-heading">Privacy Policy for Stessa</p>
        <p class="date">Effective date: 13 January 2023</p>
        <p class="date">Last updated:13 January 2023</p>
      </header>
      <section class="margin-bottom">
        <p class="section-header">Introduction</p>
        <p>Welcome to Coditas.</p>
        <p>
          Coditas Solutions LLP (“us”, “we”, or “our”) operates Stessa
          (hereinafter referred to as “website”)
        </p>
        <p>
          Our Privacy Policy governs your visit to Stessa, and explains how we
          collect, safeguard and disclose information that results from your use
          of our website.
        </p>
        <p>
          We use your data to provide and improve our website. By using the
          website, you agree to the collection and use of information in
          accordance with this policy.
        </p>
        <p>
          Our Privacy Policy governs all use of our website and constitutes your
          agreement with us (“agreement”).
        </p>
      </section>
      <section class="margin-bottom">
        <p class="section-header">Definitions</p>
        <p class="section-secondary-header">
          For the purposes of this Privacy Policy:
        </p>
        <div>
          <span class="highlighted-text">Account</span>
          <span
            >means a unique account created for You to access our Service or
            parts of our Service.</span
          >
        </div>
        <div>
          <span class="highlighted-text">Affiliate</span>
          <span
            >means an entity that controls, is controlled by or is under common
            control with a party, where "control" means ownership of 50% or more
            of the shares, equity interest or other securities entitled to vote
            for election of directors or other managing authority.</span
          >
        </div>
        <div>
          <span class="highlighted-text">Application</span>
          <span>
            refers to Stessa, the software program provided by the Company.
          </span>
        </div>
        <div>
          <span class="highlighted-text">Company</span>
          <span
            >(referred to as either "the Company", "We", "Us" or "Our" in this
            Agreement) refers to Coditas Solutions LLP, X13, Konark Campus,
            Viman Nagar, Pune, Maharashtra 411014.
          </span>
        </div>
        <div>
          <span class="highlighted-text">Cookies</span>
          <span
            >are small files that are placed on Your computer, mobile device or
            any other device by a website, containing the details of Your
            browsing history on that website among its many uses.
          </span>
        </div>
        <div>
          <span class="highlighted-text">Country</span>
          <span>refers to: Maharashtra, India</span>
        </div>
        <div>
          <span class="highlighted-text">Device</span>
          <span
            >means any device that can access the Service such as a computer, a
            cell phone or a digital tablet.</span
          >
        </div>
        <div>
          <span class="highlighted-text">Personal Data</span>
          <span>
            is any information that relates to an identified or identifiable
            individual.</span
          >
        </div>
        <div>
          <span class="highlighted-text">Service</span>
          <span
            >refers to the Application or the Website or both. Service Provider
            means any natural or legal person who processes the data on behalf
            of the Company. It refers to third-party companies or individuals
            employed by the Company to facilitate the Service, to provide the
            Service on behalf of the Company, to perform services related to the
            Service or to assist the Company in analyzing how the Service is
            used.</span
          >
        </div>
        <div>
          <span class="highlighted-text">Third-party Social Media</span>
          <span>
            Service refers to any website or any social network website through
            which a User can log in or create an account to use the
            Service.</span
          >
        </div>
        <div>
          <span class="highlighted-text">Usage Data </span>
          <span>
            refers to data collected automatically, either generated by the use
            of the Service or from the Service infrastructure itself (for
            example, the duration of a page visit).</span
          >
        </div>
        <div>
          <span class="highlighted-text">Website</span>
          <span
            >refers to Stessa, accessible from
            https://stessa.coditas.org/signin</span
          >
        </div>
        <div>
          <span class="highlighted-text">You</span>
          <span>
            means the individual accessing or using the Service, or the company,
            or other legal entity on behalf of which such individual is
            accessing or using the Service, as applicable..</span
          >
        </div>
      </section>
      <section class="margin-bottom">
        <p class="section-header">Information Collection and Use</p>
        <div>
          <p>
            While using our website, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you (<span class="highlighted-text">“Personal Data”</span
            >).
          </p>
        </div>
        <div>
          <p>We collect following Personal Data:</p>
          <div class="profile-data">
            <ol>
              <li>Name</li>
              <li>Email Id</li>
              <li>Profile Picture</li>
            </ol>
          </div>
        </div>
        <div>
          <p>
            Other than this we are not intent to collect any personal
            information from the user. We may use your Personal Data to contact
            you with newsletters, marketing or promotional materials and other
            information that may be of interest to you. You may opt out of
            receiving any, or all, of these communications from us by following
            the unsubscribe link.
          </p>
        </div>
      </section>
      <section class="margin-bottom">
        <p class="section-header">Usage Data</p>
        <div>
          <p>
            We may also collect information that your browser sends whenever you
            visit our website or when you access the website by or through any
            device (“Usage Data”).
          </p>
        </div>
        <div>
          <p>
            This Usage Data may include information such as your computer’s
            Internet Protocol address (e.g. IP address), browser type, browser
            version, the pages of our website that you visit, the time and date
            of your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
          </p>
        </div>
        <div>
          <p>
            When you access the website with a device, this Usage Data may
            include information such as the type of device you use, your device
            unique ID, the IP address of your device, your device operating
            system, the type of Internet browser you use, unique device
            identifiers and other diagnostic data.
          </p>
        </div>
      </section>
      <section class="margin-bottom">
        <p class="section-header">Tracking Cookies Data</p>
        <div>
          <p>
            We use cookies and similar tracking technologies to track the
            activity on our website and we hold certain information.
          </p>
        </div>
        <div>
          <p>
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. You can instruct your browser to
            refuse all cookies or to indicate when a cookie is being sent.
            However, if you do not accept cookies, you may not be able to use
            some portions of our website.
          </p>
        </div>
      </section>
      <section>
        <p class="section-header">
          Information from Third-Party Social Media Services
        </p>
        <div>
          <p>
            The Company allows You to create an account and log in to use the
            Service through the following Third-party Social Media Service:
          </p>
        </div>
        <div><p>Google - Sign In</p></div>
        <div>
          <p>
            If you decide to register through or otherwise grant us access to a
            Third-Party Social Media Service, We may collect Personal data that
            is already associated with Your Third-Party Social Media Service's
            account, such as Your name, Your email address, Your activities or
            Your contact list associated with that account.
          </p>
        </div>
        <div>
          <p>
            You may also have the option of sharing additional information with
            the Company through Your Third-Party Social Media Service's account.
            If You choose to provide such information and Personal Data, during
            registration or otherwise, You are giving the Company permission to
            use, share, and store it in a manner consistent with this Privacy
            Policy.
          </p>
        </div>
      </section>
      <section class="margin-bottom">
        <p class="section-header">Use of Your Personal Data</p>
        <div>
          <p>The Company may use Personal Data for the following purposes:</p>
        </div>
        <div>
          <ul>
            <li>
              To provide and maintain our Service, including to monitor the
              usage of our Service
            </li>
            <li>
              To manage Your Account: to manage Your registration as a user of
              the Service. The Personal Data You provide can give You access to
              different functionalities of the Service that are available to You
              as a registered user.
            </li>
            <li>
              For the performance of a contract: the development, compliance and
              undertaking of the purchase contract for the products, items or
              services You have purchased or of any other contract with Us
              through the Service.For the performance of a contract: the
              development, compliance and undertaking of the purchase contract
              for the products, items or services You have purchased or of any
              other contract with Us through the Service.For the performance of
              a contract: the development, compliance and undertaking of the
              purchase contract for the products, items or services You have
              purchased or of any other contract with Us through the Service.For
              the performance of a contract: the development, compliance and
              undertaking of the purchase contract for the products, items or
              services You have purchased or of any other contract with Us
              through the Service.
            </li>
            <li>
              To contact You: To contact You by email, telephone calls, SMS, or
              other equivalent forms of electronic communication, such as a
              mobile application's push notifications regarding updates or
              informative communications related to the functionalities,
              products or contracted services, including the security updates,
              when necessary or reasonable for their implementation.
            </li>
            <li>
              To provide You with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless You have opted not to receive such information.
            </li>
            <li>
              To manage Your requests: To attend and manage Your requests to Us.
            </li>
            <li>
              For business transfers: We may use Your information to evaluate or
              conduct a merger, divestiture, restructuring, reorganization,
              dissolution, or other sale or transfer of some or all of Our
              assets, whether as a going concern or as part of bankruptcy,
              liquidation, or similar proceeding, in which Personal Data held by
              Us about our Service users is among the assets transferred.
            </li>
            <li>
              For other purposes: We may use Your information for other
              purposes, such as data analysis, identifying usage trends,
              determining the effectiveness of our promotional campaigns and to
              evaluate and improve our Service, products, services, marketing
              and your experience.
            </li>
          </ul>
        </div>
        <div>
          <p>
            We may share Your personal information in the following situations:
          </p>
        </div>
        <div>
          <ul>
            <li>
              With Service Providers: We may share Your personal information
              with Service Providers to monitor and analyze the use of our
              Service, to contact You.
            </li>
            <li>
              For business transfers: We may share or transfer Your personal
              information in connection with, or during negotiations of, any
              merger, sale of Company assets, financing, or acquisition of all
              or a portion of Our business to another company.
            </li>
            <li>
              With Affiliates: We may share Your information with Our
              affiliates, in which case we will require those affiliates to
              honor this Privacy Policy. Affiliates include Our parent company
              and any other subsidiaries, joint venture partners or other
              companies that We control or that are under common control with
              Us.
            </li>
            <li>
              With business partners: We may share Your information with Our
              business partners to offer You certain products, services or
              promotions.
            </li>
            <li>
              With other users: when You share personal information or otherwise
              interact in the public areas with other users, such information
              may be viewed by all users and may be publicly distributed
              outside. If You interact with other users or register through a
              Third-Party Social Media Service, Your contacts on the Third-Party
              Social Media Service may see Your name, profile, pictures and
              description of Your activity. Similarly, other users will be able
              to view descriptions of Your activity, communicate with You and
              view Your profile.
            </li>
            <li>
              With Your consent: We may disclose Your personal information for
              any other purpose with Your consent.
            </li>
          </ul>
        </div>
      </section>
      <section class="margin-bottom">
        <p class="section-header">Retention of Data</p>
        <div>
          <p>
            We will retain your Personal Data only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and
            use your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your
            data to comply with applicable laws), resolve disputes, and enforce
            our legal agreements and policies.
          </p>
        </div>
        <div>
          <p>
            We will also retain Usage Data for internal analysis purposes. Usage
            Data is generally retained for a shorter period, except when this
            data is used to strengthen the security or to improve the
            functionality of our website, or we are legally obligated to retain
            this data for longer time periods.
          </p>
        </div>
      </section>
      <section class="margin-bottom">
        <p class="section-header">Transfer of Data</p>
        <div>
          <p>
            Your information, including Personal Data, may be transferred to –
            and maintained on – computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction.
          </p>
        </div>
        <div>
          <p>
            If you are located outside Pune and choose to provide information to
            us, please note that we transfer the data, including Personal Data,
            to Pune and process it there.
          </p>
        </div>
        <div>
          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
        </div>
        <div>
          <p>
            Coditas Solutions LLP will take all the steps reasonably necessary
            to ensure that your data is treated securely and in accordance with
            this Privacy Policy and no transfer of your Personal Data will take
            place to an organization or a country.
          </p>
        </div>
      </section>
      <section class="margin-bottom">
        <p class="section-header">Security of Data</p>
        <div>
          <p>
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>
        </div>
      </section>
      <section class="margin-bottom">
        <p class="section-header">Analytics</p>
        <div>
          <p>
            We may use third-party Service Providers to monitor and analyze the
            use of our website.
          </p>
        </div>
      </section>
      <section class="margin-bottom">
        <p class="section-header">CI/CD tools</p>
        <div>
          <p>
            We may use third-party Service Providers to automate the development
            process of our website.
          </p>
        </div>
      </section>
      <section class="margin-bottom">
        <p class="section-header">Advertising</p>
        <div>
          <p>
            We may use third-party Service Providers to show advertisements to
            you to help support and maintain our website.
          </p>
        </div>
      </section>
      <section class="margin-bottom">
        <p class="section-header">Links to Other Sites</p>
        <div>
          <p>
            Our website may contain links to other sites that are not operated
            by us. If you click a third-party link, you will be directed to that
            third party’s site. We strongly advise you to review the Privacy
            Policy of every site you visit.
          </p>
        </div>
        <div>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third-party sites or
            services.
          </p>
        </div>
      </section>
      <section class="margin-bottom">
        <p class="section-header">Children's Privacy</p>
        <div>
          <p>
            Our website is not intended for use by children under the age of 18
            (“Child” or “Children”).
          </p>
        </div>
        <div>
          <p>
            We do not knowingly collect personally identifiable information from
            Children under 18. If you become aware that a Child has provided us
            with Personal Data, please contact us. If we become aware that we
            have collected Personal Data from Children without verification of
            parental consent, we take steps to remove that information from our
            servers.
          </p>
        </div>
      </section>
      <section class="margin-bottom">
        <p class="section-header">Changes to This Privacy Policy</p>
        <div>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
        </div>
        <div>
          <p>
            We will let you know via email and/or a prominent notice on our
            website, prior to the change becoming effective and update
            “Effective Date” at the top of this Privacy Policy.
          </p>
        </div>
        <div>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
        </div>
      </section>
      <section class="margin-bottom">
        <p class="section-header">Contact Us</p>
        <div>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us:
          </p>
        </div>
        <div>
          <p>
            By mail: No. 33, 3rd Floor, Gaia Apex, S, 2D, Viman Nagar, Pune,
            Maharashtra 411014
          </p>
        </div>
      </section>
    </section>
  </main>
</section>
