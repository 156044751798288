import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ROUTES } from '@common/interfaces';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(): boolean {
    const userDetails = JSON.parse(
      localStorage.getItem('userInfo') as string
    );
    if (userDetails.roles[0] === 'ADMIN') {
      return true;
    }
    this.router.navigate([ROUTES.SIGN_IN]);
    return false;
  }
}
