import { Dialog } from '@angular/cdk/dialog';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ASSET_TYPES,
  getAddAssetData,
  IAssetById,
  IAssetType,
  IClients,
  IEndpointSecurity,
  IMetrics,
  IMetricsData,
  IProjectDetails,
  IProjects,
  ISecurityOptions,
  IVendorNames,
  ROUTES,
  SPECIFICATIONS,
} from '@common/interfaces';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { AddEditAlertModelComponent } from '../add-edit-alert-model/add-edit-alert-model.component';
import { GenericConfirmationPopUpComponent } from '../generic-confirmation-pop-up/generic-confirmation-pop-up.component';
import { GenericPopUpComponent } from '../generic-pop-up/generic-pop-up.component';
import { ConfirmationPopupWithFormComponent } from '../confirmation-popup-with-form/confirmation-popup-with-form.component';
import { ToastrService } from 'ngx-toastr';





@Component({
  selector: 'app-add-asset-modal',
  templateUrl: './add-asset-modal.component.html',
  styleUrls: ['./add-asset-modal.component.scss'],
})
export class AddAssetModalComponent {
  @Output() closeModal = new EventEmitter();
  @Output() alertBoxEmitter = new EventEmitter();
  constructor(
    private commonHttpService: CommonHttpService,
    public dialog: Dialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService
  ) {}
  endPointSecurityDefaultOption = '';
  pageTitle: string = 'Add an Asset';
  assetDetails!: IAssetById;
  assetType!: string;
  isDisable: boolean = true;
  hasError!: boolean;
  options: IAssetType[] = ASSET_TYPES;
  processors: string[] = [];
  locations: string[] = [];
  operatingSystems: string[] = [];
  hardDisks!: IMetrics[];
  warranty: string[] = [];
  ram!: IMetrics[];
  endPointSecurityOptions!: IEndpointSecurity[];
  screenSize!: IMetrics[];
  screenSizTitle!: string;
  selectedOption: string = '';
  isMobileSelected: boolean = false;
  assetCategoryId!: string;
  dataObject!: IMetricsData;
  initialValue!: any;
  hasChange: boolean = false;
  specifications = SPECIFICATIONS;
  ROUTES = ROUTES;
  assetId!: string;
  date!: string;
  dialogRef: any;
  assetCodePattern = '^(CSL-L|CTPL|CTPL-CL)-(dd)-(dd)-(M|L|KB|MS|C|H|MB)-dddd$';
  maxDate = new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd');
  assetCode: string = '';
  vendorNames: string[] = [];
  projectNames: string[] = [];
  clientNames: string[] = [];
  clientsData: IClients[] = [];
  projectsData: IProjects[] = [];
  loaderView: boolean = false;
  assetCodeErrorMsg!: string;
  addAssetForm = new UntypedFormGroup({
    assetCode: new UntypedFormControl('', [Validators.required]),
    type: new UntypedFormControl('', [Validators.required]),
    modelName: new UntypedFormControl('', [Validators.required]),
    location: new UntypedFormControl('', [Validators.required]),
    purchaseDate: new UntypedFormControl('', [Validators.required]),
    vendorName: new UntypedFormControl('', [Validators.required]),
    warranty: new UntypedFormControl('', [Validators.required]),
    projectName: new UntypedFormControl(''),
    clientName: new UntypedFormControl(''),
    addProject: new UntypedFormControl(''),
    addClient: new UntypedFormControl(''),
  });
  backRouterLink!: string;
  selectedForm!: UntypedFormGroup;

  ngOnInit(): void {
    this.backRouterLink = ROUTES.ADMIN_INVENTORY;
    this.activatedRoute.params.subscribe((param) => {
      this.assetId = param['id'];
    });
    if (this.assetId) {
      this.loaderView = true;
      this.backRouterLink = `${ROUTES.ASSET_DETAILS}${this.assetId}`;
      this.pageTitle = 'Edit an Asset';
      this.commonHttpService.getAssetDetails(this.assetId).subscribe({
        next: (response) => {
          this.assetDetails = Object(response).data.asset;
          this.assetDetails.clientName =
            Object(response).data.asset.clientName?.name;
          this.assetDetails.projectName =
            Object(response).data.asset.projectName?.name;
          this.selectedOption = this.assetDetails.asset_type.name;
          this.assetCategoryId = this.assetDetails.asset_type.id;
          this.getValueOfCategory(
            this.selectedOption + '  ' + this.assetCategoryId
          );
          this.loaderView = false;
        },
        error: () => {
          this.loaderView = false;
          this.hasError = true;
        },
      });
    }
    // this.onAssetCodeValueChange();
  }
  onAssetCodeValueChange() {
    this.addAssetForm.controls['assetCode'].valueChanges.subscribe((value) => {
      if (this.addAssetForm.controls['assetCode']?.value?.length >= 8) {
        this.commonHttpService
          .verifyAssetCode(this.addAssetForm.controls['assetCode'].value)
          .subscribe({
            next: (response) => {
              this.assetCodeErrorMsg = '';
              this.addAssetForm.get('assetCode')?.setValue(this.addAssetForm.controls['assetCode'].value);
            },
            error: (errorResponse: HttpErrorResponse) => {
              this.assetCodeErrorMsg =
                errorResponse?.error?.error?.errorList[0].msg;
            },
          });
      } else {
        this.assetCodeErrorMsg = '';
      }
    });
  }

  getValue(value: string) {
    this.addAssetForm.get('type')?.setValue(value);
    this.assetType = this.selectedOption;
  }
  getScreeSizeValue(value: string, from?: string) {
    this.addAssetForm
      .get(from || 'laptopFields')
      ?.get('screenSizeUnit')
      ?.setValue(value);
  }
  getRamValue(value: string) {
    if (this.addAssetForm?.get('mobileFields')) {
      this.addAssetForm.get('mobileFields')?.get('ramUnit')?.setValue(value);
    } else {
      this.addAssetForm.get('laptopFields')?.get('ramUnit')?.setValue(value);
    }
  }
  getDiskSpace(value: string) {
    this.addAssetForm
      .get('laptopFields')
      ?.get('diskSpaceUnit')
      ?.setValue(value);
  }

  getLocation(value: string) {
    this.addAssetForm.get('location')?.setValue(value);
  }

  getUnit(unitName: string, from?: string) {
    const ramUnit = 'ramUnit';
    const screenSizeUnit = 'screenSizeUnit';
    let value = '';

    if (this.addAssetForm.get(from || 'laptopFields')?.get(unitName)?.value) {
      if (unitName === ramUnit) {
        this.ram?.forEach((item: IMetrics) => {
          if (
            item.id ===
            this.addAssetForm.get(from || 'laptopFields')?.get(unitName)?.value
          ) {
            value = item.unit;
          }
        });
      } else if (unitName === screenSizeUnit) {
        this.screenSize?.forEach((item: IMetrics) => {
          if (
            item.id ===
            this.addAssetForm.get(from || 'laptopFields')?.get(unitName)?.value
          ) {
            value = item.unit;
          }
        });
      } else if (unitName === 'diskSpaceUnit') {
        this.hardDisks.forEach((item: IMetrics) => {
          if (
            item.id ===
            this.addAssetForm.get(from || 'laptopFields')?.get(unitName)?.value
          ) {
            value = item.unit;
          }
        });
      }
      return value;
    } else {
      return 'Unit';
    }
  }

  getEndPointSecurityName(name: string) {
    let value = 'Select';
    const endPointSecurityId = this.addAssetForm
      .get('laptopFields')
      ?.get(name)?.value;
    if (name === 'endPointSecurityId' && endPointSecurityId) {
      this.endPointSecurityOptions?.forEach((item: IEndpointSecurity) => {
        if (item.id === endPointSecurityId) {
          value = item.securityManager;
        }
      });
    }
    return value;
  }
  getCategoryName(name: string) {
    let value = 'Select';
    if (name === 'type') {
      this.options.forEach((item: IAssetType) => {
        if (item.assetId === this.addAssetForm.get(name)?.value) {
          value = item.assetName;
        }
      });
    }
    return value;
  }
  getProcessor(value: string) {
    this.addAssetForm.get('laptopFields')?.get('processor')?.setValue(value);
  }
  getOperatingSystem(value: string) {
    this.addAssetForm
      .get('laptopFields')
      ?.get('operatingSystem')
      ?.setValue(value);
  }
  getWarranty(value: string) {
    this.addAssetForm.get('type')?.setValue(this.assetType);
    this.addAssetForm.get('warranty')?.setValue(parseInt(value.split(' ')[0]));
  }
  getEndPointSecurityId(value: string) {
    this.addAssetForm
      .get('laptopFields')
      ?.get('endPointSecurityId')
      ?.setValue(value);
  }
  getVendorName(value: string) {
    this.addAssetForm?.get('vendorName')?.setValue(value);
  }

  getClientName(value: string) {
    this.addAssetForm?.get('clientName')?.setValue(value);
  }

  getProjectName(value: string) {
    this.addAssetForm?.get('projectName')?.setValue(value);
  }

  getNewProjectName(value: string) {
    this.addAssetForm?.get('addProject')?.setValue(value);
  }
  getNewClientName(value: string) {
    this.addAssetForm?.get('addClient')?.setValue(value);
  }

  assetCodeField = this.addAssetForm.get('assetCode');

  addProject() {
    const formData = {
      header: 'Add Project Name',
      description: 'Please enter the project name',
      image: 'cautionImage',
      form: {
        confirmationForm: new UntypedFormGroup({
          addProject: new UntypedFormControl('', [Validators.required]),
        }),
      },
      fields: [
        {
          field: 'text-field',
          controlName: 'addProject',
          placeholder: 'Enter Project Name',
          label: 'Project Name',
        },
      ],
      secondaryBtn: 'Cancel',
      primaryBtn: 'Add',
    };
    const dialogRef = this.dialog.open(ConfirmationPopupWithFormComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      disableClose: true,
      width: '50%',
      data: formData,
    });

    dialogRef.closed.subscribe((result) => {
      if (result) {
        let projectData = {
          projectName: Object(result).addProject,
        };
        this.getNewProjectName(Object(result).addProject);

        this.commonHttpService.addProjects(projectData).subscribe({
          next: (result) => {
            if (Object(result).statusCode === 200) {
              this.toastrService.success(
                'Project has been added successfully',
                '',
                {
                  toastClass: 'toaster-wrapper',
                }
              );
              this.getProjectList();
            }
          },
          error: (error: HttpErrorResponse) => {
            const { errorList } = error.error.error;
            this.toastrService.error(
              errorList?.errors[0].message || 'Something went wrong!',
              '',
              {
                toastClass: 'toaster-wrapper',
              }
            );
          },
        });
      }
    });
  }
  addClient() {
    const formData = {
      header: 'Add Client Name',
      description: 'Please enter the client name',
      image: 'cautionImage',
      form: {
        confirmationForm: new UntypedFormGroup({
          addClient: new UntypedFormControl('', [Validators.required]),
        }),
      },
      fields: [
        {
          field: 'text-field',
          controlName: 'addClient',
          placeholder: 'Enter client Name',
          label: 'Client Name',
        },
      ],
      secondaryBtn: 'Cancel',
      primaryBtn: 'Add',
    };
    const dialogRef = this.dialog.open(ConfirmationPopupWithFormComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      disableClose: true,
      width: '50%',
      data: formData,
    });

    dialogRef.closed.subscribe((result) => {
      if (result) {
        let clientData = {
          clientName: Object(result).addClient,
        };
        this.getNewClientName(Object(result).addClient);

        this.commonHttpService.addClients(clientData).subscribe({
          next: (result) => {
            if (Object(result).statusCode === 200) {
              this.toastrService.success(
                'Client has been added successfully',
                '',
                {
                  toastClass: 'toaster-wrapper',
                }
              );
              this.getClientList();
            }
          },
          error: (error: HttpErrorResponse) => {
            const { errorList } = error.error.error;
            this.toastrService.error(
              errorList?.errors[0].message || 'Something went wrong!',
              '',
              {
                toastClass: 'toaster-wrapper',
              }
            );
          },
        });
      }
    });
  }
  getValueOfCategory(value: string) {
    this.addAssetForm.reset();
    this.addAssetForm.get('type')?.setValue(value);
    this.selectedOption = this.addAssetForm.get('type')?.value.split('  ')[0];
    if (this.selectedOption === 'Mobile Phone') {
      this.isMobileSelected = true;
    } else {
      this.isMobileSelected = false;
    }
    this.assetType = this.addAssetForm.get('type')?.value.split('  ')[1];
    this.selectNewForm(this.selectedOption);
    this.getMetricsData();
    this.getAllOperatingSystems();
    this.getAllProcessors();
    this.getVendorsList();
    this.getProjectList();
    this.getClientList();
    this.getAllWarrantyList();
    this.getEndpointSecurityData();
    // if (!this.assetDetails?.assetCode) {
    //   this.getAssetCode(this.assetType);
    // }

    if (this.assetId) {
      this.addAssetForm.get('assetCode')?.disable();
      this.addAssetForm.get('type')?.disable();
      this.authoFillForm(this.selectedOption);
    }
  }

  

  authoFillForm(assetType: string) {
    this.addAssetForm.get('assetCode')?.setValue(this.assetDetails.assetCode);
    this.addAssetForm.get('modelName')?.setValue(this.assetDetails.modelName);
    this.addAssetForm
      .get('laptopFields')
      ?.get('ram')
      ?.setValue(this.assetDetails.ram);
    this.addAssetForm
      .get('laptopFields')
      ?.get('diskSpace')
      ?.setValue(this.assetDetails.diskSpace);
    this.addAssetForm
      .get('laptopFields')
      ?.get('diskSpaceUnit')
      ?.setValue(this.assetDetails.diskSpaceUnit);
    this.addAssetForm.get('location')?.setValue(this.assetDetails.location);
    this.addAssetForm
      .get('laptopFields')
      ?.get('processor')
      ?.setValue(this.assetDetails.processor);
    let serialNoField = '';
    if (assetType === 'Mobile Phone') {
      serialNoField = 'mobileFields';
    } else if (assetType === 'Laptop') {
      serialNoField = 'laptopFields';
    } else if (assetType === 'Monitor') {
      serialNoField = 'monitorFields';
    }
    this.addAssetForm
      .get(serialNoField)
      ?.get('ram')
      ?.setValue(this.assetDetails.ram);
    this.addAssetForm
      .get(serialNoField)
      ?.get('ramUnit')
      ?.setValue(this.assetDetails.ramUnit);
    this.addAssetForm
      ?.get(serialNoField)
      ?.get('serialNo')
      ?.setValue(this.assetDetails.serialNo);
    this.addAssetForm
      .get('purchaseDate')
      ?.setValue(this.assetDetails.purchaseDate);
    this.addAssetForm
      .get('laptopFields')
      ?.get('operatingSystem')
      ?.setValue(this.assetDetails.operatingSystem);
    this.addAssetForm
      .get('laptopFields')
      ?.get('ramUnit')
      ?.setValue(this.assetDetails.ramUnit);
    this.addAssetForm
      .get('mobileFields')
      ?.get('ramUnit')
      ?.setValue(this.assetDetails.ramUnit);
    const screenSizeField =
      assetType === 'Monitor' ? 'monitorFields' : 'laptopFields';
    this.addAssetForm
      .get(screenSizeField)
      ?.get('screenSize')
      ?.setValue(parseInt(this.assetDetails.screenSize));
    this.addAssetForm
      .get(screenSizeField)
      ?.get('screenSizeUnit')
      ?.setValue(this.assetDetails.screenSizeUnit);

    this.addAssetForm.get('vendorName')?.setValue(this.assetDetails.vendorName);
    this.addAssetForm
      .get('projectName')
      ?.setValue(this.assetDetails.projectName);
    this.addAssetForm.get('clientName')?.setValue(this.assetDetails.clientName);
    this.addAssetForm.get('warranty')?.setValue(this.assetDetails.warranty);
    this.addAssetForm
      .get('laptopFields')
      ?.get('endPointSecurityId')
      ?.setValue(this.assetDetails.endPointSecurityId);
    this.addAssetForm
      .get('diskSpaceUnit')
      ?.setValue(this.assetDetails.diskSpaceUnit);
    this.addAssetForm.get('ramUnit')?.setValue(this.assetDetails.ramUnit);

    this.initialValue = {
      ...this.addAssetForm.value,
    };
  }

  ngDoCheck() {
    this.addAssetForm.valueChanges.subscribe((value) => {
      this.hasChange = this.initialValue
        ? Object.keys(this.initialValue).some(
            (key) => this.addAssetForm.value[key] != this.initialValue[key]
          )
        : false;
    });
  }

  generateCode(event: any) {
    this.addAssetForm.get('assetCode')?.enable();
    this.getAssetCode(this.assetType);
  }

  getAssetCode(assetTypeId: string) {
    this.commonHttpService.getAssetCode(assetTypeId).subscribe({
      next: (response: any) => {
        const assetCode = response.data.newAssetCodes[0];
        this.addAssetForm
          .controls['assetCode']
          ?.setValue(assetCode);
        // this.assetCodeField?.disable();
      },
    });
  }

  getMetricsData() {
    this.locations = [];
    this.commonHttpService.getAssestLocationsList().subscribe({
      next: (response: any) => {
        let keys = Object.keys(response.data);
        keys.forEach((key) => {
          this.locations.push(response.data[key]);
        });
      },
    });
    this.commonHttpService.getMetricsData().subscribe({
      next: (response: IMetricsData) => {
        this.dataObject = response;
        this.ram = response.data.ram;
        this.hardDisks = response.data.diskSpace;
        this.screenSize = response.data.screenSize;
      },
      error: (error) => {},
      complete: () => {},
    });
  }

  getEndpointSecurityData() {
    this.commonHttpService.getEndPointSecurityData().subscribe({
      next: (response: ISecurityOptions) => {
        this.endPointSecurityOptions = response.data;
      },
    });
  }

  getAllOperatingSystems() {
    this.commonHttpService.getAllOperatingSystems().subscribe({
      next: (response) => {
        this.operatingSystems = Object.values(response.data);
      },
    });
  }

  getAllWarrantyList() {
    this.commonHttpService.getAllWarrantyList().subscribe({
      next: (response) => {
        this.warranty = Object.values(response.data).map((item) => {
          return item + ' Days';
        });
      },
    });
  }
  getAllProcessors() {
    this.commonHttpService.getAllProcessors().subscribe({
      next: (response) => {
        this.processors = Object.values(response.data);
      },
    });
  }

  getVendorsList() {
    this.vendorNames = [];
    this.commonHttpService.getAllVendorNames().subscribe({
      next: (response) => {
        Object(response.data)?.forEach((item: IVendorNames) => {
          this.vendorNames.push(item.name);
        });
      },
    });
  }

  getProjectList() {
    this.projectNames = [];
    this.commonHttpService.getAllProjectNames().subscribe({
      next: (response) => {
        this.projectsData = Object(response.data);
        Object(response.data)?.forEach((item: IProjects) => {
          this.projectNames.push(item.name);
        });
      },
    });
  }
  getClientList() {
    this.clientNames = [];
    this.commonHttpService.getAllClientNames().subscribe({
      next: (response) => {
        this.clientsData = Object(response.data);
        Object(response.data)?.forEach((item: IClients) => {
          this.clientNames.push(item.name);
        });
      },
    });
  }

  selectNewForm(option: string) {
    const laptop = 'Laptop';
    const monitor = 'Monitor';
    const headphone = 'Headphone';
    const mouse = 'Mouse';
    const Keyboard = 'Keyboard';
    const mobile = 'Mobile Phone';
    const charger = 'Charger';
    const cTypeConnector = 'C Type Connector';
    this.isDisable = false;
    if (option === laptop) {
      this.addAssetForm.addControl(
        'laptopFields',
        new FormGroup({
          ram: new UntypedFormControl(''),
          diskSpace: new UntypedFormControl('', [Validators.required]),
          processor: new UntypedFormControl('', [Validators.required]),
          serialNo: new UntypedFormControl('', [Validators.required]),
          operatingSystem: new UntypedFormControl('', [Validators.required]),
          screenSize: new UntypedFormControl('', [Validators.required]),
          endPointSecurityId: new UntypedFormControl('', [Validators.required]),
          screenSizeUnit: new UntypedFormControl('', [Validators.required]),
          diskSpaceUnit: new UntypedFormControl('', [Validators.required]),
          ramUnit: new UntypedFormControl('', [Validators.required]),
        })
      );
      this.addAssetForm
        .get('projectName')!
        .removeValidators([Validators.required]);
      this.addAssetForm
        .get('clientName')!
        .removeValidators([Validators.required]);
      this.addAssetForm
        .get('addProject')!
        .removeValidators([Validators.required]);
      this.addAssetForm
        .get('addClient')!
        .removeValidators([Validators.required]);
      this.addAssetForm.removeControl('monitorFields');
      this.addAssetForm.removeControl('headphoneFields');
      this.addAssetForm.removeControl('mobileFields');
      this.selectedForm = this.addAssetForm;
    } else if (option === monitor) {
      this.addAssetForm.addControl(
        'monitorFields',
        new FormGroup({
          screenSize: new UntypedFormControl([
            Validators.required,
            Validators.min(1),
          ]),
          screenSizeUnit: new UntypedFormControl('', [Validators.required]),
          serialNo: new UntypedFormControl('', [Validators.required]),
        })
      );
      this.addAssetForm
        .get('projectName')!
        .removeValidators([Validators.required]);
      this.addAssetForm
        .get('clientName')!
        .removeValidators([Validators.required]);
      this.addAssetForm
        .get('addProject')!
        .removeValidators([Validators.required]);
      this.addAssetForm
        .get('addClient')!
        .removeValidators([Validators.required]);
      this.addAssetForm.removeControl('laptopFields');
      this.addAssetForm.removeControl('headphoneFields');
      this.addAssetForm.removeControl('mobileFields');
      this.selectedForm = this.addAssetForm;
    } else if (option === headphone) {
      this.addAssetForm.addControl('headphoneFields', new FormGroup({}));
      this.addAssetForm
        .get('projectName')!
        .removeValidators([Validators.required]);
      this.addAssetForm
        .get('clientName')!
        .removeValidators([Validators.required]);
      this.addAssetForm
        .get('addProject')!
        .removeValidators([Validators.required]);
      this.addAssetForm
        .get('addClient')!
        .removeValidators([Validators.required]);
      this.addAssetForm.removeControl('laptopFields');
      this.addAssetForm.removeControl('monitorFields');
      this.addAssetForm.removeControl('mobileFields');
      this.selectedForm = this.addAssetForm;
    } else if (option === mouse) {
      this.removeFormControls();
      this.selectedForm = this.addAssetForm;
    } else if (option === Keyboard) {
      this.removeFormControls();
      this.selectedForm = this.addAssetForm;
    } else if (option === cTypeConnector) {
      this.removeFormControls();
      this.selectedForm = this.addAssetForm;
    } else if (option === mobile) {
      this.addAssetForm.addControl(
        'mobileFields',
        new FormGroup({
          serialNo: new UntypedFormControl('', [Validators.required]),
          ram: new UntypedFormControl(''),
          ramUnit: new UntypedFormControl('', [Validators.required]),
        })
      );
      this.addAssetForm
        .get('projectName')!
        .addValidators([Validators.required]);
      this.addAssetForm.get('clientName')!.addValidators([Validators.required]);
      // this.addAssetForm.get('addProject')!.addValidators([Validators.required]);
      // this.addAssetForm.get('addClient')!.addValidators([Validators.required]);
      this.addAssetForm.removeControl('laptopFields');
      this.addAssetForm.removeControl('monitorFields');
      this.addAssetForm.removeControl('headphoneFields');
      this.selectedForm = this.addAssetForm;
    } else if (option === charger) {
      this.removeFormControls();
      this.selectedForm = this.addAssetForm;
    }
    this.addAssetForm = this.selectedForm;

    if (this.assetId) {
      this.addAssetForm.get('type')?.setValue(this.assetCategoryId);
    } else {
      this.addAssetForm.get('type')?.setValue(this.assetType);
    }
  }

  removeFormControls() {
    this.addAssetForm
      .get('projectName')!
      .removeValidators([Validators.required]);
    this.addAssetForm
      .get('clientName')!
      .removeValidators([Validators.required]);
    this.addAssetForm
      .get('addProject')!
      .removeValidators([Validators.required]);
    this.addAssetForm.get('addClient')!.removeValidators([Validators.required]);
    this.addAssetForm.removeControl('laptopFields');
    this.addAssetForm.removeControl('monitorFields');
    this.addAssetForm.removeControl('headphoneFields');
    this.addAssetForm.removeControl('mobileFields');
  }

  onSubmit() {
    this.openConfirmationDialog();
  }

  openConfirmationDialog() {
    const dialogRef = this.dialog.open<string>(
      GenericConfirmationPopUpComponent,
      {
        panelClass: 'center-dialog-box',
        height: '11.75rem',
        backdropClass: 'backdrop',
        disableClose: true,
        data: {
          header: 'Do you want to submit the form?',
          description: 'This action cannot be undone',
          primaryBtn: 'Submit Form',
          secondaryBtn: 'Go Back',
          image: 'cautionImage',
        },
      }
    );
    dialogRef.closed.subscribe((result) => {
      if (result === 'Submit Form') {
        this.isDisable = true;
        this.date = this.addAssetForm.value.purchaseDate
          .split('-')
          .reverse()
          .join('/');

        this.addAssetForm.get('purchaseDate')?.setValue(this.date);
        const clientId = this.clientsData.find(
          (item: IClients) => item.name === this.addAssetForm.value?.clientName
        )?.id;
        const projectId = this.projectsData.find(
          (item: IProjects) =>
            item.name === this.addAssetForm.value?.projectName
        )?.id;
        const addAssetData = getAddAssetData(this.addAssetForm.value);

        addAssetData.clientId = clientId;
        addAssetData.projectId = projectId;
        addAssetData.assetCode =
          this.addAssetForm?.controls?.['assetCode'].value || '-';
        this.commonHttpService.addNewAsset(addAssetData).subscribe({
          next: (response) => {
            const alertBoxConfig = {
              headerIcon: 'success',
              title: 'Asset added successfully',
              subtitle: 'A new Asset has been added successfully',
              data: {
                'Asset Details': {
                  'Asset Category': this.selectedOption,
                  'Asset Code': Object(response).data.newAssetData.assetCode,
                },
              },
              primaryButtonText: 'Proceed to Dashboard',
              secondaryButtonText: 'View Asset Details',
            };
            const dialogRef = this.dialog.open<string>(GenericPopUpComponent, {
              hasBackdrop: true,
              backdropClass: 'backdrop',
              panelClass: 'center-dialog-box',
              data: alertBoxConfig,
            });

            dialogRef.closed.subscribe((result) => {
              this.addAssetForm.reset();
              if (result === 'Proceed to Dashboard') {
                this.router.navigate([ROUTES.ADMIN_INVENTORY]);
              } else if (result === 'View Asset Details') {
                this.router.navigate([
                  `${ROUTES.ASSET_DETAILS}${
                    Object(response).data.newAssetData.id
                  }`,
                ]);
              }
              this.addAssetForm.reset();
            });
          },
          error: (error: HttpErrorResponse) => {
            const errorMsg = error?.error?.error?.errorList[0]?.msg;
            const alertBoxConfig = {
              headerIcon: 'error',
              title: 'Asset could not be added',
              subtitle:
                errorMsg ||
                'Looks like you lost your connection. Please check your connection and try again',
              data: {
                'Asset Details': {
                  'Asset Category': this.selectedOption,
                  'Asset Code':
                    this.addAssetForm?.controls?.['assetCode'].value || '-',
                },
              },
              primaryButtonText: 'Cancel',
              secondaryButtonText: 'Try Again',
            };
            const dialogRef = this.dialog.open<string>(GenericPopUpComponent, {
              hasBackdrop: true,
              backdropClass: 'backdrop',
              panelClass: 'center-dialog-box',
              data: alertBoxConfig,
            });

            dialogRef.closed.subscribe((result) => {
              if (result === 'Try Again') {
                this.addAssetForm.reset();
              }
            });
          },
        });
      }
    });
  }
  backToDetails() {
    const dialogRef = this.dialog.open<string>(
      GenericConfirmationPopUpComponent,
      {
        panelClass: 'center-dialog-box',
        height: '11.75rem',
        backdropClass: 'backdrop',
        disableClose: true,
        data: {
          header: 'Are you sure you want to cancel?',
          description: 'All progress in this session will be lost',
          primaryBtn: 'Discard',
          secondaryBtn: 'Go Back',
          image: 'cautionImage',
        },
      }
    );
    dialogRef.closed.subscribe((response) => {
      if (response === 'Discard') {
        this.router.navigate([`${ROUTES.ASSET_DETAILS}${this.assetId}`]);
      }
    });
  }

  closeConfirmationDialog() {
    const dialogRef = this.dialog.open<string>(
      GenericConfirmationPopUpComponent,
      {
        panelClass: 'center-dialog-box',
        height: '11.75rem',
        backdropClass: 'backdrop',
        disableClose: true,
        data: {
          header: 'Are you sure you want to cancel?',
          description: 'All progress in this session will be lost',
          primaryBtn: 'Discard',
          secondaryBtn: 'Go Back',
          image: 'cautionImage',
        },
      }
    );
    dialogRef.closed.subscribe((response) => {
      if (response === 'Discard') {
        this.router.navigate([ROUTES.ADMIN_INVENTORY]);
      }
    });
  }

  onEditFormSubmit() {
    const dialogRef = this.dialog.open(GenericConfirmationPopUpComponent, {
      panelClass: 'center-dialog-box',
      height: '11.75rem',
      backdropClass: 'backdrop',
      disableClose: true,
      data: {
        header: 'Do you want to save changes?',
        description: 'Please review changes before saving.',
        primaryBtn: 'Save Changes',
        secondaryBtn: 'Keep Editing',
        image: 'cautionImage',
      },
    });
    dialogRef.closed.subscribe((result) => {
      if (result === 'Save Changes') {
        this.isDisable = true;
        this.date = this.addAssetForm.value.purchaseDate
          .split('-')
          .reverse()
          .join('/');

        this.addAssetForm.get('purchaseDate')?.setValue(this.date);
        const updateAssetData = getAddAssetData(this.addAssetForm.value) as any;
        const clientId = this.clientsData.find(
          (item: IClients) => item.name === this.addAssetForm.value?.clientName
        )?.id;
        const projectId = this.projectsData.find(
          (item: IProjects) =>
            item.name === this.addAssetForm.value?.projectName
        )?.id;
        updateAssetData['id'] = this.assetId;
        updateAssetData['assetCode'] = this.assetCodeField?.value;
        updateAssetData['type'] = this.assetType;

        updateAssetData.clientId = clientId;
        updateAssetData.projectId = projectId;
        this.commonHttpService.updateAsset(updateAssetData).subscribe({
          next: (response) => {
            const alertBoxConfig = {
              assetId: this.assetId,
              assetCode: this.assetCodeField?.value,
              assetName: this.selectedOption,
              title: 'Updated Successfully',
              description: 'Asset Details Updated Successfully.',
              color: '#ECFDF3',
              icon: 'tick',
              status: 'success',
              linkText: 'View Ticket',
              showOnlySnackbar: true,
              isError: false,
            };
            const dialogRef = this.dialog.open<string>(
              AddEditAlertModelComponent,
              {
                hasBackdrop: true,
                backdropClass: 'backdrop',
                panelClass: 'center-dialog-box',
                data: alertBoxConfig,
              }
            );

            dialogRef.closed.subscribe((result) => {
              this.router.navigate([`${ROUTES.ASSET_DETAILS}${this.assetId}`]);
              this.addAssetForm.reset();
            });
          },
          error: (error) => {
            const alertBoxConfig = {
              assetCode: this.assetCodeField?.value,
              assetName: this.selectedOption,
              assetId: this.assetId,
              title: 'Asset Update Failed!',
              description:
                error?.error?.error?.errorList[0]?.msg ||
                error.error.errorList ||
                'Asset could not be updated',
              color: '#FFFBFA',
              icon: 'fail',
              status: 'fail',
              linkText: '',
              showOnlySnackbar: true,
              isError: true,
            };
            const dialogRef = this.dialog.open<string>(
              AddEditAlertModelComponent,
              {
                hasBackdrop: true,
                backdropClass: 'backdrop',
                panelClass: 'center-dialog-box',
                data: alertBoxConfig,
              }
            );
            dialogRef.closed.subscribe((result) => {});
          },
        });
      }
    });
  }
  close(event: Event) {
    this.addAssetForm.reset();
    this.dialogRef.close();
  }
}
