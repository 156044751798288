import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { IFileInfo, PROGRESS_STATUS } from '@shared/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true,
    }
  ],
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, ControlValueAccessor {
  @Output() emitFileInfo = new EventEmitter<IFileInfo[]>();
  @Input() formControl!: UntypedFormControl;
  @Input() value: string = '';
  @Input() multiple = false;
  @Input() maxFileSizeInKB: number = 2048;
  @Input() maxVideoFileSizeInKB: number = 20480;
  @Input() hasImageAndVideo = false;
  @Input() fileTypesForValidations: any[] = [];

  files: IFileInfo[] = [];

  onChange: Function = (event: Event) => { };
  onTouched: Function = () => { };

  width: number = 10;
  @ViewChild('fileField') fileField!: ElementRef;

  constructor(private toasterService: ToastrService) { }

  // new changes
  @Input() progress: any;
  _files: File[] = [];


  ngOnInit(): void { }
  getStyle() {
    return { width: this.width + '%' };
  }

  writeValue(): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void { }

  removeFile(fileIndex: number) {
    this.files = this.files?.filter((file) => fileIndex !== file.id) || [];
    this.onChange(this._files);
    this.emitFileInfo.emit(this.files);
  }
  fileAdded(files: File[]) {
    if (this.multiple === false) {
      this.files = [];
    }

    files.forEach((file) => {
      if (this.fileTypesForValidations.includes(file.type)) {
        const fileSize = file.size;
        const fileSizeInKb = Math.round(fileSize / 1024);
        if (fileSizeInKb > this.maxFileSizeInKB && file.type.includes('image')) {
          this.toasterService.error(
            `File size should be less than ${ this.maxFileSizeInKB / 1024 } MB`,
            'Upload Failed!',
            { toastClass: 'toaster-wrapper' }
          );

          this.files.push({
            id: Math.floor(Math.random() * 1000),
            file: file,
            progressStatus: PROGRESS_STATUS.FAILED,
            progressValue: 0,
            showProgressBar: false,
            showRemoveFileBtn: true,
            originalname: file.name,
            mimetype: file.type,
            size: file.size,
          });
        }
        else if (fileSizeInKb > this.maxVideoFileSizeInKB && file.type.includes('video')) {
          this.toasterService.error(
            `File size should be less than ${ this.maxVideoFileSizeInKB / 1024 } MB`,
            'Upload Failed!',
            { toastClass: 'toaster-wrapper' }
          );
          this.files.push({
            id: Math.floor(Math.random() * 1000),
            file: file,
            progressStatus: PROGRESS_STATUS.FAILED,
            progressValue: 0,
            showProgressBar: false,
            showRemoveFileBtn: true,
            originalname: file.name,
            mimetype: file.type,
            size: file.size,
          });
        }
        else if (fileSizeInKb > this.maxFileSizeInKB && file.type.includes('csv')) {
          this.toasterService.error(
            `File size should be less than ${ this.maxFileSizeInKB / 1024 } MB`,
            'Upload Failed!',
            { toastClass: 'toaster-wrapper' }
          );
          this.files.push({
            id: Math.floor(Math.random() * 1000),
            file: file,
            progressStatus: PROGRESS_STATUS.FAILED,
            progressValue: 0,
            showProgressBar: false,
            showRemoveFileBtn: true,
            originalname: file.name,
            mimetype: file.type,
            size: file.size,
          });

        }
        else if (fileSizeInKb > this.maxFileSizeInKB && file.type.includes('pdf')) {
          this.toasterService.error(
            `File size should be less than ${ this.maxFileSizeInKB / 1024 } MB`,
            'Upload Failed!',
            { toastClass: 'toaster-wrapper' }
          );
          this.files.push({
            id: Math.floor(Math.random() * 1000),
            file: file,
            progressStatus: PROGRESS_STATUS.FAILED,
            progressValue: 0,
            showProgressBar: false,
            showRemoveFileBtn: true,
            originalname: file.name,
            mimetype: file.type,
            size: file.size,
          });
        }
        else {
          this.files.push({
            id: Math.floor(Math.random() * 1000),
            file: file,
            progressStatus: PROGRESS_STATUS.NONE,
            progressValue: 0,
            showProgressBar: true,
            showRemoveFileBtn: true,
            originalname: file.name,
            mimetype: file.type,
            size: file.size,
          })
        }
      } else {
        this.toasterService.error(
          `Incorrect file format`,
          'Upload Failed!',
          { toastClass: 'toaster-wrapper' }
        );
        this.files.push({
          id: Math.floor(Math.random() * 1000),
          file: file,
          progressStatus: PROGRESS_STATUS.FAILED,
          progressValue: 0,
          showProgressBar: false,
          showRemoveFileBtn: true,
          originalname: file.name,
          mimetype: file.type,
          size: file.size,
        })
      }
    });
    this.emitFileInfo.emit(this.files);
  }
}
