import { Component, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  AbstractControl,
} from '@angular/forms';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextFieldComponent,
      multi: true,
    },
  ],
})
export class TextFieldComponent implements OnInit, ControlValueAccessor {
  @Input() id: string = '';
  @Input() type: string = '';
  @Input() formControl!: AbstractControl;
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() value: string = '';
  @Input() icon: string = '';
  @Input() placeholder: string = '';
  @Input() hasError: any;
  @Input() isReadOnly = false;
  @Input() disabled = false;
  @Input() max!: any;
  @Input() min!: any;
  @Input() pattern!: string | RegExp;
  @Input() assetCode!: boolean;
  @Input() maxLength!: number;
  @Input() countData!: number[];
  onChange: Function = (event: Event) => {};
  onTouched: Function = () => {};

  constructor() {}

  writeValue(obj: string): void {
    this.value = obj;
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {}
}
