import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import {
  GSC_API_PATH,
  ILoginResponse,
  IPermissions,
  ROUTES,
} from '@common/interfaces';
import { CredentialResponse } from 'google-one-tap';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { ScriptLoader } from 'libs/common/src/lib/service/script-loader.service';
import { environment } from 'src/environments/environment';
import { SignInService } from './sign.in.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  ifConnected: boolean = window.navigator.onLine;
  userDetails: any = {};
  showTicketMsg: boolean = false;
  loaderView: boolean = false;

  constructor(
    private signInService: SignInService,
    private router: Router,
    private loadScript: ScriptLoader,
    private commonHttpService: CommonHttpService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    const scriptEl = this.loadScript.loadJsScript(this.renderer, GSC_API_PATH);

    scriptEl.onload = () => {
      const client_id = environment.GSC_CLIENT_ID;
      const divEl = document.getElementById('g_id_signin');
      const btnConfig = {
        type: 'standard',
        theme: 'outline',
        size: 'large',
        text: 'signin_with',
        shape: 'rectangular',
        logo_alignment: 'left',
        auto_select: true,
        itp_support: true,
      };

      // Initializing Google API
      // @ts-ignore
      google.accounts.id.initialize({
        client_id,
        callback: (response: CredentialResponse) => {
          this.signinWithGoogle(response);
        },
      });

      // Rendering button after initialization
      // @ts-ignore
      google.accounts.id.renderButton(divEl, btnConfig);
    };
  }

  signinWithGoogle = (response: CredentialResponse) => {
    try {
      this.loaderView = true;
      if (response.credential) {
        const loadRequest = this.signInService.signInWithGoogle(
          response.credential
        );
        loadRequest.subscribe({
          next: (response) => {
            const userInfo = Object(response) as ILoginResponse;
            this.loginUser(userInfo);
          },
        });
      }
    } catch (error) { }
  };

  setPermissions(userId: string, token: string) {
    const loadRequest = this.commonHttpService.getPermissions(userId, token);
    loadRequest.subscribe({
      next: (response: IPermissions) => {
        const permissions = response as IPermissions;
        const permissionsString = JSON.stringify(permissions.data.permissions);
        localStorage.setItem('permissions', permissionsString);
      },
    });
  }

  loginUser(user: ILoginResponse) {
    const { token, userId } = user.data;
    const { firstName, lastName, email, profilePictureUrl } =
      user.data.userData;
    if (token) {
      const userData = {
        firstName,
        lastName,
        email,
        photo_url: profilePictureUrl,
        roles: user.data.roles,
      };
      localStorage.setItem('userInfo', JSON.stringify(userData));
      localStorage.setItem('token', token || '');
      localStorage.setItem('userId', userId || '');
      const employeeDashboard = !user.data.isUserOnboarded
        ? '/home/welcome'
        : ROUTES.USER_DASHBOARD;
      const adminDashboard = ROUTES.ASSET_TEAM_DASHBOARD;
      const redirect =
        user.data.roles[0] === 'USER' ? employeeDashboard : adminDashboard;

      this.setPermissions(userId, token);
      this.router.navigate([redirect]);
    }
  }

  contactAdmin() {
    this.router.navigate(['contact-admin']);
  }
}
