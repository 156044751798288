import { Dialog } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { GenericPopUpComponent } from 'libs/shared/ui-components/src/lib/generic-pop-up/generic-pop-up.component';
import { OutForRepairModalComponent } from 'libs/shared/ui-components/src/lib/out-for-repair-modal/out-for-repair-modal.component';
import { RaiseTicketModalComponent } from 'libs/shared/ui-components/src/lib/raise-ticket-modal/raise-ticket-modal.component';
import { AccessInstallationModalComponent } from 'libs/shared/ui-components/src/lib/access-installation-modal/access-installation-modal.component';
import { RequestAssetModalComponent } from 'libs/shared/ui-components/src/lib/request-asset-modal/request-asset-modal.component';
import { MODALS } from '../constants/constants';
@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private dialog: Dialog) { }

  triggerModal(modal: string, data?: any) {
    let dialogRefData;
    if (modal === MODALS.ASSIGNEE_CONFIRMATION_POPUP) {
      const popupConfig = {
        headerIcon: 'warning',
        title: 'Are you sure you want to change assignee',
        subtitle: 'Performing this action will change assignee for this ticket',
        data: data,
        primaryButtonText: 'Cancel',
        secondaryButtonText: 'Change Assignee',
      };
      const dialogRef = this.dialog.open<string>(GenericPopUpComponent, {
        panelClass: 'center-dialog-box',
        backdropClass: 'backdrop',
        data: popupConfig,
        width: '50%',
      });
      dialogRefData = dialogRef.closed;
    } else if (modal === MODALS.ASSIGNEE_CONFIRMATION_POPUP_FIRST_TIME) {
      const popupConfig = {
        headerIcon: 'warning',
        title: `Are you sure you want to assign ${data?.['Details']?.['New Assignee']}`,
        subtitle: 'Performing this action will change assignee for this ticket',
        data: data,
        primaryButtonText: 'Cancel',
        secondaryButtonText: 'Assign',
      };
      const dialogRef = this.dialog.open<string>(GenericPopUpComponent, {
        panelClass: 'center-dialog-box',
        backdropClass: 'backdrop',
        data: popupConfig,
        width: '50%',
      });
      dialogRefData = dialogRef.closed;
    }
    else if (modal === MODALS.CHECKOUT_CONFIRMATION_POPUP) {
      const popupConfig = {
        headerIcon: 'warning',
        title: 'Do you want to check out?',
        subtitle: 'Please verify the details before checking out an asset',
        data: data,
        primaryButtonText: 'Cancel',
        secondaryButtonText: 'Check Out',
      };
      const dialogRef = this.dialog.open<string>(GenericPopUpComponent, {
        panelClass: 'center-dialog-box',
        backdropClass: 'backdrop',
        data: popupConfig,
        width: '50%',
      });
      dialogRefData = dialogRef.closed;
    }
    else if (modal === MODALS.DUPLICATE_ASSET_POPUP) {
      const popupConfig = {
        headerIcon: 'warning',
        title: 'Duplicate asset request',
        subtitle: `These user has requested the same type of asset they currently posses. Please
        review the user current asset allocation to check if an additional ne is needed.`,
        data: data,
        primaryButtonText: 'Back',
        secondaryButtonText: 'Submit',
      };
      const dialogRef = this.dialog.open<string>(GenericPopUpComponent, {
        panelClass: 'center-dialog-box',
        backdropClass: 'backdrop',
        data: popupConfig,
        width: '50%',
      });
      dialogRefData = dialogRef.closed;
    }
    else if (modal === MODALS.CHECKOUT_SUCCESS_CONFIRMATION_POPUP) {
      const popupConfig = {
        headerIcon: 'success',
        title: 'Check Out Successful',
        subtitle: 'Please verify the details before checking out an asset',
        data: data,
        primaryButtonText: '',
        secondaryButtonText: '',
      };
      const dialogRef = this.dialog.open<string>(GenericPopUpComponent, {
        panelClass: 'center-dialog-box',
        backdropClass: 'backdrop',
        data: popupConfig,
        width: '50%',
      });

      dialogRefData = dialogRef.closed;
    } else if (modal === MODALS.CHECKIN_CONFIRMATION_POPUP) {
      const popupConfig = {
        headerIcon: 'warning',
        title: 'Do you want to check in?',
        subtitle: 'Please verify the details before checking in an asset',
        data: data,
        primaryButtonText: 'Cancel',
        secondaryButtonText: 'Check In',
      };
      const dialogRef = this.dialog.open<string>(GenericPopUpComponent, {
        panelClass: 'center-dialog-box',
        backdropClass: 'backdrop',
        data: popupConfig,
        width: '50%',
      });

      dialogRefData = dialogRef.closed;
    } else if (modal === MODALS.CHECKIN_SUCCESS_CONFIRMATION_POPUP) {
      const popupConfig = {
        headerIcon: 'success',
        title: 'Check In Successful',
        subtitle: 'Please verify the details before checking in an asset',
        data: data,
        primaryButtonText: '',
        secondaryButtonText: '',
      };
      const dialogRef = this.dialog.open<string>(GenericPopUpComponent, {
        panelClass: 'center-dialog-box',
        backdropClass: 'backdrop',
        data: popupConfig,
        width: '50%',
      });

      dialogRefData = dialogRef.closed;
    } else if (modal === MODALS.RESEND_APPROVAL_CONFIRMATION) {
      const popupConfig = {
        headerIcon: 'warning',
        title: 'Do you want to resend verification request',
        subtitle:
          'Please verify the following details before sending for verification',
        data: data,
        primaryButtonText: 'Cancel',
        secondaryButtonText: 'Resend Request',
      };

      const dialogRef = this.dialog.open<string>(GenericPopUpComponent, {
        panelClass: 'center-dialog-box',
        backdropClass: 'backdrop',
        data: popupConfig,
        width: '50%',
      });

      dialogRefData = dialogRef.closed;
    } else if (modal === MODALS.REQUEST_APPROVAL_CONFIRMATION) {
      const popupConfig = {
        headerIcon: 'warning',
        title: `Do you want to request approval for ${data}`,
        subtitle:
          'Please verify the following details before requesting for approval',
        data: null,
        primaryButtonText: 'Cancel',
        secondaryButtonText: 'Request Approval',
      };
      const dialogRef = this.dialog.open<string>(GenericPopUpComponent, {
        panelClass: 'center-dialog-box',
        backdropClass: 'backdrop',
        data: popupConfig,
        width: '50%',
      });

      dialogRefData = dialogRef.closed;
    }
    else if (modal === MODALS.RAISE_TICKET_MODAL) {
      const dialogRef = this.dialog.open<string>(RaiseTicketModalComponent, {
        panelClass: 'dialog-box',
        backdropClass: 'backdrop',
        width: '50%',
      });
      dialogRefData = dialogRef.closed;
    }
    else if (modal === MODALS.REQUEST_ASSET_MODAL) {
      const dialogRef = this.dialog.open<string>(RequestAssetModalComponent, {
        panelClass: 'dialog-box',
        backdropClass: 'backdrop',
        width: '50%',
      });
      dialogRefData = dialogRef.closed;
    }
    else if (modal === MODALS.ACCESS_INSTALLATION_MODAL) {
      const dialogRef = this.dialog.open<string>(AccessInstallationModalComponent, {
        panelClass: 'dialog-box',
        backdropClass: 'backdrop',
        width: '50%',
      });
      dialogRefData = dialogRef.closed;
    }

    else if (modal === MODALS.OUT_FOR_REPAIR_MODAL) {
      const dialogRef = this.dialog.open<string>(OutForRepairModalComponent, {
        panelClass: 'dialog-box',
        backdropClass: 'backdrop',
        width: '50%',
        data: data,
      });
      dialogRefData = dialogRef.closed;
    }
    else if (modal === MODALS.CHECKOUT_FOR_REPAIR_SUCCESSFULL) {
      const popupConfig = {
        headerIcon: 'success',
        title: 'Checkout For Repair Successful',
        subtitle: 'Asset has been checked out for repair successfully',
        data: data,
        primaryButtonText: '',
        secondaryButtonText: '',
      };
      const dialogRef = this.dialog.open<string>(GenericPopUpComponent, {
        panelClass: 'center-dialog-box',
        backdropClass: 'backdrop',
        data: popupConfig,
        width: '50%',
      });
      dialogRefData = dialogRef.closed;
    }
    return dialogRefData;
  }
}
