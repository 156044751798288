import { Component, Input, OnInit } from '@angular/core';
import { IAssets } from '@common/interfaces';

@Component({
  selector: 'app-cards',
  templateUrl: './card.html',
  styleUrls: ['./card.scss'],
})
export class cardsComponent implements OnInit {
  @Input() assets: any;

  constructor() {}

  ngOnInit(): void {}
}
