import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';

export interface AppState { }

export const reducers: ActionReducerMap<AppState> = {};

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) => {
        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<AppState>[] = true ? [logger] : [];