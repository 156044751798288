import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { FormServiceService } from 'libs/shared/web/forms/form-service.service';
@Component({
  selector: 'app-dialog-alert-details',
  templateUrl: './dialog-alert-details.component.html',
  styleUrls: ['./dialog-alert-details.component.scss'],
})
export class DialogAlertDetailsComponent implements OnInit {
  @Input() ticketDetails!: any;
  @Output() ticketUpdates = new EventEmitter();
  @Output() markResolved = new EventEmitter();
  isAdmin!: boolean;
  options: string[] = [];
  index = -1;
  adminData!: any[];
  assignedTo = '';
  selectedAdmin = '';
  userId!: string;
  ticketStatus: string = '';
  constructor(private formService: FormServiceService) { }

  ngOnInit(ticket?: any): void {
    this.isAdmin =
      JSON.parse(localStorage.getItem('userInfo') as string).roles[0] ===
      'ADMIN';
    if (ticket || this.ticketDetails) {
      this.ticketDetails = ticket;
      this.ticketStatus = ticket.ticketDetails.status;
    }
    if (this.isAdmin) {
      this.formService.getAdmins().subscribe((res: any) => {
        this.adminData = res.data;
        this.options = [];
        res.data.forEach((user: any) => {
          this.options.push(user.firstName + ' ' + user.lastName);
          if (ticket?.ticketDetails?.assignedTo === user.userId) {
            this.assignedTo = user.firstName + ' ' + user.lastName;
          }
        });
      });
    }
  }

  getValue(value: string) {
    this.selectedAdmin = value;
  }

  getIndex(index: number) {
    let userId = 0;
    this.adminData.forEach((admin: any, adminIndex: number) => {
      if (index === adminIndex) {
        this.userId = admin.userId;
      }
    });
    this.index = index;
  }

  markAsResolved() {
    const ticketId: string = this.ticketDetails.ticketDetails.ticketId;
    this.markResolved.next({
      status: 'CLOSED',
      id: ticketId,
    });
  }

  updateTicket() {
    this.ticketUpdates.next({
      userId: this.userId,
    });
  }
}
