import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QueryParamsHandling, Router } from '@angular/router';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { filter } from 'rxjs';
import { ROUTES } from '@common/interfaces';

@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss'],
})
export class TableFilterComponent implements OnInit {
  @Input() filterOptions: any = [];
  @Input() activeMenu!: string;
  @Output() activeFilter = new EventEmitter<string>();
  @Output() ticketType = new EventEmitter<string>();
  @Input() showFilter: boolean = false;
  currentUrl = '';
  previousUrl!: any;
  role!: string;
  type!: string;
  isPrimaryButtonClicked = false;
  constructor(private router: Router, private commonService: CommonService) { }
  ngOnInit(): void {
    if (this.router.url.includes(ROUTES.ADMIN_TICKET)) {
      {
        const ticketType = this.commonService.ticketsType.value;
        if (ticketType === 'all') {
          this.activeMenu = 'all';
          this.updateFilter({ title: 'All Tickets', value: 'all' });
        } else if (ticketType === 'assigned') {
          this.activeMenu = 'assigned';
          this.updateFilter({ title: 'Assigned', value: 'assigned' });
        }
      }
    }
  }
  ngDoCheck() {
    if (this.commonService.ticketsType.value === 'all') {
      this.activeMenu = 'all';
      this.isPrimaryButtonClicked = false;
    } else if (this.commonService.ticketsType.value === 'assigned') {
      this.activeMenu = 'assigned';
      this.isPrimaryButtonClicked = true;
    }
    if (this.router.url.includes(ROUTES.ADMIN_TICKET_PERSONAL) || this.router.url.includes(ROUTES.USER_DASHBOARD) || (this.router.url === '/home/admin-ticket/admin-employee-dashboard?ticketType=assigned')) {
      this.showFilter = false;
    } else {
      this.showFilter = true;
    }
  }

  updateFilter(filter: any) {
    if (filter.value === 'assigned') {
      this.isPrimaryButtonClicked = true;
      const queryParams = { ticketType: 'assigned' };
      const navigationExtras = {
        queryParams,
        queryParamsHandling: 'preserve' as QueryParamsHandling,
      };
      this.router.navigate([], navigationExtras);
    } else if (filter.value === 'all') {
      this.isPrimaryButtonClicked = false;
      const queryParams = { ticketType: 'all' };
      const navigationExtras = {
        queryParams,
        queryParamsHandling: 'preserve' as QueryParamsHandling,
      };
      this.router.navigate([], navigationExtras);
    }
    this.activeMenu = filter.value;
    this.activeFilter.emit(filter.value);
    this.commonService.setTicketType(filter.value);
  }
}
