<div class="container">
  <div
    class="width-100"
    *ngIf="isAdmin && ticketDetails?.ticketDetails.status !== 'CLOSED'"
  >
    <h3>Assignee Details</h3>
    <div class="dropdown row width-100">
      <div class="col">
        <p>Ticket Assigned to</p>
      </div>
      <div class="col">
        <p>:</p>
      </div>
      <div class="col">
        <app-drop-down
          from="raise_issue"
          [options]="options"
          [title]="assignedTo || 'Select assignee'"
          (value)="getValue($event)"
          (index)="getIndex($event)"
        ></app-drop-down>
      </div>
    </div>
  </div>
  <div class="width-100">
    <h3>Asset details</h3>
    <div class="row width-100">
      <div class="col">
        <p>Asset Name</p>
        <p>Asset ID</p>
      </div>
      <div class="col">
        <p>:</p>
        <p>:</p>
      </div>
      <div class="col">
        <p>
          {{ ticketDetails?.assetDetails?.assetName || "-" | titlecase }}
        </p>
        <p>
          {{ ticketDetails?.assetDetails?.assetId || "-" }}
        </p>
      </div>
    </div>
  </div>
  <div class="width-100">
    <h3>Issue Description</h3>
    <div class="row width-100">
      <div class="col">
        <p>Description</p>
      </div>
      <div class="col">
        <p>:</p>
      </div>
      <div class="col">
        <p>
          {{ ticketDetails?.issueDescription?.description || "-" }}
        </p>
      </div>
    </div>
  </div>
  <div class="width-100">
    <h3>Ticket Details</h3>
    <div class="row width-100">
      <div class="col">
        <p>Ticket no.</p>
      </div>
      <div class="col">
        <p>:</p>
      </div>
      <div class="col">
        <p>
          {{ ticketDetails?.ticketDetails?.ticketNo || "-" }}
        </p>
      </div>
    </div>
  </div>
  <div
    class="btn-wrapper"
    *ngIf="isAdmin && ticketDetails?.ticketDetails.status !== 'CLOSED'"
  >
    <app-button
      class="spacing margin-top"
      type="submit"
      size="xsmall"
      [disabled]="ticketStatus === 'TO_DO'"
      theme="secondary"
      (click)="markAsResolved()"
      >Mark as Resolved</app-button
    >
    <app-button
      class="spacing margin-top"
      type="submit"
      size="xsmall"
      [disabled]="selectedAdmin === assignedTo || index === -1"
      theme="primary"
      (click)="updateTicket()"
      >Update Ticket</app-button
    >
  </div>
</div>
