<app-upload [multiple]="multiple" (emitFile)="fileAdded($event)">
  <ng-content></ng-content>
</app-upload>

<div *ngFor="let file of files; let i = index">
  <app-progress-info
    (emitId)="removeFile($event)"
    [progressInfo]="file"
    [hasImageAndVideo]="hasImageAndVideo"
  ></app-progress-info>
</div>
