import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input() type: string = '';
  @Input() routeTo: string = '';
  @Input() disabled: boolean | null = false;
  @Input() size: string = '';
  @Input() theme: string = '';
  @Input() borderNone: boolean = false;
  @Output() onClicked: EventEmitter<MouseEvent> = new EventEmitter();
  onClick(event: MouseEvent) {
    this.onClicked.emit(event);
  }

  getStyle() {
    return this.disabled == true
      ? `${this.size} ${this.theme}-disabled`
      : `${this.size} ${this.theme}-active`;
  }
}
