import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UiComponentsModule } from '@shared/ui-components';
import { ToolTipDirective } from 'libs/common/src/lib/directives/tool-tip.directive';
import { HomeComponent } from './dashboard/home.component';

@NgModule({
  declarations: [ToolTipDirective, HomeComponent],
  imports: [UiComponentsModule, RouterModule, CommonModule],
  providers: [],
  exports: [ToolTipDirective],
})
export class DashboardModule {}
