<div
  class="file-and-progress-bar"
  [ngClass]="{
    failed: progressInfo.progressStatus === PROGRESS_STATUS.FAILED,
    success: progressInfo.progressStatus === PROGRESS_STATUS.SUCCESS
  }"
>
  <div
    class="show-file-list"
    [ngClass]="{ block: progressInfo.showProgressBar }"
  >
    <div class="file-details">
      <!-- <img src="assets/36x36/{{ icon }}.svg" alt="" /> -->
      <div class="file">
        <img *ngIf="!hasImageAndVideo" src="assets/36x36/pdf.svg" alt="" />
        <ng-container *ngIf="hasImageAndVideo">
          <img
            src="assets/46x46/image.svg"
            *ngIf="progressInfo?.file?.type?.includes('image')"
            alt=""
          />
          <img
            src="assets/46x46/video.svg"
            *ngIf="progressInfo?.file?.type?.includes('video')"
            alt=""
          />
        </ng-container>
      </div>
      <div class="file-name-size">
        <div
          class="file-info"
          *ngIf="
            progressInfo.progressStatus !== PROGRESS_STATUS.FAILED;
            else fileUploadFailed
          "
        >
          <p
            class="name"
            *ngIf="progressInfo.progressValue !== 100; else fileName"
          >
            {{ progressInfo.file.name }}
          </p>
          <p class="size">{{ progressInfo.file.size | fileUnit }}</p>
        </div>
        <div
          *ngIf="progressInfo.showProgressBar && progressInfo"
          class="progress-section"
        >
          <div class="progress-bar">
            <div
              class="progress"
              [ngStyle]="{ width: progressInfo.progressValue + '%' }"
            ></div>
          </div>
          <span class="counter">{{ progressInfo.progressValue }}%</span>
        </div>
        <ng-template #fileName>
          <p class="name">
            {{ progressInfo.file.name }} -
            <span class="completed"> Completed </span>
          </p>
        </ng-template>
        <ng-template #fileUploadFailed>
          <p class="name">
            {{ progressInfo.file.name }} -
            <span class="failed"> Upload failed</span>
          </p>
          <p class="size">{{ progressInfo.file.size | fileUnit }}</p>
        </ng-template>
      </div>
    </div>
  </div>
  <div
    class="close"
    *ngIf="
      progressInfo?.showRemoveFileBtn === null ||
      progressInfo?.showRemoveFileBtn
    "
  >
    <img
      src="assets/20x20/bin.svg"
      alt=""
      (click)="removeFile(progressInfo.id)"
    />
  </div>
</div>
