import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HISTORY_TYPES } from '@common/interfaces';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-asset-history',
  templateUrl: './asset-history.component.html',
  styleUrls: ['./asset-history.component.scss'],
})
export class AssetHistoryComponent implements OnInit {
  @Input() assetId!: string;
  historyType: any =
    sessionStorage.getItem('historyType') || HISTORY_TYPES.LOANEE_HISTORY;
  @Input() historyUpdated!: string;
  ASSET_HISTORY_TYPE!: any;
  historyData!: any;
  stepsData = [];
  isHistoryLoaded: boolean = false;

  constructor(
    private commonHttpService: CommonHttpService,
    private router: Router,
    private toasterService: ToastrService
  ) {
    if (!sessionStorage.getItem('historyType')) {
      this.historyType = HISTORY_TYPES.LOANEE_HISTORY;
    }
  }

  ngOnInit(): void {
    this.getHistoryTypes();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          !event.url.includes('employee-details') &&
          !event.url.includes('asset-details')
        ) {
          sessionStorage.removeItem('historyType');
          this.historyType = HISTORY_TYPES.LOANEE_HISTORY;
        }
      }
    });
    this.getAssetHistory(this.assetId, this.historyType);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['historyUpdated']) {
      this.getAssetHistory(this.assetId, this.historyType);
    }
  }

  getHistoryTypes() {
    this.commonHttpService.getHistoryTypes().subscribe({
      next: (response) => {
        const historyObj = Object(response).data;
        this.ASSET_HISTORY_TYPE = Object.values(historyObj).filter(
          (historyType: any) => {
            if (historyType !== HISTORY_TYPES.ALL_HISTORY) {
              return historyType;
            }
          }
        );
      },
      error: (error: HttpErrorResponse) => {
        this.toasterService.error(
          'Could not able to load asset history types.',
          'Failed!',
          { toastClass: 'toaster-wrapper' }
        );
      }
    });
  }

  getAssetHistory(assetId: string, historyType: string) {
    this.commonHttpService.getAssetHistory(assetId, historyType).subscribe({
      next: (response: any) => {
        this.historyData = Object(response).data;
        this.isHistoryLoaded = true;
      },
      error: (error: HttpErrorResponse) => {
        this.isHistoryLoaded = false;
        this.toasterService.error(
          'Could not able to load asset history.',
          'Failed!',
          { toastClass: 'toaster-wrapper' }
        );
      },
    });
  }
  getValue(type: string) {
    this.historyType = type;
    sessionStorage.setItem('historyType', this.historyType);
    this.getAssetHistory(this.assetId, this.historyType);
  }
}
