export const VARIABLES = {
  title: '--select--',
};

export const GSC_API_PATH = 'https://accounts.google.com/gsi/client';

// file uploading

export const FILE_UPLOAD_VARIABLES = {
  nameOfFile: 'Drag and drop',
};
// Months
export const months = [
  'Jan', 'Feb', 'Mar', 'Apr',
  'May', 'June', 'July', 'Aug',
  'Sept', 'Oct', 'Nov', 'Dec'
];

// Methods

export const REQUEST = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export const TABLE_VARIABLES = {
  AssetTag: 'Asset Tag',
  ticketDate: 'Ticket Date',
  assetName: 'Asset Name',
  status: 'Status',
  ticketRaisedBy: 'Ticket raised by',
};

export const BOOLEAN = {
  true: true,
  false: false,
};

export const ASSET_TYPES = [
  {
    assetId: '141d6a2a-e61d-49a2-a8de-25806d2b2bc9',
    assetName: 'Monitor',
    assetCode: 'M',
  },
  {
    assetId: '6d88b333-4b4d-4d86-b5fe-b6f8f1ec131d',
    assetName: 'Laptop',
    assetCode: 'L',
  },
  {
    assetId: '314b8915-f098-4d87-b7ce-3d951b059002',
    assetName: 'Keyboard',
    assetCode: 'KB',
  },
  {
    assetId: 'f53b05b9-1f90-4625-84d9-75b11a567b47',
    assetName: 'Mouse',
    assetCode: 'MS',
  },
  {
    assetId: 'def3219a-237e-44ca-acde-5ad8014e0e6d',
    assetName: 'Charger',
    assetCode: 'C',
  },
  {
    assetId: '9510fbca-2848-490d-b49c-6dce938d8ebd',
    assetName: 'C Type Connector',
    assetCode: 'CTC',
  },
  {
    assetId: '8a976df6-e72a-4dfe-a27a-67fb82f8034a',
    assetName: 'Mobile Phone',
    assetCode: 'MB',
  },
  {
    assetId: '59ee6611-1558-4593-9bd2-d7dad584c10f',
    assetName: 'Headphone',
    assetCode: 'H',
  },
];

export const WARRANTY_TENURE = [
  '90 Days',
  '180 Days',
  '270 Days',
  '360 Days',
  '450 Days',
  '540 Days',
];

export const SPECIFICATIONS = {
  Laptop: [
    {
      fieldName: 'Model Name',
      fieldType: 'text',
    },
    {
      fieldName: 'Processor',
      fieldType: 'dropdown',
    },
    {
      fieldName: 'Operating System',
      fieldType: 'dropdown',
    },
  ],
  Headphone: [
    {
      fieldName: 'Model Name',
      fieldType: 'text',
    },
    {
      fieldName: 'Serial Number',
      fieldType: 'number',
    },
  ],
  Keyboard: [
    {
      fieldName: 'Model Name',
      fieldType: 'text',
    },
  ],
  'Mobile Phone': [
    {
      fieldName: 'Model Name',
      fieldType: 'text',
    },
    {
      fieldName: 'Serial Number',
      fieldType: 'number',
    },
  ],
  Monitor: [
    {
      fieldName: 'Model Name',
      fieldType: 'text',
    },
    {
      fieldName: 'Screem Size',
      fieldType: 'number',
    },
  ],
  Mouse: [
    {
      fieldName: 'Model Name',
      fieldType: 'text',
    },
  ],
  'C Type Connector': [
      {
        fieldName: 'Model Name',
        fieldType: 'text',
      },
      {
        fieldName: 'Serial Number',
        fieldType: 'number',
      },
  ]
};

export const FILTER = {
  todo: 'to-do',
  onhold: 'on-hold',
  closed: 'closed',
  all: 'all',
  ongoing: 'ongoing',
  pending: 'pending',
  assigned: 'assigned',
};

export const SIDENAV = {
  dashboard: 'dashboard',
  inventory: 'inventory',
  tickets: 'tickets',
  bulkUpload: 'bulk upload',
  info: 'info',
};

export const UPLOAD_LIST_TYPES = [
  { id: 'asset_list', title: 'Asset list' },
  { id: 'asset_allocation_list', title: 'Asset allocation list' },
  { id: 'asset_out_for_repair', title: 'Asset out for repair' },
  { id: 'asset_list_update', title: 'Asset list - Update' },
  { id: 'mark_asset_ewaste', title: 'Asset list mark as e-waste' },
];

export const DOWNLOAD_LIST_OPTIONS = [
  { id: 'user_list', title: 'User list', fileType: 'database_file' },
  { id: 'asset_list', title: 'Asset list', fileType: 'all' },
  {
    id: 'asset_allocation_list',
    title: 'Asset allocation list',
    fileType: 'all',
  },
  {
    id: 'asset_out_for_repair',
    title: 'Asset out for repair list',
    fileType: 'template_file',
  },
  {
    id: 'asset_update_list',
    title: 'Asset update list',
    fileType: 'template_file',
  },
  {
    id: 'mark_asset_e_waste',
    title: 'Asset list mark as e-waste',
    fileType: 'template_file',
  },
];

export const DOWNLOAD_LIST_TYPES = [
  { id: 'template_file', title: 'Template File' },
  { id: 'database_file', title: 'Database File' },
];


export const G_AUTH_TOKEN =
  '874163583758-u3f0rdgpdlqsirp6ajsvm61bpc2d7bh7.apps.googleusercontent.com';

export enum TICKET_STATUS {
  PENDING = 'TO_DO',
  ASSIGNED = 'ONGOING',
  CLOSED = 'CLOSED',
  EXTERNAL = 'EXTERNAL',
  ALL = 'ALL',
  ON_HOLD = 'ON_HOLD'

}

export const ASSET_STATUS = {
  AVAILABLE: 'AVAILABLE',
  DAMAGED: 'DAMAGED',
  OUT_FOR_REPAIR: 'OUT_FOR_REPAIR',
  IN_HOUSE_REPAIR: 'IN_HOUSE_REPAIR',
  ALLOCATED: 'ALLOCATED',
  DISABLED: 'DISABLED',
  NOT_IN_USE: 'NOT_IN_USE',
  MARK_AS_EWASTE: 'MARKED_AS_E_WASTE',
  ALL: 'ALL',
  REPAIR: 'REPAIR',
};

export const TRANSACTION_STATUS = {
  CHECKIN: "CheckIn",
  CHECKIN_OUT_FOR_REPAIR: "CheckIn Out For Repair",
  CHECKOUT_OUT_FOR_REPAIR: "CheckOut Out For Repair",
  CHECKOUT: "CheckOut",
  CHECKIN_IN_HOUSE_REPAIR: "CheckIn In House Repair",
  CHECKOUT_IN_HOUSE_REPAIR: "CheckOut In House Repair",
  CHECKOUT_NOT_IN_USE: "CheckOut Not In Use",
  CHECKIN_NOT_IN_USE: "CheckIn Not In Use",
  CHECKOUT_E_WASTE: "CheckOut E Waste",
};

export const HISTORY_TRANSACTION_STATUS = {
  CHECKOUT_OUT_FOR_REPAIR: "CHECKOUT_OUT_FOR_REPAIR",
  CHECKIN_OUT_FOR_REPAIR: "CHECKIN_OUT_FOR_REPAIR",
  CHECKOUT_USER: "CHECKOUT_USER",
  CHECKIN_USER: "CHECKIN_USER",
  CHECKOUT_IN_HOUSE_REPAIR: "CHECKOUT_IN_HOUSE_REPAIR",
  CHECKIN_IN_HOUSE_REPAIR: "CHECKIN_IN_HOUSE_REPAIR",
  TRANSACTION_REASON: "TRANSACTION_REASON",
  CHECKOUT_NOT_IN_USE: "CHECKOUT_NOT_IN_USE",
  CHECKIN_NOT_IN_USE: "CHECKIN_NOT_IN_USE",
  CHECKOUT_E_WASTE: "CHECKOUT_E_WASTE",
};
export const HISTORY_HEADER_TITLES = {
  LOANEE: 'Loanee',
  OUT_FOR_REPAIR: 'Out for Repair',
  IN_HOUSE_REPAIR: 'In-house Repair'
}
export const HISTORY_TYPES = {
  LOANEE_HISTORY: "Loanee History",
  REPAIR_HISTORY: "Repair History",
  ALL_HISTORY: "All History"
}
export const TICKETS_MENU = [
  {
    title: 'To-Do',
    count: 0,
    route: '/home/admin-ticket/coditas',
    status: TICKET_STATUS.PENDING,
  },
  {
    title: 'Ongoing',
    count: 0,
    route: '/home/admin-ticket/coditas',
    status: TICKET_STATUS.ASSIGNED,
  },
  {
    title: 'On-Hold',
    count: 0,
    route: '/home/admin-ticket/coditas',
    status: TICKET_STATUS.ON_HOLD,
  },
  {
    title: 'Closed',
    count: 0,
    route: '/home/admin-ticket/coditas',
    status: TICKET_STATUS.CLOSED,
  },
  {
    title: 'External',
    count: 0,
    route: '/home/admin-ticket/coditas',
    status: TICKET_STATUS.EXTERNAL,
  },
  {
    title: 'All',
    count: 0,
    route: '/home/admin-ticket/coditas',
    status: TICKET_STATUS.ALL,
  },
];

export const ASSETS_MENU = [
  {
    title: 'Assigned',
    count: '1',
    route: '../inventory',
    status: ASSET_STATUS.ALLOCATED,
  },
  {
    title: 'Available',
    count: '2',
    route: '../inventory',
    status: ASSET_STATUS.AVAILABLE,
  },
  {
    title: 'Out for Repair',
    count: '3',
    route: '../inventory',
    status: ASSET_STATUS.OUT_FOR_REPAIR,
  },
  {
    title: 'In House Repair',
    count: '4',
    route: '../inventory',
    status: ASSET_STATUS.IN_HOUSE_REPAIR,
  },
  {
    title: 'Disabled',
    count: '5',
    route: '../inventory',
    status: ASSET_STATUS.DISABLED,
  },
  {
    title: 'All',
    count: '6',
    route: '../inventory',
    status: ASSET_STATUS.ALL,
  },
];

export const TICKET_SEARCH_OPTIONS = [
  { title: 'Ticket No.', value: 'TICKET_NUMBER' },
  { title: 'Ticket Date', value: 'TICKET_DATE' },
  { title: 'Asset Name', value: 'ASSET_NAME' },
  { title: 'Raised By', value: 'TICKET_RAISED_BY' },
  { title: 'Assigned to', value: 'ASSIGNED_TO' },
];

export const ASSET_SEARCH_OPTIONS = [
  { title: 'Asset Tag', value: 'ASSET_TAG' },
  { title: 'Asset Name', value: 'ASSET_NAME' },
  { title: 'Loanee', value: 'LOANEE' },
];

export const ASSET_SORT_COLUMNS = {
  ASSET_TAG: 'ASSET_TAG',
  ASSET_LOCATION: 'ASSET_LOCATION',
  ASSET_NAME: 'ASSET_NAME',
  LOAN_STATUS: 'LOAN_STATUS',
  LOANEE: 'LOANEE',
  ASSIGNED_TO: 'ASSIGNED_TO',
  ONBOARDING_DATE: 'ONBOARDING_DATE',
};

export const TICKET_SORT_COLUMNS = {
  ASSET_NAME: 'ASSET_NAME',
  PRIORITY: 'PRIORITY',
  TICKET_NUMBER: 'TICKET_NUMBER',
  TICKET_DATE: 'TICKET_DATE',
  ASSET_TAG: 'ASSET_TAG',
  TICKET_STATUS: 'TICKET_STATUS',
  TICKET_RAISED_BY: 'TICKET_RAISED_BY',
  ASSIGNED_TO: 'ASSIGNED_TO',
};

export const PAGINATION_DATA = [
  { title: '5', value: '5' },
  { title: '10', value: '10' },
  { title: '15', value: '15' },
];

export const TICKET_TYPES = {
  ASSET_ALLOCATION: 'ALLOCATION',
  REPAIR_OR_REPLACEMENT: 'REPAIR/REPLACEMENT',
  ACCESS_INSTALLATION : 'ACCESS/INSTALLATION'
};

export const ASSET_LOCATIONS = [
  'Nyati',
  'Bhairav Estate',
  'Gaia Apex',
  'Remote',
];

export enum TICKET_STATES {
  SUCCESS = 'SUCCESS',
  DEFAULT = 'DEFAULT',
  ERROR = 'ERROR',
}

export enum APPROVAL_STATUS {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
}

export enum CUSTODY_VERIFICATION_STATUS {
  VERIFIED = 'VERIFIED',
  NOT_VERIFIED = 'NOT_VERIFIED',
  PENDING = 'PENDING',
}

export const ASSET_STATUS_OPTIONS = [
  {
    assetStatus: 'Mark as Disabled',
    assetValue: 'DISABLED',
  },
  {
    assetStatus: 'Discard as e-waste',
    assetValue: 'DISABLED',
  },
];

export const TICKET_PRIORITIES = {
  HIGH: 'High',
  MEDIUM: 'Medium',
  LOW: 'Low',
};

export const ASSET_INFO_HEADINGDS = {
  TICKET_STATUS: 'Ticket Status',
  ASSET_STATUS: 'Asset Status',
};

export const PROGRESS_STATUS = {
  FAILED: 'fail',
  SUCCESS: 'success',
  NONE: 'none',
};

export const EKO_TICKET_STATUS = [
  {
    title: 'To-Do',
    count: 0,
    route: '/home/admin-ticket/coditas',
    status: TICKET_STATUS.PENDING,
  },
  {
    title: 'Ongoing',
    count: 0,
    route: '/home/admin-ticket/coditas',
    status: TICKET_STATUS.ASSIGNED,
  },
  {
    title: 'Closed',
    count: 0,
    route: '/home/admin-ticket/coditas',
    status: TICKET_STATUS.CLOSED,
  },
  {
    title: 'All',
    count: 0,
    route: '/home/admin-ticket/coditas',
    status: TICKET_STATUS.ALL,
  },
]

export const EKO_ASSET_STATUS = [
  {
    title: 'Available',
    count: '2',
    route: '../inventory',
    status: ASSET_STATUS.AVAILABLE,
  },
  {
    title: 'Assigned',
    count: '1',
    route: '../inventory',
    status: ASSET_STATUS.ALLOCATED,
  },

  {
    title: 'Repair',
    count: '3',
    route: '../inventory',
    status: ASSET_STATUS.REPAIR,
  },
  {
    title: 'Disabled',
    count: '4',
    route: '../inventory',
    status: ASSET_STATUS.DISABLED,
  },
  {
    title: 'All',
    count: '5',
    route: '../inventory',
    status: ASSET_STATUS.ALL,
  },
];

export const PROVIDER = {
  CODITAS: 'CODITAS',
  EKO_CLIENT: 'EKO CLIENT'
}

export const MODALS = {
  ASSIGNEE_CONFIRMATION_POPUP: 'assignee-confirmation-popup',
  ASSIGNEE_CONFIRMATION_POPUP_FIRST_TIME: 'assignee-confirmation-popup-for-first-time',
  CHECKOUT_CONFIRMATION_POPUP: 'checkout-confirmation-popup',
  DUPLICATE_ASSET_POPUP:'duplicate-asset-popup',
  CHECKOUT_SUCCESS_CONFIRMATION_POPUP: 'checkout-success-confirmation-popup',
  CHECKIN_CONFIRMATION_POPUP: 'checkin-confirmation-popup',
  CHECKIN_SUCCESS_CONFIRMATION_POPUP: 'checkin-success-confirmation-popup',
  RESEND_APPROVAL_CONFIRMATION: 'resend-approval-confirmation',
  REQUEST_APPROVAL_CONFIRMATION: 'request-approval-confirmation',
  RAISE_TICKET_MODAL: 'raise-ticket-modal',
  REQUEST_ASSET_MODAL: 'request-asset-modal',
  ACCESS_INSTALLATION_MODAL: 'access-installation-modal',
  OUT_FOR_REPAIR_MODAL: 'out-for-repair-modal',
  CHECKOUT_FOR_REPAIR_SUCCESSFULL: 'checkout-for-repair-successfull',
}
export const STORE_RUNTIME_CHECKS = {
  strictStateImmutability: true,
  strictActionImmutability: true,
  strictStateSerializability: true,
  strictActionSerializability: true,
};
