import { Component, OnInit } from '@angular/core';
import { IErrorConfig, ROUTES } from '@common/interfaces';


@Component({
  selector: 'app-email-not-registered',
  templateUrl: './email-not-registered.component.html',
  styleUrls: ['./email-not-registered.component.scss']
})
export class EmailNotRegisteredComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  errorObject: IErrorConfig = {
    errorCode: '404 Error!',
    errorMessage: 'Email Not Registered',
    errorDescription: 'The email you are trying to enter is not authorized',
    actionName: 'Go To Login',
    navigateTo: ROUTES.SIGN_IN
  }
}
