import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ILogin, ILoginResponse } from '@common/interfaces';
import { base_url } from '../../../../common/src/lib/urls/urls';
@Injectable({
  providedIn: 'root',
})
export class SignInService {
  constructor(private http: HttpClient) { }
  getToken() {
    return localStorage.getItem('token');
  }

  getUserId() {
    return localStorage.getItem('userId');
  }

  apiSignIn(data: ILogin) {
    return this.http.post<ILoginResponse>(`${base_url}/user/login`, data);
  }

  signInWithGoogle(idToken: string) {
    const data = {
      token: `${idToken}`,
    };
    return this.http.post(`${base_url}/auth/google`, data);
  }
}
