import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit, ControlValueAccessor {
  @Output() emitFile = new EventEmitter<File[]>();
  @Input() multiple = false;
  @Input() progressInfos!: any[];
  @Input() maxSize: string = '2MB';
  _files: File[] | null = null;

  constructor(private toasterService: ToastrService) { }
  ngOnInit(): void {
  }
  @ViewChild('fileField') fileField!: ElementRef;
  onChange: Function = (event: File[]) => { };
  onTouched: Function = () => { };
  writeValue(obj: any): void {
    if (this._files) {
      this.fileField.nativeElement.value = null;
    }
    this._files = null;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void { }
  @HostListener('change', ['$event.target.files']) emitFiles(event: File[]) {
    let files = event;
    this.onChange(files);
    this._files = [...files];
    this.emitFile.emit(this._files);
  }
}
