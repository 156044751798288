<div class="table-header">
  <div class="title-section">
    <p class="ticket-list-text">
      {{ loanStatusFilter ? "Asset List " : "Ticket List" }}
    </p>
    <div *ngIf="showFilterState() && hideFilters">
      <div class="filter-container">
        <div class="sort-by-text">Sort by:</div>
        <div class="filter-btns">
          <app-table-filter
            [filterOptions]="filterStateOptions"
            (activeFilter)="onSelectFilter($event)"
            [activeMenu]="filterStateMenu"
            [showFilter]="true"
          ></app-table-filter>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="tableConfig">
    <ng-container *ngIf="searchOptions?.length">
      <div class="search-wrapper">
        <app-search-section
          [searchOptions]="searchOptions"
          (searchData)="search($event)"
        ></app-search-section>
      </div>
    </ng-container>

    <div class="filter-section">
      <ng-container *ngIf="!hideFilters">
        <p class="filter-text">Filter By:</p>
        <app-table-filter
          [filterOptions]="filterOptions"
          [activeMenu]="pageCount"
          (activeFilter)="sendFilter($event)"
        ></app-table-filter>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="tableConfig && !displayTicketList">
  <div class="table-container" *ngIf="tableData?.length; else noData">
    <table class="table">
      <thead>
        <tr class="table-head-row">
          <th *ngFor="let col of tableConfig" class="table-head">
            <span class="sort-thead" (click)="col?.sortAction(col.title)">
              <span>{{ col.title | titlecase }}</span>
              <img
                *ngIf="col?.icon !== 'none'"
                src="assets/12x12/{{ col?.icon || 'sort-asc' }}.svg"
                alt="sort-icon"
              />
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="tableData?.length">
          <tr
            *ngFor="let data of tableData; let idx = index"
            class="table-body-row"
            let
            i="index"
          >
            <td
              *ngFor="let col of tableConfig"
              class="table-data"
              [ngClass]="{ odd: idx % 2 === 0 }"
            >
              <!-- For Multi Keys -->
              <ng-container *ngIf="col.hasMultiKeys; else hasSingleKey">
                <!-- For ticket no. -->
                <ng-container *ngIf="col.config.isTicket">
                  <ng-container
                    *ngFor="let keyData of col.config.keys; let i = index"
                  >
                    <div
                      *ngIf="i === 0 && displayColorCode"
                      class="ticket-priority"
                      [ngClass]="
                        getPriorityClass(data?.ticketPriorities?.ticketPriority)
                      "
                    ></div>
                    <ng-container
                      *ngIf="keyData.key === 'ticketNumber'; else desc"
                    >
                      <a
                        [routerLink]="
                          '/' + ROUTES.TICKET_DETAILS + data.ticket?.ticketId
                        "
                        class="display-block view-more"
                      >
                        {{
                          "#" +
                            (data.ticket["isExternalTicket"] == "true"
                              ? "Ext - "
                              : "") +
                            data.ticket[keyData.key]
                        }}
                      </a>
                    </ng-container>
                    <ng-template #desc>
                      <span class="ticket-no-subtitle">
                        {{ getSubtitle(data.ticket[keyData.key]) | titlecase }}
                      </span>
                    </ng-template>
                  </ng-container>
                </ng-container>

                <!-- For Asset Tag -->
                <ng-container *ngIf="col.config.isAssetTag">
                  <ng-container *ngFor="let keyData of col.config.keys">
                    <span
                      *ngIf="keyData.key === 'name'"
                      class="display-block model-name"
                    >
                      {{
                        data.asset.name && data.asset.name.length > 15
                          ? (data.asset.name | slice : 0 : 12) + "..."
                          : (data.asset.name || "-" | titlecase)
                      }}
                    </span>
                    <a
                      *ngIf="keyData.key === 'assetCode' && (isAdmin || isSemiAdmin || isEkoAdmin)"
                      class="display-block view-more"
                      routerLink="#"
                      (click)="keyData.handleAction(data.asset.asset_id); $event.preventDefault();"
                    >
                      {{ data.asset?.assetCode || "-" }}
                    </a>
                    <span *ngIf="keyData.key === 'assetCode' && !isAdmin && !isSemiAdmin && !isEkoAdmin">
                      {{ data.asset?.assetCode || "-" }}
                    </span>
                  </ng-container>
                </ng-container>

                <!-- For User Info Col -->
                <ng-container *ngIf="col.config.isUserInfo">
                  <span *ngFor="let keyData of col.config.keys">
                    <ng-container *ngIf="keyData.key !== 'email'; else email">
                      <span class="color-black">
                        {{ data.ticket.user?.[keyData.key] | titlecase }}
                      </span>
                    </ng-container>
                    <ng-template #email>
                      <span
                        class="display-block"
                        [title]="data.ticket.user?.[keyData.key]"
                      >
                        {{
                        data.ticket.user?.[keyData.key].length > 15
                        ? (data.ticket.user?.[keyData.key] | slice: 0:15) + '...'
                        : data.ticket.user?.[keyData.key] || '-'
                        }}
                      </span>
                    </ng-template>
                  </span>
                </ng-container>

                <!-- For Assignee Info Col -->
                <ng-container *ngIf="col.config.isAssigneeInfo">
                  <span *ngFor="let keyData of col.config.keys">
                    <ng-container *ngIf="keyData.key !== 'email'; else email">
                      <span class="color-black">
                        {{data.ticket?.assignee?.[keyData.key] || '' | titlecase }}
                      </span>
                    </ng-container>
                    <ng-template #email>
                      <span
                        class="display-block model-name"
                        [title]="data.ticket?.assignee?.[keyData.key]"
                      >
                        {{
                        data.ticket?.assignee?.[keyData.key].length > 15
                        ? (data.ticket?.assignee?.[keyData.key] | slice: 0:15) + '...'
                        : data.ticket?.assignee?.[keyData.key] || '-'
                        }}
                      </span>
                    </ng-template>
                  </span>
                </ng-container>
              </ng-container>

              <!-- For Single Key -->
              <ng-template #hasSingleKey>
                <ng-container *ngIf="col.config.isTicket">
                  <span *ngIf="col.config.keys.isDate; else status">{{
                    data.ticket.createdAt | date : "dd/MM/yyyy"
                  }}</span>
                  <ng-template #status>
                    <span
                      class="badge"
                      [ngClass]="addStatusClass(data.ticket.status || '')"
                    >
                      <span class="badge-dot"></span>
                      <span>
                        {{
                          data.ticket.status === "TO_DO"
                            ? "To-Do"
                            : (data.ticket.status | titlecase)
                        }}
                      </span>
                    </span>
                  </ng-template>
                </ng-container>
                <ng-container
                  *ngIf="col.config.isAsset && !col.config.isLoanStatus"
                >
                  <span
                    [title]="data[col.config.keys.from]?.[col.config.keys.key]"
                    >{{
                    data[col.config.keys.from]?.[col.config.keys.key].length > 12 ?
                    (data[col.config.keys.from]?.[col.config.keys.key] | slice: 0:12)+'...':
                    data[col.config.keys.from]?.[col.config.keys.key] || '-' | titlecase
                    }}</span
                  >
                </ng-container>

                <ng-container
                  *ngIf="col.config.isAsset && col.config.isLoanStatus"
                >
                  <span *ngIf="col.config.keys.key == 'purchaseDate'">
                    <span>{{ data.asset.purchaseDate }}</span>
                  </span>
                  <span *ngIf="col.config.keys.key == 'createdAt'">
                    <span>{{
                      data.asset.createdAt | date : "dd/MM/yyyy"
                    }}</span>
                  </span>
                  <span *ngIf="col.config.keys.key == 'location'">
                    <span>{{ data.asset.location | titlecase }}</span>
                  </span>
                  <span
                    *ngIf="data[col.config.keys.from]?.[col.config.keys.key]=='ALLOCATED'"
                    class="badge badge-assigned"
                  >
                    <span class="badge-dot"> </span>
                    <span>Assigned</span>
                  </span>
                  <span
                    *ngIf="data[col.config.keys.from]?.[col.config.keys.key]=='AVAILABLE'"
                    class="badge badge-available"
                  >
                    <span class="badge-dot"> </span>
                    <span>Available</span>
                  </span>
                  <span
                    *ngIf="data[col.config.keys.from]?.[col.config.keys.key]=='OUT_FOR_REPAIR'"
                    class="badge badge-out-for-repair"
                  >
                    <span class="badge-dot"> </span>
                    <span>Out for Repair</span>
                  </span>
                  <span
                    *ngIf="data[col.config.keys.from]?.[col.config.keys.key]=='IN_HOUSE_REPAIR'"
                    class="badge badge-out-for-repair"
                  >
                    <span class="badge-dot"> </span>
                    <span>In-house Repair</span>
                  </span>
                  <span
                    *ngIf="data[col.config.keys.from]?.[col.config.keys.key]=='NOT_IN_USE'"
                    class="badge disabled badge-disabled"
                  >
                    <span class="badge-dot"> </span>
                    <span>Not in use</span>
                  </span>
                  <span
                    *ngIf="data[col.config.keys.from]?.[col.config.keys.key]=='MARKED_AS_E_WASTE'"
                    class="badge disabled badge-disabled"
                  >
                    <span class="badge-dot"> </span>
                    <span>E-waste</span>
                  </span>
                  <span></span>
                </ng-container>
                <ng-container *ngIf="col.config.isRoot">
                  <ng-container *ngFor="let keyData of col.config.keys">
                    <span class="badge badge-todo" *ngIf="keyData.isStatus">
                      <span class="badge-dot"></span>
                      <span> {{ data[keyData.key] | titlecase }} </span>
                    </span>
                  </ng-container>
                </ng-container>
              </ng-template>

              <!-- For Action -->
              <ng-container *ngIf="col.config.isAction">
                <ng-container *ngFor="let action of col.config.actions">
                  <a
                    [routerLink]="
                      '/' + ROUTES.ASSET_DETAILS + data.asset.asset_id
                    "
                    *ngIf="col.config.isInventory"
                  >
                    {{ action.title }}
                  </a>
                </ng-container>
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <ng-template #noData>
    <div class="no-data">
      <app-empty-placeholder
        imageSrc="{{ placeholderIcon }}"
        [title]="placeholderTitle"
        [subtitle]="placeholderSubtitle"
      ></app-empty-placeholder>
    </div>
  </ng-template>
  <!-- pagination Section -->
  <ng-container *ngIf="count > 5">
    <div class="row">
      <div class="filter-wrapper">
        <p class="filter-text">
          View {{ loanStatusFilter ? "Asset" : "Ticket" }} Rows
          <!-- View Rows -->
        </p>
        <app-generic-drop-down
          [label]="pageCount"
          (index)="pageSizeEmitter($event)"
          [options]="pageSizeOptions"
          [title]="pageCount || '5'"
          class="drop-down-container"
          [showDropContainerTop]="true"
          [shouldEmitIndex]="true"
        ></app-generic-drop-down>
      </div>
      <div>
        <app-pagination
          (responseData)="paginationEmitter($event)"
          [recordsPerPage]="+pageCount || 5"
          [count]="count"
        >
        </app-pagination>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="displayTicketList">
  <app-table-list
    class="width-100"
    [fieldArray]="fieldArray"
    [dataSource]="tableDetails"
    (clickEvent)="onNavigationById($event)"
    (clickOnSort)="onSortAction($event)"
  ></app-table-list>

  <ng-container *ngIf="count > 5" && !displayTicketList>
    <div class="row">
      <div class="filter-wrapper">
        <p class="filter-text">
          View {{ loanStatusFilter ? "Asset" : "Ticket" }} Rows
          <!-- View Rows -->
        </p>
        <app-generic-drop-down
          [label]="pageCount"
          (index)="pageSizeEmitter($event)"
          [options]="pageSizeOptions"
          [title]="pageCount || '5'"
          class="drop-down-container"
          [showDropContainerTop]="true"
          [shouldEmitIndex]="true"
        ></app-generic-drop-down>
      </div>
      <div>
        <app-pagination
          (responseData)="paginationEmitter($event)"
          [recordsPerPage]="+pageCount || 5"
          [count]="count"
        >
        </app-pagination>
      </div>
    </div>
  </ng-container>
</ng-container>
