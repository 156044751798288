import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UiComponentsModule } from '@shared/ui-components';
import { DashboardModule } from '../dashboard.module';
import { EmployeeDashboardComponent } from './employee-dashboard/employee-dashboard.component';
import { WelcomeComponent } from './welcome/welcome.component';

@NgModule({
  declarations: [EmployeeDashboardComponent, WelcomeComponent],
  imports: [UiComponentsModule, DashboardModule, RouterModule, CommonModule],
  providers: [],
  exports: [EmployeeDashboardComponent, WelcomeComponent],
})
export class EmployeeModule {}
