import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserGuard } from '@common/interfaces';
import { AddAssetModalComponent } from 'libs/shared/ui-components/src/lib/add-asset-modal/add-asset-modal.component';
import { PrivacyPolicyComponent } from 'libs/shared/ui-components/src/lib/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from 'libs/shared/ui-components/src/lib/terms-and-conditions/terms-and-conditions.component';
import { ViewTicketModalComponent } from 'libs/shared/ui-components/src/lib/view-ticket-modal/view-ticket-modal.component';
import { AuthService } from '../auth/auth.service';
import { AdminBulkUploadComponent } from './admin/admin-bulk-upload/admin-bulk-upload.component';
import { AdminInventoryComponent } from './admin/admin-inventory/admin-inventory.component';
import { AdminTicketPersonalComponent } from './admin/admin-ticket-personal/admin-ticket-personal.component';
import { AdminTicketComponent } from './admin/admin-ticket/admin-ticket.component';
import { AssetDetailsComponent } from './admin/asset-details/asset-details.component';
import { AssetTeamDashboardPersonalComponent } from './admin/asset-team-dashboard-personal/asset-team-dashboard-personal.component';
import { AssetTeamDashboardComponent } from './admin/asset-team-dashboard/asset-team-dashboard.component';
import { EmployeeDetailsComponent } from './admin/employee-details/employee-details.component';
import { HomeComponent } from './dashboard/home.component';
import { EmployeeDashboardComponent } from './employee/employee-dashboard/employee-dashboard.component';
import { WelcomeComponent } from './employee/welcome/welcome.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    children: [
      //Employee Routes
      {
        path: 'welcome',
        component: WelcomeComponent,
        canActivate: [AuthService, UserGuard],
        data: { role: 'USER' },
      },
      {
        path: 'employee-dashboard',
        component: EmployeeDashboardComponent,
        canActivate: [AuthService, UserGuard],
        data: { role: 'USER' },
        children: [
          {
            path: 'ticket/:id',
            component: ViewTicketModalComponent,
            canActivate: [AuthService],
          },
        ],
      },
      {
        path: 'profile-details/:id',
        component: EmployeeDetailsComponent,
        canActivate: [AuthService],
        data: { role: 'USER' },
      },

      // Admin Routes
      {
        path: 'asset-team-dashboard',
        canActivate: [AuthService],
        data: { role: 'ADMIN' },
        children: [
          {
            path: '',
            redirectTo: 'coditas',
            pathMatch: 'full',
          },
          { path: 'coditas', component: AssetTeamDashboardComponent, canActivate: [AuthService] },
          { path: 'personal', component: AssetTeamDashboardPersonalComponent, canActivate: [AuthService], },
        ],
      },
      {
        path: 'admin-ticket',
        canActivate: [AuthService],
        data: { role: 'ADMIN' },
        children: [
          {
            path: '',
            redirectTo: 'coditas',
            pathMatch: 'full',
          },
          { path: 'coditas', component: AdminTicketComponent, canActivate: [AuthService], },
          { path: 'personal', component: AdminTicketPersonalComponent, canActivate: [AuthService], },
          {
            path: 'ticket/:id',
            component: ViewTicketModalComponent,
            canActivate: [AuthService],
          },
        ],
      },
      {
        path: 'inventory',
        component: AdminInventoryComponent,
        canActivate: [AuthService],
        data: { role: 'ADMIN' },
        children: [
          {
            path: 'asset',
            component: AddAssetModalComponent,
          },
          {
            path: 'asset/:id',
            component: AddAssetModalComponent,
          },
          {
            path: 'asset-details/:id',
            component: AssetDetailsComponent,
          },
        ],
      },
      {
        path: 'bulk-upload',
        component: AdminBulkUploadComponent,
        canActivate: [AuthService],
        data: { role: 'ADMIN' },
      },
      {
        path: 'employee-details/:id',
        component: EmployeeDetailsComponent,
        canActivate: [AuthService],
        data: { role: 'ADMIN' },
      },
      {
        path: 'bulk-upload', component: AdminBulkUploadComponent, canActivate: [AuthService],
      },

      //Common Routes
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
    ],
  },
  { path: '**', redirectTo: '404-not-found' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule { }
