<div class="user-profile">
  <div class="loader-container" *ngIf="loaderView">
    <img src="assets/32x32/coditas.gif" class="loader-logo" loading="lazy" />
  </div>
  <div class="heading-and-edit">
    <p class="profile">Profile Details</p>
  </div>
  <div class="details" [@basicAnimation]>
    <div class="frame1">
      <p class="label">
        <span> Full Name </span><span>:</span
        ><span class="user-info">
          {{ user?.firstName || "-" }} {{ user?.lastName || "-" }}</span
        >
      </p>
      <p class="label">
        <span> Employee ID </span><span>:</span
        ><span class="user-info">{{ user?.employeeId || "-" }}</span>
      </p>
      <p class="label">
        <span> Designation </span><span>:</span
        ><span class="user-info"> {{ user?.designation || "-" }} </span>
      </p>
      <p class="label">
        <span> Email ID </span><span>:</span
        ><span class="user-info">{{ user?.email || "-" }}</span>
      </p>
    </div>

    <div class="frame2">
      <span class="label-name"> Contact No. </span>
      <span class="divider">:</span>
      <span class="user-info info">{{ user?.phoneNumber || "-" }}</span>
      <span class="label-name"> Reporting Manager</span>
      <span class="divider">:</span>
      <span class="user-info info">{{
        user?.reportingmanagername || "-"
      }}</span>
    </div>
  </div>
</div>
