<router-outlet #outlet="outlet"></router-outlet>
<ng-container
  *ngIf="!outlet.isActivated && !isAddAssetClicked && !editAssetClicked"
>
  <div
    class="asset-team-container"
    [ngClass]="{ changeBackground: isAddAssetClicked }"
  >
    <app-asset-info
      [assets]="assetMenu"
      [hasAddAsset]="true"
      [hasEditAsset]="editAssetClicked"
      [title]="'Asset Status'"
      (addAssetClicked)="addAssetIsClicked($event)"
      (buttonClick)="downloadAssetList()"
      [buttonText]="textOnButton"
      *stessaHasPermission="'GET_DASHBOARD_ASSET_COUNT'"
      [titleForClientDropDown]="titleForClientDropDown"
      (onClientSelect)="onClientChange($event)"
    ></app-asset-info>
    <div class="divider-container"><div class="divider"></div></div>
    <div
      class="dash-section-wrapper container-with-no-border"
      [ngClass]="{ loader: loaderView }"
      *stessaHasPermission="'INVENTORY__ASSET_LIST'"
    >
      <app-loader *ngIf="loaderView"></app-loader>
      <app-table
        [tableConfig]="tableConfig"
        [tableData]="AssetTeamtableDetails"
        [searchOptions]="ASSET_SEARCH_OPTIONS"
        (searchData)="search($event)"
        (paginatorEmitter)="getAllAssets($event)"
        [filterOptions]="PAGINATION_DATA"
        (pageSizeChange)="pageSizeChange($event)"
        [activeMenu]="pageSize"
        [count]="count"
        [displayTicketList]="false"
        [currentPageNumber]="currentPageNumber"
        class="width-100"
      ></app-table>
    </div>
  </div>
</ng-container>
