<div class="modal-header" *ngIf="uploadCounts?.length">
  <div class="modal-header-with-alert-icon add-gap">
    <ng-container *ngIf="!isAllFailed; else isNotAllFailed">
      <div class="modal-alert-icon success">
        <img src="assets/24x24/check-circle.svg" alt="" />
      </div>
      <div class="modal-heading">
        <h1 class="modal-header-title">Uploaded Documents Summary</h1>
        <p class="modal-header-subtitle">
          Selected documents have been uploaded successfully
        </p>
      </div>
    </ng-container>
    <ng-template #isNotAllFailed>
      <div class="modal-alert-icon danger">
        <img src="assets/24x24/alert-circle.svg" alt="" />
      </div>
      <div class="modal-heading">
        <h1 class="modal-header-title">Uploaded Documents Summary</h1>
        <p class="modal-header-subtitle">
          Selected documents could not be uploaded successfully
        </p>
      </div>
    </ng-template>
  </div>
  <button class="modal-header-action" type="button" (click)="close()">
    <img src="assets/24x24/close.svg" alt="" />
  </button>
</div>
<div class="modal-body" *ngIf="uploadCounts?.length">
  <div class="col">
    <div *ngFor="let count of uploadCounts!">
      <ng-container
        *ngIf="count?.status === 'success'; else failedFilesSection"
      >
        <div class="container">
          <div class="header">
            <div>
              <label class="label">File uploaded successfully</label>
              <p class="file-name">{{ count?.fileName }}</p>
            </div>
            <div>
              <img src="assets/12x12/tick.svg" alt="" />
            </div>
          </div>
          <div class="counts">
            <div class="row">
              <p>Total</p>
              <p>: {{ count?.totalCount || 0 }}</p>
            </div>
            <div class="row">
              <p>Successful</p>
              <p>: {{ count?.successCount || 0 }}</p>
            </div>
            <div class="row">
              <p>Unsuccessful</p>
              <p>: {{ count?.failedCount || 0 }}</p>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #failedFilesSection>
        <div class="file-and-progress-bar failed">
          <div class="show-file-list">
            <div class="file-details">
              <div class="file">
                <img src="assets/24x24/file.svg" alt="" />
              </div>
              <div class="file-name-size">
                <div class="file-info">
                  <p class="name">
                    {{ count?.fileName }} -
                    <span class="failed"> Upload failed</span>
                  </p>
                  <p class="size">{{ count?.fileSize | fileUnit }}</p>
                  <p class="name" *ngIf="count?.reason">
                    <small class="failed">*{{ count?.reason }}</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
