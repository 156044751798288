import { animate, style, transition, trigger } from '@angular/animations';
import { Dialog } from '@angular/cdk/dialog';
import { HttpErrorResponse } from '@angular/common/http';

import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  APPROVAL_STATUS,
  CUSTODY_VERIFICATION_STATUS,
  IAssetDetails,
  IIssuesList,
  IMarkAsTicketAsResolved,
  IPopupConfig,
  ITicketDetails,
  IVerifyAsset,
  MODALS,
  ROUTES,
  TICKET_PRIORITIES,
  TICKET_STATUS,
  TICKET_TYPES,
  TRANSACTION_STATUS,
  UpdateTicketAssigneeReqData,
  getTicketDetails,
} from '@common/interfaces';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { SignInService } from 'libs/shared/web/auth/sign-in/sign.in.service';
import { FormServiceService } from 'libs/shared/web/forms/form-service.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationPopupWithFormComponent } from '../confirmation-popup-with-form/confirmation-popup-with-form.component';
import { EditTicketComponent } from '../edit-ticket/edit-ticket.component';
import { GenericPopUpComponent } from '../generic-pop-up/generic-pop-up.component';
import { TextareaComponent } from '../textarea/textarea.component';
import { ModalService } from 'libs/common/src/lib/service/modal.service';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'app-view-ticket-modal',
  templateUrl: './view-ticket-modal.component.html',
  styleUrls: ['./view-ticket-modal.component.scss'],
  animations: [
    trigger('basicAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class ViewTicketModalComponent implements OnInit {
  @Output() updateTicket = new EventEmitter();
  @Output() viewTicketImage = new EventEmitter();
  newAssetCode: string = 'Unassigned';
  reportingManager: string = '';
  onClickOfCancelForDropDown: boolean = false;
  index = -1;
  ticketId = '';
  ticketNo = '';
  ticketStatus = '';
  ticketTitle = '';
  ticket!: ITicketDetails;
  options: string[] = [];
  adminData!: any[];
  assignedTo = '';
  selectedAdmin = '';
  userId!: string;
  ticketComments: any[] = [];
  ticketType: string = '';
  ticketSubCategory: string = '';
  TICKET_TYPES = TICKET_TYPES;
  ticketClosedBy: any;
  ROUTES = ROUTES;
  isAdmin!: boolean;
  assetId!: string;
  assetID!: string;
  assetCode!: string;
  assetName!: string;
  assetDetails!: IAssetDetails;
  assigneeSelected!: boolean;
  assignee!: string;
  hasLoadedCheckedInCheckedOut!: boolean;
  assigneeName!: string;
  oldAssignee!: string;
  newAssignee!: string;
  loaderView!: boolean;
  placeholderTitle = 'No comments added yet';
  placeholderSubtitle = 'Post a comment to start the conversation';
  addCommentForm = new UntypedFormGroup({
    comment: new UntypedFormControl(null, [
      Validators.required,
      Validators.maxLength(255),
      Validators.minLength(3),
      CommonService.cannotContainSpace,
    ]),
  });
  assigneeForReportAnIssue!: string;
  viewTicketImageSrc = '';
  verifyAssetForm = new UntypedFormGroup({
    assetCode: new UntypedFormControl(null, [Validators.required]),
    textArea: new UntypedFormControl(null,[Validators.required])
  });
  checkInAssetForm = new UntypedFormGroup({
    assetCode: new UntypedFormControl(null,[Validators.required]),
    textArea: new UntypedFormControl(null,[Validators.required]),
  });
  ticketSubStatus!: string;
  ticketPriority!: string;
  ticketTimeStamp!: string;
  showAssetName: boolean = false;
  selectAssigneePlaceHolder = 'Select Assignee';
  assetcode: string = '';
  showCheckOutForRequestAnAsset: boolean = false;
  showApprovalSuccessMsg: boolean = false;
  APPROVAL_STATUS = APPROVAL_STATUS;
  TICKET_STATUS = TICKET_STATUS;
  CUSTODY_VERIFICATION_STATUS = CUSTODY_VERIFICATION_STATUS;
  currentUser = JSON.parse(localStorage.getItem('userInfo') as string);
  checkInSuccessful: boolean = true;
  viewVerifyAsset!: IVerifyAsset;
  showMarkAsCompleted: boolean = false;
  showResendApproval: boolean = false;
  loading = true;
  isCheckedIn!: boolean;
  fileName!: string;
  editButtonImage: string = 'assets/14x14/Iconedit-button.svg';
  countData: number[] = [0, 0];
  imageExtensions = ['.png', '.jpg', '.jpeg', '.PNG', '.JPG', '.JPEG'];
  videoExtensions = ['.mp4', '.mpeg', '.MP4', '.MPEG'];
  isImage = false;
  isVideo = false;
  textOnCheckInButton = 'Check in Reported Asset';
  reasonForResolution!: string;
  assigneeId!: string;
  makeCommentButtonDisabled = false;
  adminRole!:string
  form = new FormGroup({
    approver: new FormControl('')
  })

  requestApprovalsData: any;
  
  subCategoryOptions: any;
  requestApprovalDetails: any ;
  showRequestApprovalDetails: boolean = false;
  isRequestApproverDisabled: boolean = false;

  @ViewChild('textArea', { static: false }) childComponent!: TextareaComponent;
  constructor(
    private commonHttpService: CommonHttpService,
    private signInService: SignInService,
    private formService: FormServiceService,
    private activatedRoute: ActivatedRoute,
    private dialog: Dialog,
    private toastrService: ToastrService,
    private modalService: ModalService,
  ) {
    const getUserInfo = JSON.parse(localStorage.getItem('userInfo') as string);
    
    this.adminRole = getUserInfo.roles[0]
    console.log('this.adminRole: ', getUserInfo);

    this.isAdmin =
      getUserInfo.roles[0] === 'ADMIN' || getUserInfo.roles[0] === 'EKO-ADMIN';


  }
  ngOnInit(): void {
    const getUserId = localStorage.getItem('userId') as string
    this.commonHttpService.getSuggestions(getUserId).subscribe({
      next:(response: any) => {
        const subCategoryOptionsList = response.data[0].remainingUsers;
        const { reportingManager, reportingPartner, ITAdministrator } = response.data[0].suggestions;
        this.subCategoryOptions = [reportingManager, reportingPartner, ITAdministrator, { ishorizontalLine: true }, ...subCategoryOptionsList];
        this.modifySuggestions(this.subCategoryOptions);

      }
    })
    this.assetCode = '';
    this.activatedRoute.params.subscribe((res) => {
      const ticketId = res['id'];
      if (ticketId) {
        this.getTicketDetails(ticketId);
        this.showApprovalStatus(ticketId);
      }
    });
  }

  modifySuggestions(data: any) {
    this.subCategoryOptions = data.map((category: any)=>{
      return {
          titlename: category.name,
          email: category.email,
          id: category.id,
          subCategory: category.name,
          isChecked: false
      }
    })

    this.subCategoryOptions[0].titlename = "Reporting Manager Name"
    this.subCategoryOptions[1].titlename = "Reporting Partner Name"
  }

  getTicketDetails(ticketId: string) {
    const loadRequest = this.commonHttpService.getTicketDetails(ticketId);
    loadRequest.subscribe({
      next: (response) => {
        this.ticket = getTicketDetails(Object(response).data[0]);
        this.assigneeId = Object(response).data[0]?.assignee?.id;
        this.reasonForResolution = Object(response).data[0]?.resolution;
        this.isCheckedIn = this.ticket?.currentRecord;
        this.ticketClosedBy = this.ticket?.ticketClosedBy?.id;
        if (Object(response).data[0].fileName === '') {
          this.fileName = 'IMG - 981y39123qe81.jpg';
        } else {
          this.fileName = Object(response).data[0].fileName;
        }
        this.reportingManager =
          Object(response).data[0]?.users?.reportingManagerName;
        this.ticketStatus = this.ticket.ticketDetails.status;
        this.ticketSubStatus = this.ticket.ticketDetails.ticketSubStatus;
        this.showMarkAsCompleted = !!this.ticket?.newAsset?.id;
        this.getAssetByAssetId(this.ticket.assetId);
        this.viewTicketDetails(this.ticket);
        this.loading = false;
      },
      error: (response: HttpErrorResponse) => {
        this.loading = false;
      },
    });
  }
  displayCount(value: any) {
    this.countData = value;
  }
  getAssetByAssetId(assetId: string) {
    if (assetId) {
      this.commonHttpService.getAssetDetails(assetId).subscribe({
        next: (response) => {
          const { assetCode, name } = Object(response).data.asset;
          this.ticket.assetDetails.assetId = assetCode;
          this.ticket.assetDetails.assetName = name;
        },
        error: (error: HttpErrorResponse) => {
          const { errorList } = error.error.error;
          this.ticket.assetDetails.assetId = errorList || 'Asset Not Found';
          this.ticket.assetDetails.assetName = errorList || 'Asset Not Found';
        },
      });
    }
  }

  addComment() {
    const userId = this.signInService.getUserId();
    this.makeCommentButtonDisabled = true;
    let formData = {
      ticketId: this.ticketId,
      userId,
      comment: this.addCommentForm.value.comment.trim(),
    };

    this.commonHttpService.addComment(formData).subscribe({
      next: () => {
        this.makeCommentButtonDisabled = false;
        this.commonHttpService
          .getAllComments(this.ticketId)
          .subscribe((response: any) => {
            this.ticketComments = response.data;
            this.addCommentForm.get('comment')?.setValue('');
            this.childComponent.writeValue('');
          });
      },
      error: (error: HttpErrorResponse) => {
        const { errorList } = error.error;
        this.makeCommentButtonDisabled = false;
        this.toastrService.error(
          errorList || 'Something went wrong, comment not added.',
          'Failed!',
          { toastClass: 'toaster-wrapper' }
        );
      },
    });
  }

  ticketUpdates(userId?: string) {
    const requestData: UpdateTicketAssigneeReqData = {
      assignedTo: this.userId || userId!,
      id: this.ticketId,
    };
    this.commonHttpService.updateTicketAssignee(requestData).subscribe({
      next: (response: any) => {
        this.ticketStatus = response.data?.status;
        this.toastrService.success(
          'Assignee has been changed successfully',
          '',
          {
            toastClass: 'toaster-wrapper',
          }
        );
        this.getTicketDetails(this.ticketId);
      },
      error: () => {
        this.toastrService.error('Assignee cannot be changed', '', {
          toastClass: 'toaster-wrapper',
        });
      },
    });
  }

  markTicketAsResolved() {
    const ticketId: string = this.ticket.ticketDetails.ticketId;
    const requestData: IMarkAsTicketAsResolved = {
      status: TICKET_STATUS.CLOSED,
      ticketId,
    };

    const formData = {
      header: 'Are you sure you want to close this ticket?',
      description: 'Please mention the reason of closing this ticket.',
      image: 'cautionImage',
      form: {
        confirmationForm: new UntypedFormGroup({
          closeTicket: new UntypedFormControl('', [Validators.required]),
          resolution: new UntypedFormControl('', [Validators.required]),
          ticketClosureTempFileKey: new UntypedFormControl([]),
        }),
      },
      fields: [
        {
          field: 'dropdown',
          controlName: 'closeTicket',
          options: ['Mark as Deferred', 'Mark as Completed'],
          title: 'Select Status',
          shouldEmitOption: true,
          label: 'Close ticket *',
        },
        {
          field: 'text-area',
          controlName: 'resolution',
          placeholder:
            'Tell us a little more about why you marked this ticket as completed...',
          label: 'Reason for ticket closure *',
        },
        {
          field: 'ticketClosureTempFileKey',
          controlName: 'ticketClosureTempFileKey',
          label: 'Attachment (Optional)',
        },
      ],
      secondaryBtn: 'Cancel',
      primaryBtn: 'Close Ticket',
    };
    if (this.ticketType === TICKET_TYPES.ASSET_ALLOCATION && this.assetCode) {
      requestData['newAssetCode'] = this.assetCode;
    }
    const dialogRef = this.dialog.open(ConfirmationPopupWithFormComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      disableClose: true,
      width: '50%',
      data: formData,
    });
    dialogRef.closed.subscribe((result) => {
      if (result) {
        const ticketResolutionObject = {
          ticketId: this.ticketId,
          status: 'CLOSED',
          resolution: Object(result).resolution.replace(/[\n,]/g, ' '),
          ticketSubStatus:
            Object(result).closeTicket === 'Mark as Deferred'
              ? 'DEFERRED'
              : 'COMPLETED',
          ticketClosureTempFileKey: Object(result).ticketClosureTempFileKey[
            Object(result).ticketClosureTempFileKey.length - 1],
        };
        this.loading = true;
        this.commonHttpService
          .updateTicketAsResolved(ticketResolutionObject)
          .subscribe((result) => {
            if (Object(result).data) {
              this.toastrService.success(
                'Ticket marked as complete successfully.',
                '',
                {
                  toastClass: 'toaster-wrapper',
                }
              );
              this.getTicketDetails(this.ticketId);
              window.location.reload()
            } else {
              this.toastrService.error(
                Object(result)?.error?.error?.error?.errorList ||
                  'Something went wrong!',
                '',
                {
                  toastClass: 'toaster-wrapper',
                }
              );
              this.loading = false;
            }
          });
      }
    });
  }

  getValue(value: string) {
    this.selectedAdmin = value;
    this.assigneeSelected = true;
  }

  moveTicket() {
    const ticketMoveObject = {
      status: 'ONGOING',
      ticketId: this.ticketId,
    };
    this.loaderView = true;
    this.commonHttpService
      .updateTicketAsResolved(ticketMoveObject)
      .subscribe((result) => {
        if (Object(result).data.status === 'ONGOING') {
          this.getTicketDetails(this.ticketId);
        }
        this.loaderView = false;
      });
  }
  moveToOngoing() {
    const ticketOngoingObject = {
      status: 'ON_HOLD',
      ticketId: this.ticketId,
    };
    this.loaderView = true;
    this.commonHttpService
      .updateTicketAsResolved(ticketOngoingObject)
      .subscribe((result) => {
        if (Object(result).data.status === 'ON_HOLD') {
          this.getTicketDetails(this.ticketId);
        }
        this.loaderView = false;
      });
  }

  triggerModal(modal: string, additionalData?: any) {
    let data: any = '';
    switch (modal) {
      case MODALS.ASSIGNEE_CONFIRMATION_POPUP:
        data = {
          Details: {
            'Ticket No': this.ticket.ticketDetails.ticketNo,
            'Ticket Type':
              this.ticket.ticketDetails.type === 'REPAIR/REPLACEMENT'
                ? 'Report An Issue'
                : this.ticket.ticketDetails.type ===
                  TICKET_TYPES.ACCESS_INSTALLATION
                ? 'Request an Access/Installation'
                : 'Request an Asset',
            'Old Assignee': this.oldAssignee,
            'New Assignee': this.newAssignee,
          },
        };
        this.modalService
          .triggerModal(modal, data)
          ?.subscribe((result: any) => {
            if (result === 'Change Assignee') {
              this.ticketUpdates(this.userId);
            }
          });
        break;
      case MODALS.ASSIGNEE_CONFIRMATION_POPUP_FIRST_TIME:
        data = {
          Details: {
            'Ticket No': this.ticket.ticketDetails.ticketNo,
            'Ticket Type':
              this.ticket.ticketDetails.type === 'REPAIR/REPLACEMENT'
                ? 'Report an Issue'
                : this.ticket.ticketDetails.type ===
                  TICKET_TYPES.ACCESS_INSTALLATION
                ? 'Request an Access/Installation'
                : 'Request an Asset',
            'Old Assignee': 'Unassigned',
            'New Assignee': this.newAssignee,
          },
        };
        this.modalService
          .triggerModal(modal, data)
          ?.subscribe((result: any) => {
            if (result === 'Assign') {
              this.ticketUpdates(this.userId);
            }
          });
        break;
      case MODALS.CHECKOUT_CONFIRMATION_POPUP:
        data = {
          'Loanee Details': {
            'Employee Name': this.ticket.ticketDetails.reporter,
            'Employee ID': this.ticket.ticketDetails.reporterEmployeeId,
            Designation: this.ticket.ticketDetails.designation,
          },
          'Asset Detail': {
            'Asset Type': this.viewVerifyAsset?.type,
            'Asset Name': this.viewVerifyAsset.name,
            'Asset ID': this.viewVerifyAsset.assetCode,
          },
        };

        // if (additionalData)
        //   data['hasAssetAlready'] = additionalData;

        this.modalService
          .triggerModal(modal, data)
          ?.subscribe((result: any) => {
            if (result === 'Check Out') {
              this.checkOutAsset();
            }
          });
        break;
      case MODALS.DUPLICATE_ASSET_POPUP:
        data = {
          'Loanee Details': {
            'Employee Name': this.ticket.ticketDetails.reporter,
            'Employee ID': this.ticket.ticketDetails.reporterEmployeeId,
            Designation: this.ticket.ticketDetails.designation,
          },
          'Asset Detail': {
            'Asset Type': this.viewVerifyAsset?.type,
            'Asset Name': this.viewVerifyAsset.name,
            'Asset ID': this.viewVerifyAsset.assetCode,
          },
        };

        this.modalService.triggerModal(modal)?.subscribe((result: any) => {
          if (result === 'Submit') {
            this.triggerModal(MODALS.CHECKOUT_CONFIRMATION_POPUP, data);
          }
        });
        break;
      case MODALS.CHECKOUT_SUCCESS_CONFIRMATION_POPUP:
        data = {
          'Loanee Details': {
            'Employee Name': this.ticket.ticketDetails.reporter,
            'Employee ID': this.ticket.ticketDetails.reporterEmployeeId,
            Designation: this.ticket.ticketDetails.designation,
          },
          'Asset Detail': {
            'Asset Type': this.viewVerifyAsset?.type,
            'Asset Name': this.viewVerifyAsset?.name,
            'Asset ID': this.viewVerifyAsset?.assetCode,
          },
        };
        this.commonHttpService
          .duplicateAssetValidate(
            this.assetDetails.asset?.assetCode,
            this.userId
          )
          .subscribe((validation) => {
            data['hasAssetAlready'] = { ...Object(validation) };
          });
        this.modalService
          .triggerModal(modal, data)
          ?.subscribe((result: any) => {});
        break;
      case MODALS.CHECKIN_CONFIRMATION_POPUP:
        data = {
          'Asset Detail': {
            'Asset Type': this.assetDetails.asset.asset_type.name,
            'Asset Name': this.assetDetails.asset.modelName,
            'Asset Code': this.assetDetails.asset.assetCode,
          },
        };
        this.modalService
          .triggerModal(modal, data)
          ?.subscribe((result: any) => {
            if (result === 'Check In') {
              this.checkIn();
            }
          });
        break;
      case MODALS.CHECKIN_SUCCESS_CONFIRMATION_POPUP:
        data = {
          'Asset Detail': {
            'Asset Type': this.assetDetails.asset.asset_type.name,
            'Asset Name': this.assetDetails.asset.modelName,
            'Asset Code': this.assetDetails.asset.assetCode,
          },
        };
        this.modalService
          .triggerModal(modal, data)
          ?.subscribe((result: any) => {});
        break;
      case MODALS.RESEND_APPROVAL_CONFIRMATION:
        data = {
          'Asset Detail': {
            'Asset Type': this.ticket?.newAsset?.asset_type?.name,
            'Asset Name': this.ticket?.newAsset?.name,
            'Asset ID': this.ticket?.newAsset?.assetCode,
          },
        };
        this.modalService
          .triggerModal(modal, data)
          ?.subscribe((result: any) => {
            if (result === 'Resend Request') {
              this.sendCustodyVerification();
              this.getTicketDetails(this.ticketId);
            }
          });
        break;
      case MODALS.REQUEST_APPROVAL_CONFIRMATION:
        data = this.ticket.assetTypes.name;
        this.modalService
          .triggerModal(modal, data)
          ?.subscribe((result: any) => {
            if (result === 'Request Approval') {
              this.sendApprovalLink(this.ticketId);
            }
          });
        break;
      default:
        break;
    }
  }

  getIndex(index: number) {
    this.adminData.forEach((admin: any, adminIndex: number) => {
      if (index === adminIndex) {
        this.userId = admin.userId;
        this.oldAssignee = this.assignedTo;
        this.newAssignee =
          this.adminData[index].firstName +
          ' ' +
          this.adminData[index].lastName;
        this.getTicketDetails(this.ticketId);
        if (this.assignedTo === 'Unassigned') {
          this.triggerModal(MODALS.ASSIGNEE_CONFIRMATION_POPUP_FIRST_TIME);
        } else if (this.assignedTo !== 'Unassigned') {
          this.triggerModal(MODALS.ASSIGNEE_CONFIRMATION_POPUP);
        }
      }
    });
    this.index = index;
  }

  sendApprovalLink(ticketId: string) {
    this.loading = true;
    const loadRequest = this.commonHttpService.sendApprovalLink(ticketId);
    if (loadRequest) {
      loadRequest.subscribe({
        next: () => {
          this.toastrService.success(
            'Approval link has been sent successfully.',
            '',
            {
              toastClass: 'toaster-wrapper',
            }
          );
          this.getTicketDetails(ticketId);
          this.loading = false;
        },
        error: () => {
          this.toastrService.error(
            'Approval link has not been sent, try again!',
            '',
            {
              toastClass: 'toaster-wrapper',
            }
          );
          this.loading = false;
        },
      });
    }
  }

  openFile(src: any, fileName: string) {
    const getExtension = fileName.split('.');
    const fileExtension = getExtension[getExtension.length - 1];
    this.isImage = false;
    this.isVideo = false;

    if (this.imageExtensions.includes(`.${fileExtension}`)) {
      this.isImage = true;
    } else if (this.videoExtensions.includes(`.${fileExtension}`)) {
      this.isVideo = true;
    } else if (fileExtension === 'pdf') {
      this.downloadOrViewFile(src);
      return;
    }


    this.viewTicketImageSrc =
      src.statusCode === 404
        ? 'https://plchldr.co/i/500x250?text=404,%20image%20not%20found'
        : src;

    const popupConfig = {
      isImage: this.isImage,
      isVideo: this.isVideo,
      fileSrc: this.viewTicketImageSrc,
    };

    this.dialog.open<string>(GenericPopUpComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      data: popupConfig,
      width: '50%',
    });
  }

  downloadOrViewFile(fileUrl: string) {
    const isHttps = fileUrl.startsWith('https://');
    if (!isHttps) {
      fileUrl = `https:${fileUrl.split(':')[1]}`;
    }
    const ticketImage = document.createElement('a');
    ticketImage.href = fileUrl;
    ticketImage.target = '_blank';
    ticketImage.download = fileUrl;
    ticketImage.click();
  }

  viewTicketDetails(ticket: ITicketDetails) {
    if (ticket) {
      this.ticketId = ticket.ticketDetails.ticketId;
      this.ticketNo = ticket.ticketDetails.ticketNo;
      this.ticketStatus = ticket.ticketDetails.status;
      this.ticketTitle = ticket.issueDescription.title;
      this.ticketComments = ticket.ticketDetails.comments;
      this.ticketType = ticket.ticketDetails.type;
      this.ticket = ticket;
      this.assetId = ticket.assetDetails.assetId;
      this.newAssetCode = ticket.assetDetails.assetId;
      this.ticketPriority = ticket.ticketPriority.priorityName;
      this.ticketTimeStamp = ticket?.ticketPriority?.timeStamp;
      this.ticketSubCategory =
        ticket?.ticketSubcategories[0]?.sub_category?.subCategory;
      if (this.ticketStatus === 'CLOSED') {
        this.editButtonImage =
          'assets/14x14/Iconedit-button-disabled-image.svg';
      }
      if (ticket?.newAsset) {
        this.showAssetName = true;
        this.newAssetCode = ticket.newAsset.assetCode;
        this.textOnCheckInButton = 'Mark as Closed';
      }

      if (this.isCheckedIn) {
        this.checkInSuccessful = true;
        if (ticket?.newAsset) {
          this.textOnCheckInButton = 'Mark as Closed';
        } else {
          this.textOnCheckInButton = 'Check in Reported Asset';
        }
      } else {
        this.checkInSuccessful = false;
        if (!ticket?.newAsset) {
          this.textOnCheckInButton = 'Check out New Asset';
        } else {
          this.textOnCheckInButton = 'Mark as Closed';
        }
      }
    }
    const flag =
      this.isAdmin &&
      ticket.ticketDetails.status !== TICKET_STATUS.CLOSED &&
      !ticket.newAsset &&
      !ticket.ticketDetails.isExternalUser &&
      ticket.issueDescription.approvalStatus !== APPROVAL_STATUS.DECLINED &&
      ticket.ticketDetails.status === 'ONGOING';

    if (flag) {
      switch (this.ticketType) {
        case TICKET_TYPES.REPAIR_OR_REPLACEMENT:
          this.showCheckOutForRequestAnAsset = true;
          break;
        case TICKET_TYPES.ASSET_ALLOCATION:
          this.showCheckOutForRequestAnAsset = true;
          break;

        default:
      }
    }
    if (this.ticket.newAsset?.assetCode) {
      this.assetCode = this.ticket.newAsset?.assetCode;
    }

    if (
      ticket.ticketDetails.assignedTo === 'Select Assignee' ||
      ticket.ticketDetails.assignedTo === ''
    ) {
      this.assignedTo = 'Unassigned';
    } else {
      this.assignedTo = ticket.assignedTo.name;
      this.assigneeSelected = true;
    }

    this.formService.getAdmins().subscribe((res: any) => {
      const assigneeId = ticket?.ticketDetails?.assignedTo;
      this.adminData = res.data;
      this.options = [];
      res.data.forEach((user: any) => {
        this.options.push(user.firstName + ' ' + user.lastName);
        if (assigneeId !== '' && assigneeId === user.userId) {
          this.assignedTo = user.firstName + ' ' + user.lastName;
        }
      });
      if (!this.assignedTo) {
        this.assignedTo = this.selectAssigneePlaceHolder;
      }
      this.selectedAdmin = this.assignedTo;
    });

    // TODO: Need change the approach
    this.commonHttpService
      .getAssetDetailsByAssetCode(this.ticket.assetDetails.assetId)
      .subscribe({
        next: (response) => {
          this.assetDetails = Object(response).data as IAssetDetails;
        },
        error: (error: HttpErrorResponse) => {
          this.toastrService.error(
            'Related asset details could not be fetched',
            'Failed!',
            {
              toastClass: 'toaster-wrapper',
            }
          );
        },
      });
  }

  checkInAsset() {
    this.hasLoadedCheckedInCheckedOut = false;

    this.commonHttpService.verifyAsset(
      this.checkInAssetForm.value.assetCode,
      this.ticketId
    ).subscribe((data: any) => {
      if(data) {
        const checkInData = {
          email: this.ticket.ticketDetails.userEmail,
          assetId: data?.assetDetails.id,
          transactionStatus: TRANSACTION_STATUS.CHECKIN,
          ticketId: this.ticketId,
          transactionReason: this.checkInAssetForm.controls['textArea'].value
        };
        this.commonHttpService.checkInOrCheckOut(checkInData).subscribe({
          next: () => {
            this.triggerModal(MODALS.CHECKIN_SUCCESS_CONFIRMATION_POPUP);
            this.toastrService.success(
              'Asset has been checked-in successfully.',
              '',
              {
                toastClass: 'toaster-wrapper',
              }
            );
            this.hasLoadedCheckedInCheckedOut = true;
            this.getTicketDetails(this.ticketId);
          },
          error: () => {
            this.toastrService.error(
              'Error occurred while asset checking-in.',
              '',
              {
                toastClass: 'toaster-wrapper',
              }
            );
            this.hasLoadedCheckedInCheckedOut = true;
          },
        });
      }
    })
    
  }
  // New Check-out Process
  verifyAsset() {
    
    const assetCode = this.verifyAssetForm.value.assetCode;
    const ticketId = this.ticketId;
    const verifyAssetRequest = this.commonHttpService.verifyAsset(
      assetCode,
      ticketId
    );

    const validateDuplicateAssetRequest =
      this.commonHttpService.duplicateAssetValidate(
        assetCode,
        this.ticket.ticketRaisedBy.employeeId
      );

    forkJoin([verifyAssetRequest, validateDuplicateAssetRequest]).subscribe({
      next: ([verifyAssetData, validateDuplicateAssetData]) => {
        const asset = Object(verifyAssetData).data.assetDetails;
        const isDuplicate = Object(validateDuplicateAssetData).data;
        this.viewVerifyAsset = {
          ...asset,
          type: asset?.asset_type?.name,
          isAssetVerified: true,
        };

        if (isDuplicate) {
          this.triggerModal(MODALS.DUPLICATE_ASSET_POPUP);
        } else {
          this.triggerModal(MODALS.CHECKOUT_CONFIRMATION_POPUP, {
            ...validateDuplicateAssetData,
          });
        }
      },
      error: (response: HttpErrorResponse) => {
        const error = response.error.error.errorList[0];
        this.viewVerifyAsset = {
          ...response.error,
          isAssetVerified: false,
          errorMsg: error.msg,
        };
        this.openVerifyAssetCodePopUp('error', this.viewVerifyAsset.errorMsg);
        this.toastrService.error('Asset could not be verified.', 'Failed!', {
          toastClass: 'toaster-wrapper',
        });
      },
    });
  }

  openVerifyAssetCodePopUp(type: string, errorMessage?: string) {
    let popupConfig!: IPopupConfig;
    if (type === 'error') {
      popupConfig = {
        headerIcon: `${type}`,
        title: 'Asset verfication has failed',
        subtitle: `${errorMessage}`,
        data: {},
        hasForm: true,
        primaryButtonText: 'Cancel',
        secondaryButtonText: 'Verify Asset',
      };
    } else {
      popupConfig = {
        headerIcon: `${type}`,
        title: 'Do you want to check out?',
        subtitle:
          'Please verify the following details before checking out the asset',
        data: {},
        hasForm: true,
        primaryButtonText: 'Cancel',
        secondaryButtonText: 'Verify Asset',
      };
    }
    const dialogRef = this.dialog.open<string>(GenericPopUpComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      data: popupConfig,
      width: '50%',
    });
    dialogRef.closed.subscribe((result: any) => {
      if (result[0] === 'Verify Asset') {
        this.verifyAssetForm.get('assetCode')?.setValue(result[1]);
        this.verifyAsset();
      }
    });
  }
  checkOutAsset() {
    this.hasLoadedCheckedInCheckedOut = false;
    const checkOutPayload = {
      email: this.ticket.ticketDetails.userEmail,
      transactionStatus: TRANSACTION_STATUS.CHECKOUT,
      assetId: this.viewVerifyAsset.id,
      ticketId: this.ticketId,
      transactionReason: this.verifyAssetForm.controls['textArea'].value
    };
    const loadRequest =
      this.commonHttpService.checkInOrCheckOut(checkOutPayload);

    loadRequest.subscribe({
      next: () => {
        this.triggerModal(MODALS.CHECKOUT_SUCCESS_CONFIRMATION_POPUP);
        this.toastrService.success(
          'Asset has been checked-out successfully.',
          '',
          {
            toastClass: 'toaster-wrapper',
          }
        );
        this.getTicketDetails(this.ticketId);
        this.showAssetName = true;
        this.hasLoadedCheckedInCheckedOut = true;
      },
      error: () => {
        this.toastrService.success(
          'Error occurred while checking-out an asset.',
          '',
          {
            toastClass: 'toaster-wrapper',
          }
        );
        this.hasLoadedCheckedInCheckedOut = true;
      },
    });
  }

  sendCustodyVerification() {
    const userId = this.ticket.ticketDetails.userId;
    if (userId) {
      this.commonHttpService
        .sendCustodyVerificationMail({
          userId,
          assetId: this.ticket?.newAsset.id,
        })
        .subscribe({
          next: () => {
            this.toastrService.success(
              'Custody verification request has been sent successfully.',
              '',
              {
                toastClass: 'toaster-wrapper',
              }
            );
          },
          error: () => {
            this.toastrService.error(
              'Error occurred while sending custody verification request.',
              '',
              {
                toastClass: 'toaster-wrapper',
              }
            );
          },
        });
    }
  }
  getSubCategoriesString(subCatagoriesArray: any) {
    if (subCatagoriesArray) {
      let issues = subCatagoriesArray
        .map((item: any) => {
          return item.sub_category.subCategory;
        })
        .join(', ');
      return issues;
    } else {
      return 'N/A';
    }
  }
  checkIn() {
    this.hasLoadedCheckedInCheckedOut = false;
    const checkInData = {
      email: this.ticket.ticketDetails.userEmail,
      assetId: this.assetDetails?.asset?.id,
      transactionStatus: TRANSACTION_STATUS.CHECKIN,
    };
    this.commonHttpService.checkInOrCheckOut(checkInData).subscribe({
      next: () => {
        this.triggerModal(MODALS.CHECKIN_SUCCESS_CONFIRMATION_POPUP);
        this.toastrService.success(
          'Asset has been checked-in successfully.',
          '',
          {
            toastClass: 'toaster-wrapper',
          }
        );
        this.hasLoadedCheckedInCheckedOut = true;
        this.getTicketDetails(this.ticketId);
      },
      error: () => {
        this.toastrService.error(
          'Error occurred while asset checking-in.',
          '',
          {
            toastClass: 'toaster-wrapper',
          }
        );
        this.hasLoadedCheckedInCheckedOut = true;
      },
    });
  }

  getCustodyStatus(assets: any[]) {
    if (assets?.length) {
      return assets[assets.length - 1].custodyVerified;
    }
  }
  onEditTicket() {
    const dialogRef = this.dialog.open<string>(EditTicketComponent, {
      panelClass: 'dialog-box',
      backdropClass: 'backdrop',
      width: '50%',
      disableClose: true,
      data: {
        ticketId: this.ticketId,
        ticket: this.ticket,
        assignees: this.adminData,
      },
    });
    dialogRef.closed.subscribe((result: any) => {
      if (result !== 'cancel' && result !== 'error') {
        this.toastrService.success(result, '', {
          toastClass: 'toaster-wrapper',
        });
      } else if (result === 'error') {
        this.toastrService.error('Something went wrong!', '', {
          toastClass: 'toaster-wrapper',
        });
      }
      this.getTicketDetails(this.ticketId);
    });
  }

  handleClick() {
    if (this.textOnCheckInButton === 'Mark as Closed') {
      this.markTicketAsResolved();
    } else if (this.textOnCheckInButton === 'Check out New Asset') {
      this.openVerifyAssetCodePopUp('warning');
    } else {
      this.triggerModal(MODALS.CHECKIN_CONFIRMATION_POPUP);
    }
  }

  getDisableStatus() {
    if (
      this.ticket.ticketDetails.status === 'ONGOING' &&
      this.assetDetails &&
      !this.ticket.ticketDetails.isExternalUser
    ) {
      return false;
    }
    return true;
  }
  getPriorityClass() {
    if (this.ticketPriority === TICKET_PRIORITIES.HIGH) {
      return 'high';
    } else if (this.ticketPriority === TICKET_PRIORITIES.MEDIUM) {
      return 'medium';
    } else if (this.ticketPriority === TICKET_PRIORITIES.LOW) {
      return 'low';
    } else {
      return '';
    }
  }

  getValueDropdown(data:any){
    this.requestApprovalsData = {
      "approvalsBy":data[0]
    }
    data[0].length > 0 ? this.isRequestApproverDisabled = true : this.isRequestApproverDisabled = false;
    this.onClickOfCancelForDropDown = false;
  }
  getIndexDropdown(data: any) {

  }

  requestApproval() {
    this.commonHttpService.sendRequestApprovals(this.ticketId,this.requestApprovalsData).subscribe({
      next: (response: any) => {
        if(response.statusCode === 200){
          this.showRequestApprovalDetails = true;
          this.showApprovalStatus(this.ticketId);
          this.onCancelSelection();
        }
      },
      error:(error:Error)=>{
        this.showRequestApprovalDetails = false;
      }
    })
  }

  onCancelSelection() {
    this.isRequestApproverDisabled = false;
    this.subCategoryOptions.map((category: any)=>{
      category.isChecked = false;
    })
    this.onClickOfCancelForDropDown = true;
  }

  showApprovalStatus(ticketId:any) {
    this.commonHttpService.getApprovalStatus(ticketId).subscribe({
      next: (response: any) => {
        this.requestApprovalDetails = response.data;
      }
    })
  }
}
