
import { ChangeDetectionStrategy } from '@angular/core';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TICKET_PRIORITIES } from '@common/interfaces';
import { paginationChange } from '@common/interfaces';
import { FIELD_ARRAY_FOR_CLOSED_TICKETS, FIELD_ARRAY_TICKET_LIST } from 'libs/common/src/lib/constants/table-field-constants';
@Component({
  selector: 'app-table-list',
  templateUrl: './table-list.component.html',
  styleUrls: ['./table-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableListComponent {
  @Input() fieldArray!: Array<{ fieldName: string, type: string, keyType: string, key: any, icon: string }>;
  HeadArray: Array<{ fieldName: string }> = [];
  @Input() dataSource!: any[];
  @Output() clickEvent = new EventEmitter<string>()
  @Output() clickOnSort = new EventEmitter<string>();
  descendingSortIcon = `assets/12x12/sort-asc.svg`;
  ascendingSortIcon = `assets/12x12/sort-desc.svg`;
  currentSortOrder = 'DESC';
  sortIcons = [{ typeOfOrder: 'DESC', icon: `assets/12x12/sort-asc.svg` }, { typeOfOrder: 'ASC', icon: 'assets/12x12/sort-desc.svg' }]
  imageAddress = this.sortIcons[0].icon;
  hasDate: boolean = false;
  previousDate!: string
  isClosedCardSelected: boolean = false;
  canDateBeShown: boolean = false;
  placeholderTitle = 'No tickets generated yet';
  placeholderSubtitle =
    'Once you start generating tickets, they will appear here';
  placeholderIcon = 'assets/58x58/no_tickets.svg';
  constructor(private cdr: ChangeDetectorRef, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource']) {
      this.previousDate = '';
    }
  }
  ngOnInit(): void {
    this.previousDate = '';
    this.activatedRoute.queryParams.subscribe((params) => {
      switch (params['status']) {
        case 'CLOSED':
          this.placeholderTitle = 'No Closed Tickets';
          this.placeholderSubtitle = 'There are no closed tickets';
          break;
        case 'TO_DO':
          this.placeholderTitle = 'No To-DO Tickets';
          this.placeholderSubtitle = 'There are no To-Do tickets';
          break;
        case 'ONGOING':
          this.placeholderTitle = 'No Ongoing Tickets';
          this.placeholderSubtitle = 'There are no ongoing tickets';
          break;
          case 'ON_HOLD':
            this.placeholderTitle = 'No On-HOLD Tickets';
            this.placeholderSubtitle = 'There are no On-Hold tickets';
            break;
        case 'EXTERNAL':
          this.placeholderTitle = 'No External Tickets';
          this.placeholderSubtitle = 'There are no external tickets';
          break;
        case 'ALL':
          this.placeholderTitle = 'No tickets generated yet';
          this.placeholderSubtitle =
            'Once you start generating tickets, they will appear here';
          break;
      }
      if (params['status'] === 'CLOSED') {
        this.fieldArray = FIELD_ARRAY_FOR_CLOSED_TICKETS;
        this.HeadArray = this.fieldArray.map((r: { fieldName: any; }) => r.fieldName);
        this.isClosedCardSelected = true;
      }
      else {
        this.fieldArray = FIELD_ARRAY_TICKET_LIST;
        this.HeadArray = this.fieldArray.map((r: { fieldName: any; }) => r.fieldName);
        this.isClosedCardSelected = false;
      }
    })
    paginationChange.subscribe((res) => {

      this.previousDate = '';
      this.cdr.detectChanges();
    })
  }
  getImageAddress(field: any) {
    if (field?.icon === 'DESC') {
      return this.descendingSortIcon;
    }
    else if (field?.icon === 'ASC') {
      return this.ascendingSortIcon;
    }
    return ' '
  }
  getStatus(status: string) {
    if (status === 'TO_DO') {
      return 'todo'
    }
    if(status === 'ON_HOLD')
    {
      return 'onhold'
    }
    if (status === 'ONGOING') {
      return 'ongoing'
    }
    if (status === 'CLOSED') {
      return 'completed'
    }
    return ''
  }

  getDateForTicketRow(fieldName: string, currentTicketDate: string) {
    if (fieldName === 'Date') {
      if (!this.previousDate) {
        this.previousDate = currentTicketDate;
        this.canDateBeShown = true;
        return this.previousDate;
      }
      else if (this.previousDate === currentTicketDate) {
        this.canDateBeShown = false;
        return '';
      } else {
        this.previousDate = currentTicketDate;
        this.canDateBeShown = true;
        return currentTicketDate;
      }
    }
    return ''
  }
  getPriorityClass(priority: string) {
    if (priority == TICKET_PRIORITIES.HIGH) {
      return 'high';
    } else if (priority == TICKET_PRIORITIES.MEDIUM) {
      return 'medium';
    } else if (priority == TICKET_PRIORITIES.LOW) {
      return 'low';
    } else {
      return '';
    }
  }

  handleClick(ticketId: string) {
    this.clickEvent.emit(ticketId);
  }
  sortAction(field: any) {

    if (field?.icon === 'DESC') {
      field.icon = 'ASC';
    } else {
      field.icon = 'DESC';
    }
    const res = field?.fieldName.split(" ").join("");
    this.clickOnSort.emit(field.icon + ' ' + res);
  }
  getClass(element: string, key: string) {
    if (element === 'Date') {
      if (key == this.previousDate) {
        return false;
      }
      return true;
    }
    if (element === 'Day') {
      let ab;
      if (key === this.previousDate) {
        ab = true;
        return ab;
      }
      else {
        ab = false;
        return ab;
      }

    }
    return false;
  }
}
