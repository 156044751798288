import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortDate'
})
export class ShortDatePipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    if (value !== '') {
      const date = new Date(Date.parse(value));
      const day = date.getDate();
      const month = this.getMonthName(date.getMonth());
      return `${day} ${month}`;
    }
    return ''
  }

  private getMonthName(month: number): string {
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr',
      'May', 'Jun', 'Jul', 'Aug',
      'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return monthNames[month];
  }
}
