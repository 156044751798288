<div class="ticket-buttons" *ngIf="!showFilter">
  <div class="filter-buttons">
    <ng-container *ngFor="let filter of filterOptions">
      <button
        class="filter-button"
        (click)="updateFilter(filter)"
        [ngClass]="{ active: activeMenu === filter.value }"
      >
        {{ filter.title }}
      </button>
    </ng-container>
  </div>
</div>
<div class="ticket-buttons" *ngIf="showFilter">
  <div class="filter-buttons">
    <button
      class="filter-button"
      (click)="updateFilter(filterOptions[0])"
      [ngClass]="{ active: isPrimaryButtonClicked }"
    >
      {{ filterOptions[0].title }}
    </button>
    <button
      class="filter-button"
      (click)="updateFilter(filterOptions[1])"
      [ngClass]="{ active: !isPrimaryButtonClicked }"
    >
      {{ filterOptions[1].title }}
    </button>
  </div>
</div>
