<cdk-accordion class="example-accordion">
  <cdk-accordion-item
    *ngFor="let item of stepData; let index = index"
    #accordionItem="cdkAccordionItem"
    class="container"
    role="button"
    tabindex="0"
    [attr.id]="'accordion-header-' + index"
    [attr.aria-expanded]="accordionItem.expanded"
    [attr.aria-controls]="'accordion-body-' + index"
  >
    <div class="check-box">
      <div class="check-box-images" (click)="accordionItem.toggle()">
        <img
          *ngIf="!accordionItem.expanded"
          src="assets/16x16/empty-checkbox.svg"
          alt=""
        />
        <img
          *ngIf="accordionItem.expanded"
          src="assets/16x16/filled-checkbox.svg"
          alt=""
        />
      </div>
      <div [ngClass]="{ shortline: !accordionItem.expanded }">
        <img
          *ngIf="accordionItem.expanded"
          src="assets/16x16/dashed-long.svg"
          alt=""
        />
        <img
          *ngIf="!accordionItem.expanded && index != stepData.length - 1"
          src="assets/16x16/dashed-short.svg"
          alt=""
        />
      </div>
    </div>
    <section class="example-accordion-item">
      <div
        class="example-accordion-item-header"
        (click)="accordionItem.toggle()"
      >
        <div>
          <span class="loanee-title">{{ getHeaderTitle(item) }}</span>
          <span class="loanee-name">{{ getHeader(item) }}</span>
        </div>
        <div class="header-month-section">
          <p *ngIf="!accordionItem.expanded" class="month-year">
            {{ formatMonthAndYear(item) }}
          </p>
          <div class="header-icon">
            <img
              *ngIf="!accordionItem.expanded; else notExpanded"
              src="assets/10x10/downarrow.svg"
              alt=""
            />
            <ng-template #notExpanded>
              <img src="assets/10x10/uparrow.svg" alt="" />
            </ng-template>
          </div>
        </div>
      </div>

      <div class="hr"></div>
      <div
        class="example-accordion-item-body"
        role="region"
        [style.display]="accordionItem.expanded ? '' : 'none'"
        [attr.id]="'accordion-body-' + index"
        [attr.aria-labelledby]="'accordion-header-' + index"
      >
        <div>
          <section *ngFor="let info of item">
            <ng-container
              *ngIf="historyType === 'Loanee History'; else repairHistory"
            >
              <div class="checkout-info">
                <p class="checkout-label">
                  {{ !info?.checkedInBy ? "Check Out" : "Check In" }}
                </p>
                <p class="checkout-date">
                  {{ info?.createdAt | date : "dd MMM, yyyy" }}
                  <span class="time"
                    >| {{ info?.createdAt | date : "hh:mm a" }}</span
                  >
                </p>
              </div>
              <div class="username" (click)="navigateToProfile(info)">
                <p>{{ getUserName(info) }}</p>
              </div>
            </ng-container>
            <ng-template #repairHistory>
              <div class="checkin-info">
                <app-chip [status]="info.assetStatus"></app-chip>
                <p class="checkout-date">
                  {{ info?.createdAt | date : "dd MMM, yyyy" }}
                  <span class="time"
                    >| {{ info?.createdAt | date : "hh:mm a" }}</span
                  >
                </p>
              </div>
              <div class="username" (click)="navigateToProfile(info)">
                <p>{{ getUserName(info) }}</p>
              </div>
            </ng-template>
          </section>
        </div>
      </div>
    </section>
  </cdk-accordion-item>
</cdk-accordion>
