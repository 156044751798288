<!-- <div class="modal"> -->
<div class="modal-header">
  <div class="modal-heading">
    <h1 class="modal-header-title">Report an Issue</h1>
    <p class="modal-header-subtitle">
      Request a repair or report an issue with an assigned asset
    </p>
  </div>
  <button
    class="modal-header-action"
    type="button"
    (click)="openCloseConfirmationDialog()"
  >
    <img src="assets/24x24/close.svg" alt="" />
  </button>
</div>
<div class="modal-body max-height">
  <form class="popup-form" [formGroup]="popupForm">
    <select class="hidden-drop-down" formControlName="location">
      <option value="hidden-select"></option>
    </select>
    <div class="dropdown-wrapper">
      <label class="label">Select Location *</label>
      <app-generic-drop-down
        [options]="locations"
        title="Select Location"
        (value)="getValue($event, 'location')"
        (index)="getIndex($event)"
        titleName="locationValue"
        valueName="locationKey"
        [hasCaption]="false"
        [shouldEmitdisplayValue]="true"
      ></app-generic-drop-down>
    </div>
    <select class="hidden-drop-down" formControlName="option">
      <option value="hidden-select"></option>
    </select>
    <div class="dropdown-wrapper">
      <label class="label">Select Asset *</label>
      <app-generic-drop-down
        [options]="options"
        title="Select Asset"
        (value)="getValue($event, 'option')"
        (index)="getIndex($event)"
        titleName="assetName"
        [hasCaption]="true"
        captionItem="assetCode"
        [shouldEmitIndex]="true"
        [shouldEmitOption]="true"
      ></app-generic-drop-down>
    </div>
    <select class="hidden-drop-down" formControlName="subCategories">
      <option value="hidden-select"></option>
    </select>
    <div class="dropdown-wrapper">
      <label class="label">Select Issue *</label>
      <app-drop-down
        from="select_an_issue"
        [options]="subCategoryOptions"
        titleName="subCategory"
        valueName="id"
        title="Select Type of Issue"
        formArrayName="subCategories"
        (value)="getValue($event, 'subCategory')"
        (index)="getIndex($event)"
        [isDropDownofSelectIssue]="true"
        [disabled]="!isAssetSelected"
        (emitIssueDiscription)="getOtherIssue($event)"
      ></app-drop-down>
      <p class="select-asset-remainder" *ngIf="!isAssetSelected">
        Please select the asset
      </p>
    </div>
    <label class="label">Describe Your Issue *</label>
    <app-textarea
      cols="30"
      rows="10"
      formControlName="description"
      characterCounter
      maxLength="255"
      [countData]="countData"
      placeholder="Tell us a little more about the issue..."
      [isRequired]="true"
      [isLabelPresent]="false"
    >
    </app-textarea>
    <label class="label">Attachment (Optional)</label>
    <app-file-upload
      (emitFileInfo)="selectedFiles($event)"
      formControlName="file"
      [multiple]="true"
      [hasImageAndVideo]="true"
      [fileTypesForValidations]="fileTypes"
    >
      <p>Image (JPG, under 2 MB, Up to 4 images)</p>
      <p>Video (MP4, under 20 MB, Up to 1 video)</p>
    </app-file-upload>
  </form>
</div>
<div class="modal-footer">
  <div class="button">
    <app-button
      class="spacing"
      type="submit"
      [disabled]="popupForm.invalid || isDisable"
      size="xsmall"
      theme="primary"
      (click)="onSubmit()"
      >Submit
    </app-button>
  </div>
</div>
<!-- </div> -->
