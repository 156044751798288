<ng-container *ngIf="type !== 'date'">
  <div *ngIf="icon">
    <input
      class="text-field"
      [type]="type"
      [required]="required"
      placeholder="placeholder"
      [(ngModel)]="value"
      (change)="onChange(value)"
      [ngClass]="{ 'has-error': hasError }"
      [disabled]="disabled"
      [pattern]="pattern"
    />
    <img class="text-field-icon" src="assets/24x24/{{ icon }}" alt="icon" />
  </div>

  <div *ngIf="!icon && !assetCode">
    <input
      class="text-field"
      [type]="type"
      [required]="required"
      [placeholder]="placeholder"
      [ngModel]="value"
      (ngModelChange)="onChange($event)"
      [ngClass]="{ 'has-error': hasError }"
      [disabled]="disabled"
      [min]="min"
    />
  </div>

  <div *ngIf="assetCode">
    <input
      class="text-field"
      [type]="type"
      [required]="required"
      [placeholder]="placeholder"
      [ngModel]="value"
      (ngModelChange)="onChange($event)"
      [ngClass]="{ 'has-error': hasError }"
      [disabled]="disabled"
      [min]="min"
      [ngClass]="{ disabled: 'disabled' }"
    />
  </div>
</ng-container>
<div *ngIf="type === 'date'">
  <input
    class="text-field"
    [type]="type"
    [required]="required"
    [placeholder]="placeholder"
    [ngModel]="value"
    (ngModelChange)="onChange($event)"
    [ngClass]="{ 'has-error': hasError }"
    [max]="max"
  />
</div>
