<section>
  <app-alert-box
    [config]="snackBarConfig"
    *ngIf="alertBox && snackBarConfig"
    (close)="alertBox = false"
  ></app-alert-box>

  <div class="asset-team-container" *ngIf="displayTicketList">
    <div class="row" *ngIf="ticketStatusClicked && displayTicketList">
      <div class="dash-section-wrapper">
        <app-user-profile-details></app-user-profile-details>
      </div>

      <div class="dash-section-wrapper">
        <app-generate-ticket-section
          (toggleModalEmitter)="triggerModal($event)"
        ></app-generate-ticket-section>
      </div>
    </div>

    <div
      class="dash-section-wrapper"
      *ngIf="ticketStatusClicked && displayTicketList"
    >
      <app-user-assets-section></app-user-assets-section>
    </div>
    <router-outlet
      (activate)="ticketStatusClicked = !ticketStatusClicked"
    ></router-outlet>
  </div>
  <div
    class="dash-section-wrapper"
    *ngIf="tableDetails && !displayTicketList"
    [ngClass]="{ loader: loaderView }"
  >
    <app-loader *ngIf="loaderView"></app-loader>
    <app-table
      [tableConfig]="tableConfig"
      [tableData]="tableDetails"
      [count]="count"
      [filterOptions]="filterOptions"
      [activeMenu]="pageSize"
      (activeFilter)="filterBy($event)"
      (paginatorEmitter)="getUserTickets($event)"
      (pageSizeChange)="pageSizeChange($event)"
      class="width-100"
      #table
    >
    </app-table>
  </div>
</section>
