<div
  class="container"
  [ngClass]="{ 'main-container': !historyData?.length }"
>
  <app-loader
    class="loader"
    *ngIf="!isHistoryLoaded; else assetHistorySection"
  ></app-loader>
  <ng-template #assetHistorySection>
    <div *ngIf="historyData; else assetHistoryNotFoundSection">
      <div class="header">
        <h3 class="history-heading">Asset History</h3>
        <div class="dropdown">
          <app-generic-drop-down
            [options]="ASSET_HISTORY_TYPE"
            [title]="historyType"
            (value)="getValue($event)"
            [shouldEmitOption]="true"
          ></app-generic-drop-down>
        </div>
      </div>
      <div *ngIf="!historyData?.length" class="placeholder">
        <app-empty-placeholder
          imageSrc="assets/58x58/no_assets.svg"
          title="No assets history"
          [subtitle]="
            historyType === 'Loanee History'
              ? 'Previous loanees details will be here'
              : 'Previous Repair details will be here'
          "
        ></app-empty-placeholder>
      </div>
      <app-stepper [stepData]="historyData" [historyType]="historyType">
      </app-stepper>
    </div>
    <ng-template #assetHistoryNotFoundSection>
      <div class="asset-not-loaded-section">
        <p>Something went wrong, try later!</p>
      </div>
    </ng-template>
  </ng-template>  
</div>