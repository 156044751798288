import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[tooltip]',
})
export class ToolTipDirective implements OnInit {

    @Input() tooltip = "";
    @Input() delay?= 190;
    private myPopup!: HTMLDivElement;
    private timer: string | number | NodeJS.Timeout | undefined;
    constructor(private element: ElementRef) { }
    ngOnInit(): void {
    }

    @HostListener('mouseenter') mouseEnter() {
        this.timer = setTimeout(() => {
            this.createTooltipPopup()
        }, this.delay);
    }

    @HostListener('mouseleave') mouseLeave() {
        if (this.timer) clearTimeout(this.timer);
        if (this.myPopup) { this.myPopup.remove() }
    }

    private createTooltipPopup() {
        if (this.element.nativeElement.querySelector("button").disabled) {
            let popup = document.createElement('div');
            popup.innerHTML = this.tooltip;
            popup.setAttribute("class", "tooltip-container");
            this.element.nativeElement.append(popup);
            popup.style.position = "absolute";
            popup.style.top = "7.875rem";
            popup.style.right = "15.625rem";
            this.myPopup = popup;
            setTimeout(() => {
                if (this.myPopup) this.myPopup.remove();
            }, 5000);
        }
    }
}