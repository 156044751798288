import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ASSETS_MENU, TICKETS_MENU, ROUTES } from '@common/interfaces';

import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
@Component({
  selector: 'app-asset-team-dashboard',
  templateUrl: './asset-team-dashboard.component.html',
  styleUrls: ['./asset-team-dashboard.component.scss'],
  animations: [
    trigger('basicAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class AssetTeamDashboardComponent implements OnInit {
  ticketStatus = TICKETS_MENU;
  assetStatus = ASSETS_MENU;
  showComponent: boolean = false;
  constructor(private commonHttpService: CommonHttpService, private router: Router) { }
  ngOnInit(): void {
    // ticket status and asset status in admin-dashboard
    this.commonHttpService.getTicketStatus().subscribe((res: any) => {
      this.ticketStatus[1].count = res.data.ongoingTicketCount;
      this.ticketStatus[0].count = res.data.todoTicketCount;
      this.ticketStatus[3].count = res.data.closedTicketCount;
      this.ticketStatus[4].count = res.data.externalTicketCount;
      this.ticketStatus[5].count = res.data.totalTicketCount;
      this.ticketStatus[2].count = res.data.onHoldTicketCount
    });

    this.commonHttpService.getAssetStatus().subscribe((res: any) => {
      this.assetStatus[0].count = res.data.assignedAssetCount;
      this.assetStatus[1].count = res.data.unAssignedAssetCount;
      this.assetStatus[2].count = res.data.outForRepairAssetCount;
      this.assetStatus[3].count = res.data.disabledAssetCount;
      this.assetStatus[4].count = res.data.totalAssets;
    });
    if (this.router.url === ROUTES.ASSET_TEAM_DASHBOARD) {
      this.showComponent = true;
    }
  }
}
