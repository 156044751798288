import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';

@Component({
  selector: 'app-user-assets-section',
  templateUrl: './user-assets-section.component.html',
  styleUrls: ['./user-assets-section.component.scss'],
  animations: [
    trigger('basicAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class UserAssetsSectionComponent implements OnInit {
  userDetails!: any;
  @Input() forAdmin: boolean = false;
  @Input() isLoanee: boolean = false;
  placeholderTitle = 'No assets assigned';
  placeholderSubtitle = 'Generate a ticket to request an asset';
  constructor(
    private commonHttpService: CommonHttpService,
    private commonService: CommonService,
    private router: Router
  ) { }

  userId!: string;
  assetDetails!: any;
  ngOnInit(): void {
    if (this.router.url.endsWith('employee-details') || this.router.url.includes('employee-dashboard') || this.router.url.includes('admin-employee-dashboard') || this.router.url.includes('asset-team-dashboard')) {
      this.userId = localStorage.getItem('userId') || '';
    }
    else {
      const length = this.router.url.split('/').length;
      this.userId = this.router.url.split('/')[length - 1]
    }
    this.commonHttpService.getUserDetailsById(this.userId).subscribe({
      next: (response) => {
        this.userDetails = Object(response).data[0]?.asset_allocation;
      }
    })
  }
}
