<div class="header-container">
  <div class="left-corner">
    <img
      src="assets/32x32/stessa-logo.svg"
      height="40"
      class="image"
      alt="stessa-logo"
    />
  </div>
</div>
