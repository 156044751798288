
   
      <main class="main">
        <section class="section">
          <span class="error-code">{{errorConfig.errorCode}}</span>
          <span class="error-message">{{errorConfig.errorMessage}}</span>
          <span class="error-description"
            >{{errorConfig.errorDescription}}</span
          >
         
          <div class="routing-btn">
            <a routerLink="/{{errorConfig.navigateTo}}">
              <app-button
                class="spacing"
                type="submit"
                [disabled]="false"
                size="small"
                theme="primary"
                >{{errorConfig.actionName}}</app-button
              >
            </a>
          </div>
        </section>
      </main>
   
 