<p class="asset-heading">{{ !isLoanee ? "My " : "" }} Assets</p>
<div
  class="wrapper"
  [ngClass]="{ 'grid-2': forAdmin, 'no-asset': userDetails?.length === 0 }"
  [@basicAnimation]
>
  <ng-container *ngIf="userDetails?.length !== 0; else noAssets">
    <div *ngFor="let details of userDetails" class="asset-group">
      <app-cards [assets]="details || false"></app-cards>
    </div>
  </ng-container>
  <ng-template #noAssets>
    <!-- <app-cards [assets]="false"></app-cards> -->
    <app-empty-placeholder
      imageSrc="assets/58x58/no_assets.svg"
      [title]="placeholderTitle"
      [subtitle]="placeholderSubtitle"
    ></app-empty-placeholder>
  </ng-template>
</div>
