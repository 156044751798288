import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ASSET_INFO_HEADINGDS, TICKET_STATES } from '../constants/constants';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor() {}
  searchColumn!: string;
  params!: any;
  submittedValue = new Subject<any>();
  showTicketAlert = new Subject<boolean>();
  ticketsType = new BehaviorSubject('');
  subject$ = new Subject();
  resetMultiSelect = new Subject();

  AssetId = new BehaviorSubject('');
  EmployeeEmail = new BehaviorSubject('');
  searchParam = new BehaviorSubject(this.params);
  userAssetCount = new BehaviorSubject(0);
  priorityChangeCount = new BehaviorSubject(0);
  makeDownloadReportButtonDisabled = new BehaviorSubject({
    from: ASSET_INFO_HEADINGDS.TICKET_STATUS,
    flag: false,
  });
  contactAdminTicketCreated = new BehaviorSubject(TICKET_STATES.DEFAULT);
  filterOptions: any;
  progress = 0;
  assetDetailVerifyData: any = {
    email: '',
    isEmailVerified: false,
    loaneeVerifyStatus: 'default',
  };
  loaneeEmployeeInfo: any = {};
  assetsPageParam: string = 'ALL';
  selectedAsset!: string;
  static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value || '').trim().length === 0) {
      return { cannotContainSpace: true };
    }
    return null;
  }

  static isCoditasEmail(control : AbstractControl) : ValidationErrors | null {
    if(control.value.toLowerCase().endsWith("@coditas.com")){
      return {isCoditasEmail:true}
    }
    return null;
  }

  setTicketType(ticketType: string) {
    this.ticketsType.next(ticketType);
  }
  showProgressBar(show: boolean) {
    this.subject$.next(show);
  }
  setFilterOptions(filterOptions: any) {
    this.filterOptions = filterOptions;
  }
  getFilterOptions() {
    return this.filterOptions;
  }
  filterAssetByAssetName(assetName: string) {
    this.selectedAsset = assetName;
    this.searchColumn = 'ASSET_NAME';
  }

  resetAssetDetailVerifyData() {
    this.assetDetailVerifyData = {
      email: '',
      isEmailVerified: false,
      loaneeVerifyStatus: 'default',
    };
    this.loaneeEmployeeInfo = {};
  }

  submittedEvent() {
    this.submittedValue.next(true);
  }

  showAlertForTicket() {
    this.showTicketAlert.next(true);
  }
  setProgress() {
    this.progress = 0;
  }
  getProgress() {
    setInterval(() => {
      if (this.progress < 87) {
        this.progress += Math.floor(Math.random() * 10) + 10;
      }
    }, 100);
    const fakeProgress = new Observable<number>((observer) => {
      setInterval(() => {
        if (this.progress >= 87) {
          observer.complete();
        } else {
          observer.next(this.progress);
        }
      }, 100);
    });
    return fakeProgress;
  }

  buildURL(baseUrl: string, params:any) {
    const queryParams = [];
  
    if (params.status) {
      queryParams.push(`status=${params.status}`);
    }
    if (params.page) {
      queryParams.push(`page=${params.page}`);
    }
    if (params.size) {
      queryParams.push(`size=${params.size}`);
    }
    if (params.search) {
      queryParams.push(`search=${encodeURIComponent(params.search)}`);
    }
    if (params.column) {
      queryParams.push(`column=${params.column}`);
    }
    if (params.provider) {
      queryParams.push(`provider=${params.provider}`);
    }
  
    const queryString = queryParams.join('&');
    const url = `${baseUrl}?${queryString}`;
  
    return url;
  }  
}
