import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './sign-in/sign-in.component';
import { UnderMaintainanceComponent } from './error-states/under-maintainance/under-maintainance.component';
import { NoInternetComponent } from './error-states/no-internet/no-internet.component';
import { ContactAdminComponent } from '../forms/contact-admin/contact-admin.component';
import { TestSignInComponent } from './test-sign-in/test-sign-in.component';
import { EmailNotRegisteredComponent } from './error-states/email-not-registered/email-not-registered.component';
import { PrivacyPolicyComponent } from 'libs/shared/ui-components/src/lib/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from 'libs/shared/ui-components/src/lib/terms-and-conditions/terms-and-conditions.component';
import { VerificationComponent } from './verification/verification.component';

const routes: Routes = [
  { path: 'signin', component: SignInComponent },
  { path: 'test/login', component: TestSignInComponent },
  { path: 'under-maintainance', component: UnderMaintainanceComponent },
  { path: 'no-internet', component: NoInternetComponent },
  { path: 'contact-admin', component: ContactAdminComponent },
  { path: '404-not-found', component: NoInternetComponent },
  { path: 'verification/:type', component: VerificationComponent },
  { path: 'email-not-found', component: EmailNotRegisteredComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule { }
