import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-auto-suggest',
  templateUrl: './auto-suggest.component.html',
  styleUrls: ['./auto-suggest.component.scss'],
})
export class AutoSuggestComponent implements OnInit {
  @Output() inputValue = new EventEmitter();
  @Input() suggestions = [''];

  options: string[] = [];
  value: string = '';
  show: boolean = true;
  valueSelected = '';

  constructor() {}

  ngOnInit(): void {}

  getValue(enteredKey: string): void {
    this.valueSelected = enteredKey;

    this.inputValue.emit(enteredKey);
    this.options = [];
  }

  getInput(event: Event): void {
    this.show = true;
    const value = (event.target as HTMLInputElement).value;
    if (value) {
      this.options = this.suggestions.filter((data) => {
        return data.startsWith(value.toLocaleLowerCase());
      });
    } else {
      this.options = [];
    }
  }
}
