<div class="description">
  <div class="text-area-container">
    <textarea
      [placeholder]="placeholder"
      [cols]="cols"
      [rows]="rows"
      class="text-area"
      [(ngModel)]="value"
      (ngModelChange)="onChange($event)"
      [maxLength]="maxLength"
      [ngClass]="{ 'apply-background': backgroundNeeded }"
      [disabled]="disabled"
    ></textarea>
    <p class="count" *ngIf="true">
      <!-- {{ countData[0] }} / {{ countData[1] }} -->
    </p>
  </div>
  <p class="desc-note" *ngIf="isRequired">Note- This Is a Required Field</p>
</div>
