import { Injectable, Injector } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { SignInService } from 'libs/shared/web/auth/sign-in/sign.in.service';
@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService {
  constructor(private injector: Injector) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authService = this.injector.get(SignInService);

    if (!window.navigator.onLine) {
      // if there is no internet, throw a HttpErrorResponse error
      return throwError(
        () => new HttpErrorResponse({ error: 'Internet is required.' })
      );
    } else {
      let tokenizedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${authService.getToken()}`,
        },
      });
      return next.handle(tokenizedReq);
    }
  }
}
