import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UiComponentsModule } from '@shared/ui-components';
import { AuthRoutingModule } from './auth-routing.module';
import { NoInternetComponent } from './error-states/no-internet/no-internet.component';
import { UnderMaintainanceComponent } from './error-states/under-maintainance/under-maintainance.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { TestSignInComponent } from './test-sign-in/test-sign-in.component';
import { EmailNotRegisteredComponent } from './error-states/email-not-registered/email-not-registered.component';
import { VerificationComponent } from './verification/verification.component';

@NgModule({
  declarations: [
    SignInComponent,
    UnderMaintainanceComponent,
    NoInternetComponent,
    TestSignInComponent,
    EmailNotRegisteredComponent,
    VerificationComponent
  ],
  providers: [],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    UiComponentsModule,
    HttpClientModule,
  ],
})
export class AuthModule { }
