import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-placeholder',
  templateUrl: './empty-placeholder.component.html',
  styleUrls: ['./empty-placeholder.component.scss']
})
export class EmptyPlaceholderComponent {

  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() imageSrc!: string;
}
