<div class="asset-team-container">
  <app-back-button></app-back-button>
  <div class="wrapper-container">
    <div class="asset-container" [ngClass]="{ loader: loaderView || hasError }">
      <div>
        <div class="loader-container" *ngIf="loaderView || hasError">
          <app-loader *ngIf="loaderView"></app-loader>
          <h1 *ngIf="hasError" class="asset-not-found">Asset Not Found</h1>
        </div>
        <div>
          <div class="head" *ngIf="!hasError && !loaderView">
            <h1 class="heading">{{ pageTitle }}</h1>
            <button
              *ngIf="!assetId"
              class="close-button"
              type="button"
              (click)="closeConfirmationDialog()"
            >
              <img src="assets/24x24/close.svg" alt="" />
            </button>
            <button
              *ngIf="assetId"
              class="close-button"
              type="button"
              (click)="backToDetails()"
            >
              <img src="assets/24x24/close.svg" alt="" />
            </button>
          </div>
          <p class="info" *ngIf="!loaderView && !hasError">
            {{
              assetId
                ? "Edit and update specifications of selected asset"
                : "Select an asset category and specifications to add a new asset"
            }}
          </p>
          <p class="note" *ngIf="!loaderView && !hasError">
            Fields marked with * are mandatory
          </p>
        </div>

        <div *ngIf="!loaderView && !hasError">
          <form class="popup-form" [formGroup]="addAssetForm">
            <select class="hidden-drop-down" formControlName="type">
              <option value="hidden-select"></option>
            </select>
            <div class="dropdown-wrapper">
              <div class="header-container">
                <div class="dropdown">
                  <p class="label">Category *</p>
                  <app-generic-drop-down
                    [options]="options"
                    [title]="getCategoryName('type')"
                    titleName="assetName"
                    valueName="assetId"
                    label="Select Category"
                    (value)="getValueOfCategory($event)"
                    [disabled]="!!addAssetForm.get('type')?.disabled"
                    [shouldEmitDisplayValueAndEmittedValue]="true"
                  ></app-generic-drop-down>
                </div>

                <div class="field" *ngIf="selectedOption !== ''">
                  <div class="add-asset">
                    <p for="" class="label">Asset Code *</p>
                    <button type="button" (click)="generateCode($event)" class="generate-asset-code">Generate Code</button>
                  </div>
                  <app-text-field
                    [required]="true"
                    for="assetCode"
                    id="assetCode"
                    type="text"
                    name="assetCode"
                    formControlName="assetCode"
                    label=""
                    placeholder="CSL-L-00-00-L-0000"
                    [hasError]="
                      (addAssetForm.controls['assetCode'].dirty &&
                        addAssetForm.controls['assetCode'].errors) ||
                      (assetCodeErrorMsg && !assetId)
                    "
                  >
                  </app-text-field>
                  <small
                    *ngIf="
                addAssetForm.controls['assetCode'].dirty &&
                addAssetForm.controls['assetCode'].errors &&
                !assetCodeErrorMsg && !addAssetForm.controls['assetCode'].errors?.['pattern']
              "
                    class="error-message"
                    >This field is mandatory</small
                  >
                  <small
                    *ngIf="assetCodeErrorMsg && !assetId"
                    class="error-message"
                    >{{ assetCodeErrorMsg }}</small
                  >
                </div>

                <div class="field" *ngIf="selectedOption !== ''">
                  <p for="" class="label">
                    Project Name {{ isMobileSelected ? "*" : "" }}
                  </p>
                  <app-generic-drop-down
                    [options]="projectNames"
                    [title]="addAssetForm.get('projectName')?.value || 'Select'"
                    placeholder="select Project name "
                    (value)="getProjectName($event)"
                    [shouldEmitOption]="true"
                  ></app-generic-drop-down>
                  <small
                    *ngIf="
                      addAssetForm.get('projectName')?.dirty &&
                      addAssetForm.get('projectName')?.errors
                    "
                    class="error-message"
                    >This field is mandatory</small
                  >
                </div>

                <div class="field" *ngIf="selectedOption !== ''">
                  <p for="" class="label">
                    Client Name {{ isMobileSelected ? "*" : "" }}
                  </p>
                  <app-generic-drop-down
                    [options]="clientNames"
                    [title]="addAssetForm.get('clientName')?.value || 'Select'"
                    placeholder="select Client name "
                    (value)="getClientName($event)"
                    [shouldEmitOption]="true"
                  ></app-generic-drop-down>
                  <small
                    *ngIf="
                      addAssetForm.get('clientName')?.dirty &&
                      addAssetForm.get('clientName')?.errors
                    "
                    class="error-message"
                    >This field is mandatory</small
                  >
                </div>
                <div *ngIf="selectedOption !== ''">
                  <span class="add-hyperlink" (click)="addProject()"
                    >  Add Project Name </span
                  >
                </div>
                <div *ngIf="selectedOption !== ''"  >
                  <span class="add-hyperlink" (click)="addClient()"
                    >Add Client Name </span
                  >
                </div>
              </div>
              <hr *ngIf="selectedOption !== ''" />
              <div
                class="specifications-container"
                *ngIf="selectedOption !== ''"
              >
                <p class="title">Specifications:</p>
                <div class="specifications" [ngSwitch]="selectedOption">
                  <div class="field">
                    <p for="" class="label">Model Name *</p>
                    <app-text-field
                      [required]="true"
                      for="modelName"
                      id="modelName"
                      type="text"
                      formControlName="modelName"
                      label=""
                      placeholder="Enter Model Name"
                      [value]="addAssetForm.value.modelName"
                      [hasError]="
                        addAssetForm.controls['modelName'].dirty &&
                        addAssetForm.controls['modelName'].errors
                      "
                    >
                    </app-text-field>
                    <small
                      *ngIf="
                        addAssetForm.controls['modelName'].dirty &&
                        addAssetForm.controls['modelName'].errors
                      "
                      class="error-message"
                      >This field is mandatory</small
                    >
                  </div>
                  <ng-container *ngSwitchCase="'Monitor'">
                    <div
                      class="field split-fields"
                      formGroupName="monitorFields"
                    >
                      <p class="label">Screen Size *</p>
                      <div class="sub-fields">
                        <div class="sub-field">
                          <app-text-field
                            [required]="true"
                            for="screenSize"
                            id="screenSize"
                            type="number"
                            name="screenSize"
                            formControlName="screenSize"
                            label=""
                            placeholder="Enter Number"
                            [hasError]="
                              addAssetForm
                                .get('monitorFields')
                                ?.get('screenSize')?.dirty &&
                              addAssetForm
                                .get('monitorFields')
                                ?.get('screenSize')?.errors
                            "
                          >
                          </app-text-field>
                          <small
                            *ngIf="
                              addAssetForm
                                .get('monitorFields')
                                ?.get('screenSize')?.invalid &&
                              !addAssetForm
                                .get('monitorFields')
                                ?.get('screenSize')
                                ?.hasError('min')
                            "
                            class="error-message"
                            >This field is mandatory</small
                          >
                          <small
                            *ngIf="
                              addAssetForm
                                .get('monitorFields')
                                ?.get('screenSize')
                                ?.hasError('min')
                            "
                            class="error-message"
                            >Screen size cannot be zero</small
                          >
                        </div>
                        <div class="unit-sub-field">
                          <app-generic-drop-down
                            [options]="screenSize"
                            [title]="getUnit('screenSizeUnit', 'monitorFields')"
                            titleName="unit"
                            valueName="id"
                            (value)="getScreeSizeValue($event, 'monitorFields')"
                            [shouldEmitEmittedValue]="true"
                          ></app-generic-drop-down>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngSwitchCase="'Laptop'"
                    formGroupName="laptopFields"
                  >
                    <div class="dropdown">
                      <p class="label">Processor *</p>
                      <app-generic-drop-down
                        [options]="processors"
                        [title]="
                          addAssetForm.get('laptopFields')?.get('processor')
                            ?.value || 'Select'
                        "
                        placeholder="select a processor"
                        (value)="getProcessor($event)"
                        [shouldEmitOption]="true"
                      ></app-generic-drop-down>
                      <small
                        *ngIf="
                          addAssetForm.get('laptopFields')?.get('processor')
                            ?.dirty &&
                          addAssetForm.get('laptopFields')?.get('processor')
                            ?.errors
                        "
                        class="error-message"
                        >This field is mandatory</small
                      >
                    </div>
                    <div class="dropdown">
                      <p class="label">Operating System *</p>
                      <app-generic-drop-down
                        [options]="operatingSystems"
                        [title]="
                          this.addAssetForm
                            .get('laptopFields')
                            ?.get('operatingSystem')?.value || 'Select'
                        "
                        (value)="getOperatingSystem($event)"
                        [shouldEmitOption]="true"
                      ></app-generic-drop-down>
                      <small
                        *ngIf="
                          addAssetForm
                            .get('laptopFields')
                            ?.get('operatingSystem')?.dirty &&
                          addAssetForm
                            .get('laptopFields')
                            ?.get('operatingSystem')?.errors
                        "
                        class="error-message"
                        >This field is mandatory</small
                      >
                    </div>

                    <div class="field split-fields">
                      <p class="label">Screen Size *</p>
                      <div class="sub-fields">
                        <div class="sub-field">
                          <app-text-field
                            [required]="true"
                            for="screenSize"
                            id="screenSize"
                            type="number"
                            name="screenSize"
                            formControlName="screenSize"
                            label=""
                            placeholder="Enter Number"
                          >
                          </app-text-field>
                          <small
                            *ngIf="
                              (addAssetForm
                                .get('laptopFields')
                                ?.get('screenSize')?.dirty &&
                                addAssetForm
                                  .get('laptopFields')
                                  ?.get('screenSize')?.errors) ||
                              (addAssetForm
                                .get('laptopFields')
                                ?.get('screenSizeUnit')?.dirty &&
                                addAssetForm
                                  .get('laptopFields')
                                  ?.get('screenSizeUnit')?.errors)
                            "
                            class="error-message"
                            >This field is mandatory</small
                          >
                        </div>
                        <div class="unit-sub-field">
                          <app-generic-drop-down
                            [options]="screenSize"
                            [title]="getUnit('screenSizeUnit')"
                            titleName="unit"
                            valueName="id"
                            (value)="getScreeSizeValue($event)"
                            [shouldEmitEmittedValue]="true"
                          ></app-generic-drop-down>
                        </div>
                      </div>
                    </div>

                    <div class="field split-fields">
                      <p class="label">RAM *</p>
                      <div class="sub-fields">
                        <div class="sub-field">
                          <app-text-field
                            [required]="true"
                            for="ram"
                            id="ram"
                            type="number"
                            name="ram"
                            formControlName="ram"
                            label=""
                            placeholder="Enter Number"
                            [hasError]="
                              (addAssetForm.get('laptopFields')?.get('ram')
                                ?.dirty &&
                                addAssetForm.get('laptopFields')?.get('ram')
                                  ?.errors) ||
                              (addAssetForm.get('laptopFields')?.get('ramUnit')
                                ?.dirty &&
                                addAssetForm.get('laptopFields')?.get('ramUnit')
                                  ?.errors)
                            "
                          >
                          </app-text-field>
                          <small
                            *ngIf="
                              (addAssetForm.get('laptopFields')?.get('ram')
                                ?.dirty &&
                                addAssetForm.get('laptopFields')?.get('ram')
                                  ?.errors) ||
                              (addAssetForm.get('laptopFields')?.get('ramUnit')
                                ?.dirty &&
                                addAssetForm.get('laptopFields')?.get('ramUnit')
                                  ?.errors)
                            "
                            class="error-message"
                            >This field is mandatory</small
                          >
                        </div>
                        <div class="unit-sub-field">
                          <app-generic-drop-down
                            [options]="ram"
                            [title]="getUnit('ramUnit')"
                            titleName="unit"
                            valueName="id"
                            (value)="getRamValue($event)"
                            [shouldEmitEmittedValue]="true"
                          ></app-generic-drop-down>
                        </div>
                      </div>
                    </div>
                    <div class="field split-fields">
                      <p class="label">Hard Drive *</p>
                      <div class="sub-fields">
                        <div class="sub-field">
                          <app-text-field
                            [required]="true"
                            for="hardDrive"
                            id="hardDrive"
                            type="number"
                            name="hardDrive"
                            formControlName="diskSpace"
                            label=""
                            placeholder="Enter Number"
                            [hasError]="
                              (addAssetForm
                                .get('laptopFields')
                                ?.get('diskSpace')?.dirty &&
                                addAssetForm
                                  .get('laptopFields')
                                  ?.get('diskSpace')?.errors) ||
                              (addAssetForm
                                .get('laptopFields')
                                ?.get('diskSpaceUnit')?.dirty &&
                                addAssetForm
                                  .get('laptopFields')
                                  ?.get('diskSpaceUnit')?.errors)
                            "
                          >
                          </app-text-field>
                          <small
                            *ngIf="
                              (addAssetForm
                                .get('laptopFields')
                                ?.get('diskSpace')?.dirty &&
                                addAssetForm
                                  .get('laptopFields')
                                  ?.get('diskSpace')?.errors) ||
                              (addAssetForm
                                .get('laptopFields')
                                ?.get('diskSpaceUnit')?.dirty &&
                                addAssetForm
                                  .get('laptopFields')
                                  ?.get('diskSpaceUnit')?.errors)
                            "
                            class="error-message"
                            >This field is mandatory</small
                          >
                        </div>
                        <div class="unit-sub-field">
                          <app-generic-drop-down
                            [options]="hardDisks"
                            [title]="getUnit('diskSpaceUnit')"
                            titleName="unit"
                            valueName="id"
                            (value)="getDiskSpace($event)"
                            [shouldEmitEmittedValue]="true"
                          ></app-generic-drop-down>
                        </div>
                      </div>
                    </div>
                    <div class="field">
                      <p class="label">Serial No *</p>
                      <app-text-field
                        [required]="true"
                        for="serialNum"
                        id="serialNume"
                        type="text"
                        name="serialNum"
                        formControlName="serialNo"
                        label=""
                        placeholder="Enter Number"
                        [hasError]="
                          (addAssetForm.get('laptopFields')?.get('screenSize')
                            ?.dirty &&
                            addAssetForm.get('laptopFields')?.get('screenSize')
                              ?.errors) ||
                          (addAssetForm
                            .get('laptopFields')
                            ?.get('screenSizeUnit')?.dirty &&
                            addAssetForm
                              .get('laptopFields')
                              ?.get('screenSizeUnit')?.errors)
                        "
                      >
                      </app-text-field>
                      <small
                        *ngIf="
                          addAssetForm.get('laptopFields')?.get('serialNo')
                            ?.dirty &&
                          addAssetForm.get('laptopFields')?.get('serialNo')
                            ?.errors
                        "
                        class="error-message"
                        >This field is mandatory</small
                      >
                    </div>
                  </ng-container>
                  <ng-container
                    *ngSwitchCase="'Mobile Phone'"
                    formGroupName="mobileFields"
                  >
                    <div class="field">
                      <p class="label">Serial No *</p>
                      <app-text-field
                        [required]="true"
                        for="serialNum"
                        id="serialNume"
                        type="text"
                        name="serialNum"
                        formControlName="serialNo"
                        label=""
                        placeholder="Enter Serial No"
                        [hasError]="
                          addAssetForm.get('mobileFields')?.get('serialNo')
                            ?.dirty &&
                          addAssetForm.get('mobileFields')?.get('serialNo')
                            ?.errors
                        "
                      >
                      </app-text-field>
                      <small
                        *ngIf="
                          addAssetForm.get('mobileFields')?.get('serialNo')
                            ?.dirty &&
                          addAssetForm.get('mobileFields')?.get('serialNo')
                            ?.errors
                        "
                        class="error-message"
                        >This field is mandatory</small
                      >
                    </div>
                    <div class="field split-fields">
                      <p class="label">RAM *</p>
                      <div class="sub-fields">
                        <div class="sub-field">
                          <app-text-field
                            [required]="true"
                            for="ram"
                            id="ram"
                            type="number"
                            name="ram"
                            formControlName="ram"
                            label=""
                            placeholder="Enter Number"
                            [hasError]="
                              (addAssetForm.get('mobileFields')?.get('ram')
                                ?.dirty &&
                                addAssetForm.get('mobileFields')?.get('ram')
                                  ?.errors) ||
                              (addAssetForm.get('mobileFields')?.get('ramUnit')
                                ?.dirty &&
                                addAssetForm.get('mobileFields')?.get('ramUnit')
                                  ?.errors)
                            "
                          >
                          </app-text-field>
                          <small
                            *ngIf="
                              (addAssetForm.get('mobileFields')?.get('ram')
                                ?.dirty &&
                                addAssetForm.get('mobileFields')?.get('ram')
                                  ?.errors) ||
                              (addAssetForm.get('mobileFields')?.get('ramUnit')
                                ?.dirty &&
                                addAssetForm.get('mobileFields')?.get('ramUnit')
                                  ?.errors)
                            "
                            class="error-message"
                            >This field is mandatory</small
                          >
                        </div>
                        <div class="unit-sub-field">
                          <app-generic-drop-down
                            [options]="ram"
                            [title]="getUnit('ramUnit', 'mobileFields')"
                            titleName="unit"
                            valueName="id"
                            (value)="getRamValue($event)"
                            [shouldEmitEmittedValue]="true"
                          ></app-generic-drop-down>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngSwitchCase="'Monitor'"
                    formGroupName="monitorFields"
                  >
                    <div class="field">
                      <p class="label">Serial No *</p>
                      <app-text-field
                        [required]="true"
                        for="serialNo"
                        id="serialNo"
                        type="text"
                        name="serialNo"
                        formControlName="serialNo"
                        label=""
                        placeholder="Enter Serial No"
                        [hasError]="
                          addAssetForm.get('monitorFields')?.get('serialNo')
                            ?.dirty &&
                          addAssetForm.get('monitorFields')?.get('serialNo')
                            ?.errors
                        "
                      >
                      </app-text-field>
                      <small
                        *ngIf="
                          addAssetForm.get('monitorFields')?.get('serialNo')
                            ?.dirty &&
                          addAssetForm.get('monitorFields')?.get('serialNo')
                            ?.errors
                        "
                        class="error-message"
                        >This field is mandatory</small
                      >
                    </div>
                  </ng-container>
                </div>
              </div>
              <hr *ngIf="selectedOption !== ''" />
              <div
                class="specifications-container"
                *ngIf="selectedOption !== ''"
              >
                <p class="title">Purchase Details:</p>
                <div class="specifications">
                  <div class="field">
                    <p class="label">Purchase Date *</p>

                    <app-text-field
                      [required]="true"
                      for="purchaseData"
                      id="purchaseData"
                      type="date"
                      [max]="maxDate"
                      formControlName="purchaseDate"
                      label=""
                      [value]="addAssetForm.value.purchaseDate"
                      placeholder="DD/MM/YYYY"
                      [hasError]="
                        addAssetForm.controls['purchaseDate'].dirty &&
                        addAssetForm.controls['purchaseDate'].errors
                      "
                    >
                      <small
                        *ngIf="
                          addAssetForm.controls['purchaseDate'].dirty &&
                          addAssetForm.controls['purchaseDate'].errors
                        "
                        class="error-message"
                        >This field is mandatory</small
                      >
                    </app-text-field>
                  </div>

                  <div class="field">
                    <p class="label">Vendor Name *</p>
                    <app-generic-drop-down
                      [options]="vendorNames"
                      [title]="
                        addAssetForm.get('vendorName')?.value || 'Select'
                      "
                      placeholder="select vendor name "
                      (value)="getVendorName($event)"
                      [shouldEmitOption]="true"
                    ></app-generic-drop-down>
                    <small
                      *ngIf="
                        addAssetForm.get('vendorName')?.dirty &&
                        addAssetForm.get('vendorName')?.errors
                      "
                      class="error-message"
                      >This field is mandatory</small
                    >
                  </div>
                  <div class="field">
                    <p class="label">Warranty Tenure *</p>
                    <app-generic-drop-down
                      [options]="warranty"
                      [title]="
                        addAssetForm.get('warranty')?.value
                          ? addAssetForm.get('warranty')?.value + ' Days'
                          : 'Select'
                      "
                      placeholder="Select"
                      (value)="getWarranty($event)"
                      [shouldEmitOption]="true"
                    ></app-generic-drop-down>
                  </div>
                </div>
                <hr />
                <div class="specifications">
                  <div
                    class="footer-element"
                    *ngIf="selectedOption === 'Laptop'"
                    formGroupName="laptopFields"
                  >
                    <p class="title">Security Details:</p>
                    <div>
                      <div class="dropdown">
                        <p class="label">End Point Security *</p>
                        <app-generic-drop-down
                          [options]="endPointSecurityOptions"
                          titleName="securityManager"
                          valueName="id"
                          [title]="
                            getEndPointSecurityName('endPointSecurityId')
                          "
                          (value)="getEndPointSecurityId($event)"
                          [shouldEmitEmittedValue]="true"
                        ></app-generic-drop-down>
                        <small
                          *ngIf="
                            addAssetForm
                              .get('laptopFields')
                              ?.get('endPointSecurityId')?.dirty &&
                            addAssetForm
                              .get('laptopFields')
                              ?.get('endPointSecurityId')?.errors
                          "
                          class="error-message"
                          >This field is mandatory</small
                        >
                      </div>
                    </div>
                  </div>

                  <div class="footer-element">
                    <p class="title">Location Details:</p>
                    <div>
                      <div class="dropdown">
                        <p class="label">Location *</p>
                        <app-generic-drop-down
                          [options]="locations"
                          [title]="
                            addAssetForm.get('location')?.value || 'Select'
                          "
                          (value)="getLocation($event)"
                          [shouldEmitOption]="true"
                        ></app-generic-drop-down>
                        <small
                          *ngIf="
                            addAssetForm.get('laptopFields')?.get('location')
                              ?.dirty &&
                            addAssetForm.get('laptopFields')?.get('location')
                              ?.errors
                          "
                          class="error-message"
                          >This field is mandatory</small
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="button" *ngIf="!assetId">
        <app-button
          class="spacing"
          type="submit"
          size="medium"
          theme="primary"
          [disabled]="addAssetForm.invalid"
          (click)="onSubmit()"
          >Add
        </app-button>
      </div>
      <div class="button" *ngIf="!loaderView && !hasError && assetId">
        <app-button
          class="spacing"
          type="submit"
          [disabled]="!hasChange || addAssetForm.invalid"
          size="medium"
          theme="primary"
          (click)="onEditFormSubmit()"
          >Update
        </app-button>
      </div>
    </div>
  </div>
</div>
