import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ASSET_STATUS_OPTIONS } from '@common/interfaces';
@Component({
  selector: 'app-generic-confirmation-pop-up',
  templateUrl: './generic-confirmation-pop-up.component.html',
  styleUrls: ['./generic-confirmation-pop-up.component.scss'],
})
export class GenericConfirmationPopUpComponent implements OnInit {
  header = '';
  description = '';
  primaryBtn = '';
  secondaryBtn!: string;
  image = '';
  imageAddress = '';
  successImage = 'assets/48x48/Featured iconGreen-tick.svg';
  failureImage = 'assets/48x48/Featured iconred-exclamatory.svg';
  cautionImage = 'assets/48x48/logoutyellow-excalmatory.svg';
  options = ASSET_STATUS_OPTIONS;
  selectedOptionIndex: number = 0;
  displayCaption: boolean = false;
  hasFormGroup: boolean = false;
  placeholder = "Enter reason..."
  countData : any;
  isDropDownSelected : boolean = false;

  // DisableorEwasteForm = new FormGroup({
  //   dropDownValue : new FormControl(''),
  //   textArea : new FormControl('')
  // })
  textArea = new FormControl("",Validators.required)
  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA)
    public data: {
      header: string;
      description: string;
      primaryBtn: string;
      secondaryBtn: string;
      image: string;
      assetStatusOption?: string;
    }
  ) { }
  ngOnInit(): void {
    this.header = this.data.header;
    this.description = this.data.description;
    this.primaryBtn = this.data.primaryBtn;
    this.secondaryBtn = this.data.secondaryBtn;
    this.image = this.data.image;
    if (this.image === 'successImage') {
      this.imageAddress = this.successImage;
    } else if (this.image === 'failureImage') {
      this.imageAddress = this.failureImage;
    } else if (this.image === 'cautionImage') {
      this.imageAddress = this.cautionImage;
    }
  }
  onClick(buttonText: string) {
    const data = this.selectedOptionIndex
      ? { buttonText, index: 1 , transactionReason: this.textArea.value}
      : {buttonText, index: 0, transactionReason: this.textArea.value};
    this.dialogRef.close(data);
  }
  closeDialog() {
    this.dialogRef.close();
  }
  getIndex($event: number) {
    this.selectedOptionIndex = $event;
    $event === 1 || $event === 0 ? this.isDropDownSelected = true : this.isDropDownSelected = false;
    
    if ($event === 1) {
      this.displayCaption = true;
      this.placeholder="Mention the reason for E-waste"
    }
    else {
      this.displayCaption = false;
      this.placeholder="Mention the reason for disable"
    }
  }

  displayCount(value: any) {
    this.countData = value;
  }
}
