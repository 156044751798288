import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ILoginResponse, ROUTES } from '@common/interfaces';
import { SignInService } from '../sign-in/sign.in.service';
import { ITestSignIn } from './test-sign-in.interface';

@Component({
  selector: 'lib-test-sign-in',
  templateUrl: './test-sign-in.component.html',
  styleUrls: ['./test-sign-in.component.scss'],
})
export class TestSignInComponent implements OnInit {
  constructor(private signInService: SignInService, private router: Router) { }

  ngOnInit(): void { }
  testSignInForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required]),
    firstName: new UntypedFormControl('', [Validators.required]),
    lastName: new UntypedFormControl('', [Validators.required]),
  });

  login() {
    const formData: ITestSignIn = {
      firstName: this.testSignInForm.get('firstName')?.value,
      lastName: this.testSignInForm.get('lastName')?.value,
      email: this.testSignInForm.get('email')?.value,
    };
    this.loginUser(formData.firstName, formData.lastName, formData.email);
  }
  loginUser(firstname: string, lastname: string, email: string) {
    const data = { firstName: firstname, lastName: lastname, email: email };
    this.signInService.apiSignIn(data).subscribe(
      (response: ILoginResponse) => {
        const { token, userId } = response.data;
        if (token) {
          const userData = {
            ...data,
            roles: response.data.roles,
          };
          localStorage.setItem('userInfo', JSON.stringify(userData));
          localStorage.setItem('token', token || '');
          localStorage.setItem('userId', userId || '');
          const redirect =
            response.data.roles[0] === 'USER'
              ? ROUTES.USER_DASHBOARD
              : ROUTES.ASSET_TEAM_DASHBOARD;
          this.router.navigate([redirect]);
        }
      },
      (err) => { }
    );
  }
}
