import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEventType,
  HttpRequest,
} from '@angular/common/http';
import {
  getAllAdmins,
  raiseTicket,
} from 'libs/common/src/lib/urls/urls';
import { catchError, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormServiceService {
  constructor(private http: HttpClient) { }

  submitContactAdminForm(contactAdminFormData: any): any {
    const req = new HttpRequest('POST', raiseTicket, contactAdminFormData, {
      reportProgress: true,
      responseType: 'json',
    });
    return this.http.request(req).pipe(
      map((response) => {
        if (response.type === HttpEventType.Response) {
          return response.body;
        }
        return null;
      }),
      catchError((error: any) => {
        throw error;
      })
    );

  }

  getAdmins() {
    return this.http.get(`${getAllAdmins}`);
  }
}
