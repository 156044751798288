import { createReducer, on, props } from '@ngrx/store';
import { maintainFilterType, maintainSearchInput, reset } from './search.action';
import { searchState } from './search.state';
import { select } from '@ngrx/store';
const searchFeatureKey = 'search';
let state = searchState;
const searchReducer = createReducer(searchState,
  on(maintainSearchInput, (state, { searchStr }) => {
    return {
      ...state,
      searchStr
    }
  }),
  on(maintainFilterType, (state, { filterBy }) => {
    return {
      ...state,
      filterBy
    }
  }),
  on(reset, () => {
    return {
      ...state
    };
  })
)
export const searchStateReducer = {
  searchFeatureKey,
  searchReducer
}

