import { Component, OnInit ,Input} from '@angular/core';
import { IErrorConfig } from '@common/interfaces';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  constructor() { }
  @Input() errorConfig!:IErrorConfig;
  ngOnInit(): void {
  }

}
