import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ɵresetJitOptions,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss'],
})
export class CheckBoxComponent implements OnInit {
  @Output() options: EventEmitter<string[]> = new EventEmitter();

  @Input() item: any;

  choices: string[] = [];

  constructor() {}

  ngOnInit(): void {}

  onCheckBoxChange(event: any) {
    if (event.target.checked === true) {
      this.choices.push(event.target.value);
      this.options.emit(this.choices);
    } else {
      const indexOfUncheckedElement = this.choices.indexOf(event.target.value);
      this.choices.splice(indexOfUncheckedElement, 1);
      this.options.emit(this.choices);
    }
  }
}
