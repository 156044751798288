import {
  Directive,
  HostListener,
  Input,
  OnInit,
  ElementRef,
  Renderer2,
  Output,
  EventEmitter,
} from '@angular/core';

@Directive({
  selector: '[characterCounter]',
  inputs: ['limit:charCount'],
})
export class CharacterCounterDirective implements OnInit {
  remainingCharacters!: number;
  inputTag!: string;
  maxLength: number = 255;
  count: number = 0;
  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.inputTag = this.elementRef.nativeElement.tagName;
    if (this.inputTag !== 'INPUT') {
      this.maxLength = this.elementRef.nativeElement.getAttribute('maxlength');
    }
  }

  ngDoCheck() {
    if (this.inputTag === 'INPUT') {
      this.count = this.elementRef.nativeElement.value.length;
    } else {
      this.count =
        this.elementRef.nativeElement.querySelector('.text-area').value.length;
    }
    this.updateCounts();
  }
  @HostListener(':input')
  onInput() {
    this.updateCounts();
  }

  updateCounts() {
    if (this.maxLength >= this.count) {
      this.remainingCharacters = this.maxLength - this.count;
    }
    const countEleRef = this.elementRef.nativeElement.querySelector('.count');
    if (this.remainingCharacters) {
      // this.countEvent.emit([this.count, this.maxLength]);
      if (countEleRef && this.count >= 0) {
        countEleRef.textContent = `${this.count} / ${this.maxLength}`;
      }
    } else {
      // this.countEvent.emit([this.maxLength, this.maxLength]);
      countEleRef.textContent = `${this.maxLength} / ${this.maxLength}`;
    }
  }
}
