<div class="header width-100">
  <app-header></app-header>
</div>
<div class="content-area width-100 content-background">
  <div class="sidenav">
    <app-sidenav
      [navigationOption]="userNavigation"
      (employeeDetailsNavigation)="goToEmployeeDetails($event)"
    ></app-sidenav>
  </div>
  <div class="content width-100">
    <router-outlet class="route"></router-outlet>
  </div>
</div>
