<div class="main-container">
  <div class="form-container">
    <div class="heading-support">
      <p class="heading">Describe Your Issue In Detail</p>
      <p class="supporting-text">You can reach us anytime via</p>
      <p class="supporting-text">it-support@coditas.com</p>
    </div>
    <div>
      <p class="note-text">Note - Fields marked with * are mandatory.</p>
    </div>

    <form
      class="form"
      [formGroup]="contactAdminForm"
      (ngSubmit)="submitContactAdminForm()"
    >
      <div>
        <app-text-field
          [required]="true"
          for="username"
          id="username"
          type="text"
          name="username"
          formControlName="username"
          label=""
          placeholder="John Doe"
          [hasError]="usernameHasError"
        ></app-text-field>
        <small *ngIf="usernameHasError" class="error-message"
          >Please enter a valid username</small
        >
      </div>
      <div>
        <label for="" class="label">Email Address *</label>
        <app-text-field
          [required]="true"
          for="email"
          id="email"
          type="email"
          name="email"
          formControlName="email"
          label=""
          placeholder="email@address.com"
          [hasError]="emailHasError"
        >
        </app-text-field>
        <small *ngIf="emailHasError && !emailHasCoditasError" class="error-message">
          Please enter valid email ID
        </small>
        <small *ngIf="emailHasCoditasError" class="error-message">
          If you are a coditas user, please login into the system
        </small>
      </div>
      <div>
        <label for="" class="label">Mobile Number *</label>
        <div class="mobile-field">
          <app-generic-drop-down
            [disabled]="true"
            title="+91"
            class="drop-down-container"
          ></app-generic-drop-down>
          <app-text-field
            [required]="true"
            for="mobile"
            id="mobile"
            type="number"
            maxlength="10"
            name="mobile"
            formControlName="mobile"
            label=""
            placeholder="99999 99999"
            class="width-100"
            [hasError]="mobileHasError"
          >
          </app-text-field>
        </div>
        <small *ngIf="mobileHasError" class="error-message"
          >Please enter valid mobile number</small
        >
      </div>
      <select class="hidden-drop-down" formControlName="location">
        <option value="hidden-select"></option>
      </select>
      <div>
        <label class="label">Select Location *</label>
        <app-generic-drop-down
          [options]="locations"
          title="Select Location"
          (value)="getValue($event, 'location')"
          (index)="getIndex($event)"
          titleName="locationValue"
          valueName="locationKey"
          [hasCaption]="false"
          [shouldEmitdisplayValue]="true"
        ></app-generic-drop-down>
      </div>
      <select class="hidden-drop-down" formControlName="option">
        <option value="hidden-select"></option>
      </select>
      <div>
        <label class="label">Select Asset *</label>
        <app-generic-drop-down
          [options]="options"
          title="Select Asset"
          (value)="getValue($event, 'option')"
          (index)="getIndex($event)"
          titleName="assetName"
          [shouldEmitIndex]="true"
          [shouldEmitdisplayValue]="true"
        ></app-generic-drop-down>
      </div>
      <select class="hidden-drop-down" formControlName="subCategories">
        <option value="hidden-select"></option>
      </select>
      <div>
        <label class="label">Select Issue *</label>
        <app-drop-down
          from="select_an_issue"
          [options]="subCategoryOptions"
          titleName="subCategory"
          valueName="id"
          title="Select Type of Issue"
          formArrayName="subCategories"
          (value)="getValue($event, 'subCategory')"
          (index)="getIndex($event)"
          [isDropDownofSelectIssue]="true"
          [disabled]="!isAssetSelected"
        ></app-drop-down>
        <p class="select-asset-remainder" *ngIf="!isAssetSelected">
          Please select the asset
        </p>
      </div>
      <div>
        <label for="" class="label">Asset Code *</label>
        <app-text-field
          [required]="true"
          [disabled]="!isAssetSelected"
          for="asset_details"
          id="asset_details"
          type="text"
          name="asset_details"
          formControlName="asset_details"
          [assetCode]="true"
          label=""
          placeholder="CSL-L-00-00-L-0000"
          [hasError]="assetDetailsHasError"
        >
        </app-text-field>
        <small
          class="error-message"
          *ngIf="assetDetailsHasError && !assetCodeErrorMsg"
        >
          This field is mandatory
        </small>
        <small class="error-message" *ngIf="assetCodeErrorMsg">{{
          assetCodeErrorMsg
        }}</small>
      </div>

      <div class="issue">
        <label for="" class="label">Describe Your Issue *</label>
        <textarea
          name="issue"
          id="issue"
          cols="30"
          rows="10"
          class="text-area"
          placeholder="Tell us little more about the issue..."
          formControlName="issue"
          [ngClass]="{ 'has-error': issueHasError }"
        ></textarea>
        <small *ngIf="issueHasError" class="error-message"
          >Please describe the issue before continuing</small
        >
      </div>

      <div class="issue">
        <label for="" class="label">Attach a File (Optional)</label>
        <div>
          <app-file-upload
            (emitFileInfo)="selectedFiles($event)"
            formControlName="file"
            [multiple]="true"
            [hasImageAndVideo]="true"
            [fileTypesForValidations]="fileTypes"
          >
            <p>Image (JPG, under 2 MB, Up to 4 images)</p>
            <p>Video (MP4, under 20 MB, Up to 1 video)</p>
          </app-file-upload>
        </div>
      </div>
      <app-button
        class="spacing margin-top"
        type="submit"
        [disabled]="contactAdminForm.invalid || isDisable || uploadInProgress"
        size="xsmall"
        theme="primary"
        >Submit</app-button
      >
    </form>
  </div>
  <div class="image"></div>
</div>
