<div class="modal-header">
  <div class="modal-heading">
    <h1 class="modal-header-title">Upload List</h1>
    <p class="modal-header-subtitle">Upload list for specific category</p>
  </div>
  <button class="modal-header-action" type="button" (click)="closeDialog()">
    <img src="assets/24x24/close.svg" alt="" />
  </button>
</div>
<div class="form-container">
  <div class="modal-body max-height">
    <form class="popup-form" [formGroup]="popupForm">
      <select class="hidden-drop-down" formControlName="upload_list_type">
        <option value="hidden-select"></option>
      </select>
      <div class="dropdown-wrapper">
        <label class="label">List Category*</label>
        <app-generic-drop-down
          from="bulk_upload_list"
          [options]="options"
          title="Select List Category"
          (value)="getValue($event)"
          titleName="title"
          valueName="id"
          [shouldEmitEmittedValue]="true"
        ></app-generic-drop-down>
      </div>
      <label class="label">Attachment*</label>
      <app-file-upload
        [maxFileSizeInKB]="10240"
        (emitFileInfo)="selectedFiles($event)"
        formControlName="files"
        [multiple]="false"
        [fileTypesForValidations]="fileTypes"
      >
        .CSV (File size should be under 10 MB)
      </app-file-upload>
    </form>
  </div>
  <div class="modal-footer">
    <div class="button">
      <app-button
      class="spacing"
      type="submit"
      [disabled]="popupForm.invalid  || uploadInProgress"
      size="xsmall"
      theme="primary"
        (click)="onSubmit()"
      >
        Upload Files
      </app-button>
    </div>
    <div class="button cancel-btn">
      <app-button
        class="width-100"
        size="xsmall"
        theme="secondary"
        (click)="close()"
        >Cancel
      </app-button>
    </div>
  </div>
</div>
