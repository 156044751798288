<div class="ticket">
  <div class="header">
    <p class="ticket-heading">Generate Ticket</p>
  </div>
  <!-- <div class="col width-100" [@basicAnimation]>
    <app-button
      class="width-100"
      size="xsmall"
      theme="primary"
      [disabled]="assetCount <= 0"
      (click)="open('raise-ticket-modal')"
      >Report an Issue
    </app-button>
    <app-button
      class="width-100"
      size="xsmall"
      theme="secondary"
      (click)="open('request-asset-modal')"
      >Request an Asset
    </app-button>
  </div> -->
  <div [@basicAnimation] class="ticket-dropdown">
    <app-generic-drop-down
      [options]="generateTicketOptions"
      [title]="generateTicketOptions[0]"
      [shouldEmitOption]="true"
      (value)="getSelectedOption($event)"
    ></app-generic-drop-down>
  </div>

  <app-button
    class="width-100"
    size="xsmall"
    theme="primary"
    (click)="openModal()"
    >Generate
  </app-button>
</div>
