import { DialogRef } from '@angular/cdk/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  DOWNLOAD_LIST_OPTIONS,
  DOWNLOAD_LIST_TYPES,
} from 'libs/common/src/lib/constants/constants';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bulk-download-list',
  templateUrl: './bulk-download-list.component.html',
  styleUrls: ['./bulk-download-list.component.scss'],
})
export class BulkDownloadListComponent implements OnInit {
  constructor(
    private commonHttpService: CommonHttpService,
    private dialogRef: DialogRef,
    private toasterService: ToastrService
  ) {}

  ngOnInit(): void {}

  @Output() closeModal = new EventEmitter();
  @Output() alertBoxEmitter = new EventEmitter();

  popupForm = new UntypedFormGroup({
    list_type_options: new UntypedFormControl('', Validators.required),
    list_category_options: new UntypedFormControl('', Validators.required),
  });

  categoryOptions = DOWNLOAD_LIST_OPTIONS;
  listTypeOptions = DOWNLOAD_LIST_TYPES;
  listCategoryOptions = DOWNLOAD_LIST_OPTIONS;
  showRemoveFileBtn = true;
  progressInfos: any[] = [];
  uploadFailed = false;
  isLoading = false;

  private downloadDatabaseFile(url: string, urlOf: string) {
    const databaseFileDownloadEl = document.createElement('a');
    databaseFileDownloadEl.href = url;
    databaseFileDownloadEl.target = '_blank';
    databaseFileDownloadEl.download = `${urlOf}-${new Date().getTime()}.xlsx`;
    databaseFileDownloadEl.click();
  }

  getValue(selectedValue: string, type?: string) {
    if (type === 'list_type_options') {
      this.popupForm.get('list_category_options')?.setValue('');
      this.categoryOptions = this.listCategoryOptions.filter(
        (value) => value.fileType === selectedValue || value.fileType === 'all'
      );
    }
    this.popupForm.get(type!)?.setValue(selectedValue);
  }

  closeDialog() {
    this.popupForm.reset();
    this.dialogRef.removePanelClass('download-upload-dialog');
    this.dialogRef.addPanelClass('close-upload-download-dialog');
    const current = this;
    setTimeout(() => {
      current.dialogRef.close();
    }, 1000);
  }

  onSubmit() {
    this.isLoading = !this.isLoading;

    if (this.popupForm.value.list_type_options === 'database_file') {
      if (this.popupForm.value.list_category_options === 'asset_list') {
        this.downloadAndClose('asset-list');
      } else if (
        this.popupForm.value.list_category_options === 'asset_allocation_list'
      ) {
        this.downloadAndClose('asset-allocation-list');
      } else if (this.popupForm.value.list_category_options === 'user_list') {
        this.downloadAndClose('user-list');
      }
    } else if (this.popupForm.value.list_type_options === 'template_file') {
      switch (this.popupForm.value.list_category_options) {
        case 'asset_list':
          this.downloadAndClose('asset_list-template');
          break;
        case 'asset_allocation_list':
          this.downloadAndClose('asset_allocation_list-template');
          break;
        case 'asset_out_for_repair':
          this.downloadAndClose('asset_out_for_repair-template');
          break;
        case 'asset_update_list':
          this.downloadAndClose('asset_update_list-template');
          break;
        case 'mark_asset_e_waste':
          this.downloadAndClose('mark_asset_e_waste-template');
          break;
        default:
          this.isLoading = !this.isLoading;
          break;
      }
    }
  }

  downloadAndClose(fileName: string) {
    const downloadFunction =
      this.popupForm.value.list_type_options === 'database_file'
        ? this.getDownloadFunctionByCategory()
        : this.getTemplateDownloadFunctionByCategory();

    if (downloadFunction) {
      downloadFunction.subscribe({
        next: (response: any) => {
          this.isLoading = !this.isLoading;
          const fileUrl = this.getFileUrlFromResponse(response);
          this.downloadDatabaseFile(fileUrl, fileName);
          this.closeDialog();
        },
        error: (error: HttpErrorResponse) => {
          this.toasterService.error(
            `${fileName} file could not be downloaded, try later.`,
            'Download failed!',
            { toastClass: 'toaster-wrapper' }
          );
          this.isLoading = !this.isLoading;
        },
      });
    }
  }

  getDownloadFunctionByCategory() {
    const category = this.popupForm.value.list_category_options;
    switch (category) {
      case 'asset_list':
        return this.commonHttpService.downloadAssetListDatabaseFile();
      case 'asset_allocation_list':
        return this.commonHttpService.downloadAssetAllocationListDatabaseFile();
      case 'user_list':
        return this.commonHttpService.downloadUserListDatabaseFile();
      default:
        return null;
    }
  }

  getTemplateDownloadFunctionByCategory() {
    const category = this.popupForm.value.list_category_options;
    switch (category) {
      case 'asset_list':
        return this.commonHttpService.downloadAssetListTemplateFile();
      case 'asset_allocation_list':
        return this.commonHttpService.downloadAssetAllocationListTemplateFile();
      case 'asset_out_for_repair':
        return this.commonHttpService.downloadAssetOutForRepairListTemplateFile();
      case 'asset_update_list':
        return this.commonHttpService.downloadAssetUpdateListTemplateFile();
      case 'mark_asset_e_waste':
        return this.commonHttpService.downloadAssetsMarkedAsEWaste();
      default:
        return null;
    }
  }

  getFileUrlFromResponse(response: {
    downloadUrl?: string;
    data?: string;
  }): string {
    let downloadUrl = response.data || response.downloadUrl;
    const isHttps = downloadUrl?.startsWith('https://');
    if (!isHttps) {
      downloadUrl = `https:${downloadUrl?.split(':')[1]}`;
    }
    return downloadUrl!;
  }

  getCategoryOptionById(categoryId: string) {
    const matchingOption = this.listCategoryOptions.find(
      (option) => option.id === categoryId
    );
    return matchingOption ? matchingOption.title : null;
  }
}
