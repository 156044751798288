import { Location } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ROUTES } from '@common/interfaces';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { filter } from 'rxjs';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {
  constructor(
    public location: Location,
    private router: Router,
    private commonService: CommonService
  ) {}
  @Input() backRouteLink?: { path: string; params: any };

  ngOnInit(): void {}

  goBack() {
    this.backRouteLink
      ? this.router.navigateByUrl(
          this.commonService.buildURL(
            this.backRouteLink.path,
            this.backRouteLink.params
          )
        )
      : this.location.back();
  }
}
