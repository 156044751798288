import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UploadDownloadService {

  isAllFailed !: boolean;
  uploadCounts: any[] = [];
  constructor(private datePipe: DatePipe) { }
  destructureDownloadTicketList(data: Array<any>) {
    if (data.length === 0) {
      return '';
    }

    let rows: string[] = [];

    let propertyObject = [
      {
        propertyName: 'Ticket Number',
        propertyKey: 'ticketNumber',
      },
      {
        propertyName: 'Ticket Category',
        propertyKey: 'title',
      },
      {
        propertyName: 'Ticket Status',
        propertyKey: 'status',
      },
      {
        propertyName: 'External User Name',
        propertyKey: 'externalUserName',
      },
      {
        propertyName: 'External User Email',
        propertyKey: 'externalUserEmail',
      },
      {
        propertyName: 'Employee Email Id',
        propertyKey: 'users.email',
      },
      {
        propertyName: 'Employee ID',
        propertyKey: 'users.employeeId',
      },
      {
        propertyName: 'Employee First Name',
        propertyKey: 'users.firstName',
      },
      {
        propertyName: 'Employee Last Name',
        propertyKey: 'users.lastName',
      },
      {
        propertyName: 'Assignee Employee Id',
        propertyKey: 'assignee.employeeId',
      },
      {
        propertyName: 'Assignee Email',
        propertyKey: 'assignee.email',
      },
      {
        propertyName: 'Assignee First Name',
        propertyKey: 'assignee.firstName',
      },
      {
        propertyName: 'Assignee Last Name',
        propertyKey: 'assignee.lastName',
      },
      {
        propertyName: 'New Asset Code',
        propertyKey: 'newAsset.assetCode',
      },
      {
        propertyName: 'New Asset Name',
        propertyKey: 'newAsset.name',
      },
      {
        propertyName: 'Reported Asset Code',
        propertyKey: 'ticketAssets.assets.assetCode',
      },
      {
        propertyName: 'Reported Asset Name',
        propertyKey: 'ticketAssets.assets.name',
      },
      {
        propertyName: 'Reported Asset Status',
        propertyKey: 'ticketAssets.assets.status',
      },
      {
        propertyName: 'Created At',
        propertyKey: 'createdAt'
      },
      {
        propertyName: 'Description',
        propertyKey: 'description'
      },
      {
        propertyName: 'Ticket Completion Reason',
        propertyKey: 'resolution'
      }
    ];
    data.forEach((item) => {
      let values: string[] = [];
      propertyObject.forEach((property) => {
        let key = property.propertyKey;

        if (key === 'ticketAssets.assets.assetCode') {
          let val: any = item['ticketAssets.assets.assetCode'];
          if (val) {
            val = new String(val);
          } else {
            val = '';
          }
          values.push(val);
        } else if (key === 'ticketAssets.assets.name') {
          let val: any = item['ticketAssets.assets.name'];
          if (val) {
            val = new String(val);
          } else {
            val = '';
          }
          values.push(val);
        } else if (key === 'ticketAssets.assets.status') {
          let val: any = item['ticketAssets.assets.status'];
          if (val) {
            val = new String(val);
          } else {
            val = '';
          }
          values.push(val);
        }
        else if (key === 'createdAt') {
          let val: any = new DatePipe('en-us').transform(
            item['createdAt'],
            'dd/MM/yyyy'
          );
          if (val) {
            val = new String(val);
          } else {
            val = '';
          }
          values.push(val);
        }
        else if (key === 'description') {
          let val: any = item[key];
          let value = ``;
          if (val.includes(',')) {
            value = `"${val}"`
            values.push(value);
          }
          else {
            let val: any = item[key];
            if (val) {
              val = new String(val);
            } else {
              val = '';
            }
            values.push(val)
          }

        }
        else {
          let val: any = item[key];
          if (val) {
            val = new String(val);
          } else {
            val = '';
          }
          values.push(val);
        }
      });
      rows.push(values.join(','));
    });

    let properties: string[] = [];
    propertyObject.forEach((property) => {
      properties.push(property.propertyName);
    });
    let rowWithPropertyNames = properties.join(',') + '\n';
    let csvContent = rowWithPropertyNames;
    csvContent += rows.join('\n');
    return csvContent;
  }

  public destructureAssetList(data: Array<any>) {
    if (data.length === 0) {
      return '';
    }
    let rows: string[] = [];
    let propertyObject = [
      {
        propertyName: 'Employee ID',
        propertyKey: 'asset_allocations.user.employeeId',
      },
      { propertyName: 'Asset Type', propertyKey: 'name' },
      { propertyName: 'Asset Name', propertyKey: 'modelName' },
      { propertyName: 'Asset Status', propertyKey: 'status' },
      { propertyName: 'Asset Code', propertyKey: 'assetCode' },
      { propertyName: 'Vendor Name', propertyKey: 'vendorName' },
      { propertyName: 'Purchase Date', propertyKey: 'purchaseDate' },
      { propertyName: 'Warranty Tenure(Days)', propertyKey: 'warranty' },
      {
        propertyName: 'Assignee Name',
        propertyKey: 'assigneeName',
      },
      {
        propertyName: 'Assignee Email',
        propertyKey: 'item.asset_allocations?.user?.email',
      },
      {
        propertyName: 'Assignee Designation',
        propertyKey: 'asset_allocations.user.designation',
      },
      {
        propertyName: 'Custody Verified',
        propertyKey: 'item.asset_allocations?.custodyVerified',
      },
      {
        propertyName: 'Check In By',
        propertyKey: 'checkInByEmail',
      },
      {
        propertyName: 'Check In At',
        propertyKey: 'checkInDay',
      },
      {
        propertyName: 'Check Out By',
        propertyKey: 'checkOutByEmail',
      },

      {
        propertyName: 'Check Out At',
        propertyKey: 'checkOutDay',
      },
      {
        propertyName: 'End Point Security',
        propertyKey: 'endPoint_security.securityManager',
      },

    ];
    data.forEach((item) => {
      let values: string[] = [];
      propertyObject.forEach((property) => {
        let key = property.propertyKey;

        if (key === 'item.asset_allocations?.user?.email') {
          let val: any = item?.['asset_allocations.user.email'];
          if (val) {
            val = new String(val);
          } else {
            val = '';
          }
          values.push(val);
        } else if (key === 'item.asset_allocations?.custodyVerified') {
          let val: any = item?.['asset_allocations.custodyVerified'];
          if (val) {
            val = new String(val);
          } else {
            val = '';
          }
          values.push(val);
        }
        else if (key === 'assigneeName') {
          let val: any = (item?.['asset_allocations.user.firstName'] || '') + ' ' + (item?.['asset_allocations.user.lastName'] || '');
          if (val) {
            val = new String(val);
          } else {
            val = '';
          }
          values.push(val);
        }
        else if (key === 'checkInDay') {
          let val: any;
          if (item?.['asset_allocations.checkedInBy.email']) {
            val = this.datePipe.transform(item?.['asset_allocations.createdAt'], 'dd/MM/yyyy');
          }
          if (val) {
            val = new String(val);
          } else {
            val = '';
          }
          values.push(val);
        }
        else if (key === 'checkOutDay') {
          let val: any;
          if (item?.['asset_allocations.checkedOutBy.email']) {
            val = this.datePipe.transform(item?.['asset_allocations.createdAt'], 'dd/MM/yyyy');
          }
          if (val) {
            val = new String(val);
          } else {
            val = '';
          }
          values.push(val);
        }
        else if (key === 'checkInByDay') {
          let val: any;
          if (item?.['asset_allocations.checkedInBy.email']) {
            val = this.datePipe.transform(item?.['asset_allocations.createdAt'], 'dd/MM/yyyy');
          }
          if (val) {
            val = new String(val);
          } else {
            val = '';
          }
          values.push(val);
        }
        else if (key === 'checkInByEmail') {
          let val: any = item?.['asset_allocations.checkedInBy.email'];

          if (val) {
            val = new String(val);
          } else {
            val = '';
          }
          values.push(val);
        }
        else if (key === 'checkOutByEmail') {
          let val: any = item?.['asset_allocations.checkedOutBy.email'];
          if (val) {
            val = new String(val);
          } else {
            val = '';
          }
          values.push(val);
        }
        else {
          let val: any = item[key];
          if (val) {
            val = new String(val);
          } else {
            val = '';
          }
          values.push(val);
        }
      });
      rows.push(values.join(','));
    });

    let properties: string[] = [];
    propertyObject.forEach((property) => {
      properties.push(property.propertyName);
    });

    let rowWithPropertyNames = properties.join(',') + '\n';
    let csvContent = rowWithPropertyNames;
    csvContent += rows.join('\n');
    return csvContent;
  }

  public saveDataInCSV(data: Array<any>): string {
    if (data.length === 0) {
      return '';
    }

    let propertyNames = Object.keys(data[0]);
    let rowWithPropertyNames = propertyNames.join(',') + '\n';

    let csvContent = rowWithPropertyNames;

    let rows: string[] = [];

    data.forEach((item) => {
      let values: string[] = [];

      propertyNames.forEach((key) => {
        let val: any = item[key];

        if (val) {
          val = new String(val);
        } else {
          val = '';
        }
        values.push(val);
      });
      rows.push(values.join(','));
    });
    csvContent += rows.join('\n');

    return csvContent;
  }

}
