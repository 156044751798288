import { CdkAccordionModule } from '@angular/cdk/accordion';
import { DialogModule } from '@angular/cdk/dialog';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AutoFocusDirective } from 'libs/common/src/lib/directives/auto-focus-directive.directive';
import { CharacterCounterDirective } from 'libs/common/src/lib/directives/character-counter.directive';
import { PermissionsDirective } from 'libs/common/src/lib/directives/permissions.directive';
import { TextareaAutoresizeDirective } from 'libs/common/src/lib/directives/textarea-autoresize.directive';
import { ShortDatePipe } from 'libs/common/src/lib/pipes/date.pipe';
import { FileUnitPipe } from 'libs/common/src/lib/pipes/file-unit.pipe';
import { ToastrModule } from 'ngx-toastr';
import { AddAssetModalComponent } from './add-asset-modal/add-asset-modal.component';
import { AddEditAlertModelComponent } from './add-edit-alert-model/add-edit-alert-model.component';
import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { AlertBoxComponent } from './alert-box/alert-box.component';
import { AssetInfoComponent } from './asset-info/asset-info.component';
import { AutoSuggestComponent } from './auto-suggest/auto-suggest.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { BulkDownloadListComponent } from './bulk-download-list/bulk-download-list.component';
import { BulkUploadAlertModalComponent } from './bulk-upload-alert-modal/bulk-upload-alert-modal.component';
import { BulkUploadListComponent } from './bulk-upload-list/bulk-upload-list.component';
import { ButtonComponent } from './button/button.component';
import { cardsComponent } from './card/card';
import { CheckBoxComponent } from './check-box/check-box.component';
import { ChipComponent } from './chip/chip.component';
import { DialogAlertDetailsComponent } from './dialog-alert-details/dialog-alert-details.component';
import { DropDownComponent } from './drop-down/drop-down.component';
import { EditTicketComponent } from './edit-ticket/edit-ticket.component';
import { EmptyPlaceholderComponent } from './empty-placeholder/empty-placeholder.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { GenerateTicketSectionComponent } from './generate-ticket-section/generate-ticket-section.component';
import { GenericConfirmationPopUpComponent } from './generic-confirmation-pop-up/generic-confirmation-pop-up.component';
import { GenericDropDownComponent } from './generic-drop-down/generic-drop-down.component';
import { GenericPopUpComponent } from './generic-pop-up/generic-pop-up.component';
import { HeaderComponent } from './header/header.component';
import { LoaderComponent } from './loader/loader.component';
import { ModalComponent } from './modal/modal.component';
import { OutForRepairModalComponent } from './out-for-repair-modal/out-for-repair-modal.component';
import { PaginationComponent } from './pagination/pagination.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ProgressInfoComponent } from './progress-info/progress-info.component';
import { RaiseTicketModalComponent } from './raise-ticket-modal/raise-ticket-modal.component';
import { RequestAssetModalComponent } from './request-asset-modal/request-asset-modal.component';
import { SearchSectionComponent } from './search-section/search-section.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { TableFilterComponent } from './table-filter/table-filter.component';
import { TableListComponent } from './table-list/table-list.component';
import { TableComponent } from './table/table.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { TextFieldComponent } from './text-field/text-field.component';
import { TextareaComponent } from './textarea/textarea.component';
import { TicketGenerateAlertModalComponent } from './ticket-generate-alert-modal/ticket-generate-alert-modal.component';
import { UserAssetsSectionComponent } from './user-assets-section/user-assets-section.component';
import { UserProfileDetailsComponent } from './user-profile-details/user-profile-details.component';
import { ViewTicketModalComponent } from './view-ticket-modal/view-ticket-modal.component';
import { UploadComponent } from './upload/upload.component';
import { CommentComponent } from './comment/comment.component';
import { AssetHistoryComponent } from './asset-history/asset-history.component';
import { StepperComponent } from './stepper/stepper.component';
import { ConfirmationPopupWithFormComponent } from './confirmation-popup-with-form/confirmation-popup-with-form.component';
import { CategoryComponent } from './category/category.component';
import { AccessInstallationModalComponent } from './access-installation-modal/access-installation-modal.component';
@NgModule({
  declarations: [
    ButtonComponent,
    TextFieldComponent,
    AutoSuggestComponent,
    DropDownComponent,
    AlertBoxComponent,
    ModalComponent,
    FileUploadComponent,
    CheckBoxComponent,
    TableComponent,
    SidenavComponent,
    HeaderComponent,
    cardsComponent,
    AssetInfoComponent,
    PaginationComponent,
    DialogAlertDetailsComponent,
    TableFilterComponent,
    TextareaComponent,
    UserProfileDetailsComponent,
    GenerateTicketSectionComponent,
    UserAssetsSectionComponent,
    RaiseTicketModalComponent,
    RequestAssetModalComponent,
    TicketGenerateAlertModalComponent,
    ViewTicketModalComponent,
    BulkUploadListComponent,
    BulkDownloadListComponent,
    FileUnitPipe,
    SearchSectionComponent,
    BulkUploadAlertModalComponent,
    AutoFocusDirective,
    ErrorPageComponent,
    AddAssetModalComponent,
    AddEditAlertModelComponent,
    LoaderComponent,
    ChipComponent,
    BackButtonComponent,
    PrivacyPolicyComponent,
    GenericDropDownComponent,
    TermsAndConditionsComponent,
    GenericConfirmationPopUpComponent,
    GenericPopUpComponent,
    EditTicketComponent,
    AdminHeaderComponent,
    CharacterCounterDirective,
    TextareaAutoresizeDirective,
    EmptyPlaceholderComponent,
    OutForRepairModalComponent,
    PermissionsDirective,
    UploadComponent,
    ProgressInfoComponent,
    CommentComponent,
    AssetHistoryComponent,
    StepperComponent,
    TableListComponent,
    ShortDatePipe,
    ConfirmationPopupWithFormComponent,
    CategoryComponent,
    AccessInstallationModalComponent,
  ],
  providers: [DatePipe],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    CdkTableModule,
    DialogModule,
    CdkAccordionModule,
    ToastrModule.forRoot(),
  ],
  exports: [
    DialogModule,
    ButtonComponent,
    TextFieldComponent,
    AutoSuggestComponent,
    DropDownComponent,
    AlertBoxComponent,
    ModalComponent,
    FileUploadComponent,
    CheckBoxComponent,
    TableComponent,
    HeaderComponent,
    AssetInfoComponent,
    SidenavComponent,

    cardsComponent,
    PaginationComponent,
    TableFilterComponent,
    DialogAlertDetailsComponent,
    TextareaComponent,
    UserProfileDetailsComponent,
    GenerateTicketSectionComponent,
    UserAssetsSectionComponent,
    RaiseTicketModalComponent,
    RequestAssetModalComponent,
    TicketGenerateAlertModalComponent,
    ViewTicketModalComponent,
    BulkUploadListComponent,
    BulkDownloadListComponent,
    BulkUploadAlertModalComponent,
    FileUnitPipe,
    AutoFocusDirective,
    BackButtonComponent,
    ErrorPageComponent,
    AddEditAlertModelComponent,
    LoaderComponent,
    ChipComponent,
    GenericConfirmationPopUpComponent,
    GenericDropDownComponent,
    AdminHeaderComponent,
    EmptyPlaceholderComponent,
    PermissionsDirective,
    AssetHistoryComponent,
    AccessInstallationModalComponent

  ],
})
export class UiComponentsModule { }
