<div class="checkbox-options">
  <input
    type="checkbox"
    id="{{ item }}"
    [value]="item.value"
    (change)="onCheckBoxChange($event)"
    class="checkbox-button"
  />
  <label for="{{ item.value }}" class="checkbox-text">{{ item.name }}</label>
</div>
