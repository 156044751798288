import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
@Directive({
  selector: '[stessaHasPermission]',
})
export class PermissionsDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}
  @Input() set stessaHasPermission(permissionKey: string) {
    //NOTE: Need to replace localStorage with store
    const permissions = JSON.parse(
      localStorage.getItem('permissions') as string
    ) as string[];
    const hasPermission = permissions
      ? permissions.includes(permissionKey)
      : false;
    if (hasPermission) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
