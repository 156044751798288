<ng-container *ngIf="tableDetails">
  <app-admin-header
    coditasRoute="/home/admin-ticket/coditas"
    personalRoute="/home/admin-ticket/personal"
    personalTabPermission="DASHBOARD__PERSONAL"
  ></app-admin-header>
  <div class="asset-team-container">
    <div class="dash-section-wrapper" [ngClass]="{ loader: loaderView }">
      <app-loader *ngIf="loaderView"></app-loader>
      <app-table
        [tableConfig]="tableConfig"
        [tableDetails]="tableDetails"
        [count]="count"
        [filterOptions]="filterOptions"
        [activeMenu]="pageSize"
        (activeFilter)="filterBy($event)"
        (paginatorEmitter)="getUserTickets($event)"
        (pageSizeChange)="pageSizeChange($event)"
        (tableNavigationClick)="navigateToTicket($event)"
        [displayTicketList]="true"
        (onSort)="handleSortAction($event)"
        class="width-100"
      >
      </app-table>
    </div>
  </div>
</ng-container>
