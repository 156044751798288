import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[libTextareaAutoresize]',
})
export class TextareaAutoresizeDirective {
  constructor(private elementRef: ElementRef) {}
  @HostListener(':input')
  onInput() {
    this.resize();
  }

  ngOnInit() {
    this.elementRef.nativeElement.querySelector('.text-area').style.overflow =
      'hidden';
    if (
      this.elementRef.nativeElement.querySelector('.text-area').scrollHeight
    ) {
      setTimeout(() => this.resize());
    }
    if (
      this.elementRef.nativeElement.querySelector('.text-area').style
        .scrollHeight > 120
    ) {
      this.elementRef.nativeElement.querySelector('.text-area').style.overflow =
        'auto';
    }
  }

  resize() {
    this.elementRef.nativeElement.querySelector('.text-area').style.height =
      '40px';
    this.elementRef.nativeElement.querySelector('.text-area').style.maxHeight =
      '120px';
    this.elementRef.nativeElement.querySelector('.text-area').style.height =
      this.elementRef.nativeElement.querySelector('.text-area').scrollHeight +
      'px';
  }
}
