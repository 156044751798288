import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ASSET_STATUS, IChipConfig, TICKET_STATUS } from '@common/interfaces';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent implements OnInit {
  @Input() status!: string;
  chipConfig!: IChipConfig;
  constructor() { }
  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['status'].currentValue) {
      switch (this.status) {
        case TICKET_STATUS.PENDING:
          this.chipConfig = {
            class: 'badge-assigned',
            title: 'To-Do',
          };
          break;
          case TICKET_STATUS.ON_HOLD:
          this.chipConfig = {
            class: 'badge-on-hold',
            title: 'On-Hold',
          };
          break;

        case TICKET_STATUS.ASSIGNED:
          this.chipConfig = {
            class: 'badge-ongoing',
            title: 'Ongoing',
          };
          break;

        case TICKET_STATUS.CLOSED:
          this.chipConfig = {
            class: 'badge-closed',
            title: 'Closed',
          };
          break;

        case ASSET_STATUS.AVAILABLE:
          this.chipConfig = {
            class: 'badge-available',
            title: 'Available',
          };
          break;

        case ASSET_STATUS.ALLOCATED:
          this.chipConfig = {
            class: 'badge-assigned',
            title: 'Assigned',
          };
          break;

        case ASSET_STATUS.DISABLED || ASSET_STATUS.MARK_AS_EWASTE:
          this.chipConfig = {
            class: 'disabled badge-disabled',
            title: 'Not in use',
          };
          break;

        case ASSET_STATUS.NOT_IN_USE:
          this.chipConfig = {
            class: 'disabled badge-disabled',
            title: 'Not in use',
          };
          break;
        case ASSET_STATUS.MARK_AS_EWASTE:
          this.chipConfig = {
            class: 'disabled badge-disabled',
            title: 'E-waste',
          };
          break;

        case ASSET_STATUS.OUT_FOR_REPAIR:
          this.chipConfig = {
            class: 'badge-out-for-repair',
            title: 'Out for Repair',
          };
          break;
        case ASSET_STATUS.IN_HOUSE_REPAIR:
          this.chipConfig = {
            class: 'badge-out-for-repair',
            title: 'In House Repair',
          };
          break;
        default:
          break;
      }
    }
  }
}
