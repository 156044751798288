import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IVerificationParams } from '@common/interfaces';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
})
export class VerificationComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private commonHttpService: CommonHttpService
  ) {}
  alertBoxConfig = {
    isOpen: false,
    status: 'none',
    heading: '',
    content: '',
    icon: '',
  };
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe({
      next: (queryParams) => {
        const verificationType = this.checkVerificationType();
        if (verificationType) {
          this.verify(verificationType, queryParams);
        }
      },
    });
  }

  checkVerificationType() {
    let verificationType = '';
    this.activatedRoute.params.subscribe({
      next: (params) => {
        verificationType = Object(params).type;
      },
    });
    return verificationType;
  }

  verify(type: string, queryParams: IVerificationParams) {
    if (type === 'update-approval-status') {
      const isDeclined = queryParams.approvalStatus === 'DECLINED';
      const loadRequest =
        this.commonHttpService.updateApprovalStatus(queryParams);
      loadRequest.subscribe({
        next: (response) => {
          this.alertBoxConfig = {
            isOpen: true,
            status: isDeclined ? 'fail' : 'success',
            heading: isDeclined
            // generic messages to be corrected with ticket id on next sprint
              ? 'Your Request has been Declined!'
              : 'Your Request has been Approved!',
            content: isDeclined
              ? 'Your request has been declined'
              : 'Your request has been approved',
            icon: isDeclined ? 'alert-circle.svg' : 'check-circle.svg',
          };
        },
        error: (errorResponse: HttpErrorResponse) => {
          const { error } = errorResponse;
          this.alertBoxConfig = {
            isOpen: true,
            status: 'fail',
            heading: 'Approval Request Failed!',
            content: error.error?.errorList || 'Something went wrong, try again later',
            icon: 'alert-circle.svg',
          };
        },
      });
    } else if (type === 'update-custody-status') {
      const loadRequest =
        this.commonHttpService.updateCustodyStatus(queryParams);
      loadRequest.subscribe({
        next: (response) => {
          this.alertBoxConfig = {
            isOpen: true,
            status: 'success',
            heading: 'Asset Custody Verified!',
            content:
              'Custody verification of your asset has been done successfully',
            icon: 'check-circle.svg',
          };
        },
        error: (errorResponse: HttpErrorResponse) => {
          const { error } = errorResponse;
          this.alertBoxConfig = {
            isOpen: true,
            status: 'fail',
            heading: 'Custody Verification Failed!',
            content: error.error?.errorList || 'Something went wrong, try again later',
            icon: 'alert-circle.svg',
          };
        },
      });
    }
  }
}