import { Component, OnInit } from '@angular/core';
import { IErrorConfig, ROUTES } from '@common/interfaces';


@Component({
  selector: 'app-under-maintainance',
  templateUrl: './under-maintainance.component.html',
  styleUrls: ['./under-maintainance.component.scss']
})
export class UnderMaintainanceComponent implements OnInit {

  constructor() { }
  errorObject: IErrorConfig = {
    errorCode: '404 Error!',
    errorMessage: 'Under maintenance',
    errorDescription: 'The page you’re looking for is currently under maintenance and will be back soon. Stay tuned!',
    actionName: 'Take me home',
    navigateTo: ROUTES.SIGN_IN
  }
  ngOnInit(): void {
  }

}
