<div class="modal">
  <div class="modal-header">
    <div class="modal-heading">
      <h1 class="modal-header-title">Request an Access/Software Installation</h1>
      <p class="modal-header-subtitle">
        Request an access/software installation as per your requirements.
      </p>
    </div>

    <button
      class="modal-header-action"
      type="button"
      (click)="openCloseConfirmationDialog()"
    >
      <img src="assets/24x24/close.svg" alt="" />
    </button>
  </div>
  <div class="modal-body height">
    <form class="popup-form" [formGroup]="popupForm">
      <select class="hidden-drop-down" formControlName="location">
        <option value="hidden-select"></option>
      </select>
      <div class="dropdown-wrapper">
        <label class="label">Select Location *</label>
        <app-generic-drop-down
          [options]="locations"
          title="Select Location"
          (value)="getValue($event, 'location')"
          titleName="locationValue"
          valueName="locationKey"
          [hasCaption]="false"
          [shouldEmitdisplayValue]="true"
        ></app-generic-drop-down>
      </div>
      <select class="hidden-drop-down" formControlName="option">
        <option value="hidden-select"></option>
      </select>
      <div class="dropdown-wrapper">
        <label class="label">Select Asset *</label>

        <app-generic-drop-down
        [options]="options"
        title="Select Asset"
        (value)="getValue($event, 'option')"
        (index)="getIndex($event)"
        titleName="assetName"
        [hasCaption]="true"
        captionItem="assetCode"
        [shouldEmitIndex]="true"
        [shouldEmitOption]="true"
      ></app-generic-drop-down>
      </div>

      <div class="dropdown-wrapper">
        <label class="label">Select Category *</label>
      <app-drop-down
      from="select_an_issue"
      [options]="subCategoryOptions"
      titleName="subCategory"
      valueName="id"
      title="Select Type of Issue"
      formArrayName="subCategories"
      (value)="getValue($event, 'subCategory')"
      (index)="getIndex($event)"
      [isDropDownofSelectIssue]="true"
      [disabled]="!isAssetSelected"
    ></app-drop-down>
    </div>
      <label class="label">Describe Your Asset Requirement *</label>
      <app-textarea
        cols="30"
        rows="10"
        formControlName="description"
        characterCounter
        maxLength="255"
        (countEvent)="displayCount($event)"
        [countData]="countData"
        placeholder="Tell us a little more about the requirement for new asset..."
        [isRequired]="true"
      ></app-textarea>
    </form>
  </div>
</div>
<div class="modal-footer">
  <div class="button">
    <app-button
      class="spacing"
      type="submit"
      [disabled]="popupForm.invalid || isDisable"
      size="xsmall"
      theme="primary"
      (click)="onSubmit()"
      >Submit
    </app-button>
  </div>
</div>
