import { Component, Input, OnInit } from '@angular/core';
import { ROUTES } from '@common/interfaces';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss'],
})
export class AdminHeaderComponent implements OnInit {
  constructor() { }
  @Input() coditasRoute!: string;
  @Input() personalRoute!: string;
  @Input() personalTabPermission!: string;
  ngOnInit(): void {}
}
