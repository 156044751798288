<section>
  <app-alert-box
    [config]="snackBarConfig"
    *ngIf="alertBox && snackBarConfig"
    (close)="alertBox = false"
  ></app-alert-box>
  <div class="container">
    <app-back-button></app-back-button>
    <div class="outer-div" *ngIf="!loading" [@basicAnimation]>
      <div>
        <span class="profile-details">Profile Details</span>
      </div>
      <div class="employee-info">
        <div *ngIf="canImageBeShown()">
          <img
            [src]="photoUrl"
            alt="user"
            [ngClass]="{
              'user-image': photoUrl !== placeholderUrl,
              'placeholder-image': photoUrl === placeholderUrl
            }"
            (error)="photoUrl = placeholderUrl"
          />
        </div>
        <div class="employee-details">
          <div class="employee-primary-details">
            <div class="fullName">
              <span class="employee-attribute-fullName">{{
                employeeData["Full Name"]
              }}</span>
            </div>
            <div>
              <span class="employee-attribute-emailId">{{
                employeeData["Email ID"]
              }}</span>
            </div>
          </div>
          <div class="employee-secondary-details">
            <div class="label">
              <span>Employee ID</span> <span class="colon">:</span>
              <span class="employee-attribute-value user-info">{{
                employeeData["Employee ID"]
              }}</span>
            </div>
            <div class="label">
              <span>Contact No.</span> <span class="colon">:</span
              ><span class="employee-attribute-value user-info">{{
                employeeData["Contact No"]
              }}</span>
            </div>
            <div class="label">
              <span>Designation</span> <span class="colon">:</span>
              <span class="employee-attribute-value user-info">{{
                employeeData["Designation"]
              }}</span>
            </div>
            <div class="label">
              <span>Reporting Manager</span><span class="colon">:</span
              ><span class="employee-attribute-value user-info">{{
                employeeData["Reporting Manager"]
              }}</span>
            </div>
          </div>
        </div>
        <div *ngIf="loading">loading...</div>
      </div>
    </div>
  </div>
  <div class="asset-team-container">
    <div class="dash-section-wrapper" *ngIf="isLoanee!">
      <app-user-assets-section [isLoanee]="isLoanee"></app-user-assets-section>
    </div>
  </div>
</section>
