import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFileInfo } from '@common/interfaces';
import { PROGRESS_STATUS } from '@common/interfaces';
@Component({
  selector: 'app-progress-info',
  templateUrl: './progress-info.component.html',
  styleUrls: ['./progress-info.component.scss']
})
export class ProgressInfoComponent implements OnInit {
  @Input() progressInfo!: IFileInfo;
  @Input() hasImageAndVideo: boolean = false;
  @Output() emitId = new EventEmitter<number>();
  PROGRESS_STATUS = PROGRESS_STATUS;

  removeFile(fileId: number) {
    this.emitId.emit(fileId);
  }

  ngOnInit(): void {}
}
