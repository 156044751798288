<div class="modal-header">
  <div class="modal-heading">
    <h1 class="modal-header-title">Download List</h1>
    <p class="modal-header-subtitle">Download list for specific category</p>
  </div>
  <button class="modal-header-action" type="button" (click)="closeDialog()">
    <img src="assets/24x24/close.svg" alt="" />
  </button>
</div>

<div class="form-container">
  <div class="modal-body max-height">
    <form class="popup-form" [formGroup]="popupForm">
      <select class="hidden-drop-down">
        <option value="hidden-select"></option>
      </select>
      <div class="dropdown-wrapper">
        <label class="label">List Type*</label>
        <app-generic-drop-down
          label="Select List Type"
          from="bulk_download_list"
          [options]="listTypeOptions"
          title="Select List Type"
          titleName="title"
          valueName="id"
          (value)="getValue($event, 'list_type_options')"
          [shouldEmitEmittedValue]="true"
        ></app-generic-drop-down>
      </div>
      <div class="dropdown-wrapper">
        <label class="label">List Category*</label>
        <app-generic-drop-down
          label="Select List Category"
          from="bulk_download_list"
          [options]="categoryOptions"
          [title]="
            getCategoryOptionById(popupForm.value.list_category_options) ||
            'Select List Category'
          "
          titleName="title"
          valueName="id"
          (value)="getValue($event, 'list_category_options')"
          [shouldEmitEmittedValue]="true"
        ></app-generic-drop-down>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="button">
      <app-button
        class="spacing"
        type="submit"
        [disabled]="!popupForm.valid || isLoading"
        size="xsmall"
        theme="primary"
        (click)="onSubmit()"
      >
        Download Files
      </app-button>
    </div>
    <div class="button cancel-btn">
      <app-button
        class="width-100"
        size="xsmall"
        theme="secondary"
        (click)="closeDialog()"
        >Cancel
      </app-button>
    </div>
  </div>
</div>
