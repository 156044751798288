<div class="modal-header">
  <div class="modal-header-with-alert-icon add-gap">
    <div
      class="modal-alert-icon {{
        ticketDetails?.isErrorState ? 'danger' : 'success'
      }}"
    >
      <img
        src="assets/24x24/{{
          ticketDetails?.isErrorState ? 'alert-circle.svg' : 'check-circle.svg'
        }}"
        alt=""
      />
    </div>
    <div class="modal-heading">
      <h1 class="modal-header-title">
        {{
          ticketDetails?.isErrorState
            ? "Ticket could not be generated"
            : "Ticket Generated"
        }}
      </h1>
      <p class="modal-header-subtitle">
        {{
          ticketDetails?.isErrorState
            ? "Ticket for reporting an issue could not be generated"
            : "Ticket for reporting an issue is generated successfully"
        }}
      </p>
    </div>
  </div>
  <button class="modal-header-action" type="button" (click)="close()">
    <img src="assets/24x24/close.svg" alt="" />
  </button>
</div>
<div class="modal-body ticket-generate-alert-box">
  <div class="container">
    <div class="width-100">
      <h3>Asset details</h3>
      <div class="row width-100">
        <div class="col">
          <p>Asset Name</p>
          <p>Asset ID</p>
        </div>
        <div class="col">
          <p>:</p>
          <p>:</p>
        </div>
        <div class="col">
          <p>
            {{ ticketDetails?.assetDetails?.assetName || "-" | titlecase }}
          </p>
          <p>
            {{ ticketDetails?.assetDetails?.assetId || "-" }}
          </p>
        </div>
      </div>
    </div>
    <div class="width-100">
      <div class="issue-container">
        <p class="title">Issue Type</p>
        <p>:</p>
        <p>
          {{
            ticketDetails?.ticketSubcategories?.issue ||
              ticketDetails?.issueDescription?.issue
          }}
        </p>

        <p class="title">Description</p>
        <p>:</p>
        <p>
          {{ ticketDetails?.issueDescription?.description || "-" }}
        </p>
      </div>
    </div>
    <div class="width-100" *ngIf="!ticketDetails?.isErrorState">
      <h3>Ticket Details</h3>
      <div class="row width-100">
        <div class="col">
          <p>Ticket no.</p>
        </div>
        <div class="col">
          <p>:</p>
        </div>
        <div class="col">
          <p>
            {{ ticketDetails?.ticketDetails?.ticketNo || "-" }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
