import { DialogRef, DIALOG_DATA, Dialog } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  IFileInfo,
  IPopupConfig,
  PROGRESS_STATUS,
  ROUTES,
  TRANSACTION_STATUS,
} from '@common/interfaces';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { FormServiceService } from 'libs/shared/web/forms/form-service.service';
import { Observable } from 'rxjs';
import { GenericConfirmationPopUpComponent } from '../generic-confirmation-pop-up/generic-confirmation-pop-up.component';
import { GenericPopUpComponent } from '../generic-pop-up/generic-pop-up.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-out-for-repair-modal',
  templateUrl: './out-for-repair-modal.component.html',
  styleUrls: ['./out-for-repair-modal.component.scss'],
})
export class OutForRepairModalComponent {
  assetDetails: any;
  toggleRepairConfirmModal: boolean = true;
  optionsRelatedToTypeOfRepair = ['In-house Repair', 'Send to Vendor'];
  assigneeNames: string[] = [];
  assigneeList: any = [];
  vendorNames: any = [];
  issueList: any[] = [];
  issueArray: string[] = [];
  otherOptionId!: string;
  otherOptionText!: string;
  displayVendorList: boolean = false;
  displayAssigneeList: boolean = false;
  progressInfos: any[] = [];
  assetType!: string;
  issues!: string;
  disableButton: boolean = true;
  selectedVendor!: string;
  selectedAssignee!: string;
  assigneeName!: string;
  imageCount = 0;
  videoCount = 0;
  isValidFiles = true;
  vendorName!: string;
  fileKeys: string[] = [];
  failedFilesProgress: any[] = [];
  unAddedFiles!: IFileInfo[];
  filesArray: IFileInfo[] = [];
  assetId!: string;
  uploadInProgress = false;
  fileTypes = [
    'image/png',
    'image/jpg',
    'image/svg+xml',
    'image/jpeg',
    'video/mp4',
    'video/mpeg',
  ];
  popupForm = new UntypedFormGroup({
    assetId: new UntypedFormControl(null, [Validators.required]),
    subCategories: new UntypedFormControl(this.issueArray, [
      Validators.required,
    ]),
    transactionStatus: new UntypedFormControl('', [Validators.required]),
    transactionReason: new UntypedFormControl('', [
      Validators.required,
      CommonService.cannotContainSpace,
      Validators.minLength(3),
    ]),
    markAssetFor: new UntypedFormControl('', [Validators.required]),
    issueDescription: new FormControl(this.otherOptionText?.trim(), [
      Validators.required,
      CommonService.cannotContainSpace,
      Validators.minLength(3),
    ]),
    file: new UntypedFormControl(''),
  });
  @Output() closeModal = new EventEmitter();

  constructor(
    @Inject(DIALOG_DATA) public data: { assetDetails: any },
    private commonHttpService: CommonHttpService,
    private formService: FormServiceService,
    private dialogRef: DialogRef,
    private commonService: CommonService,
    private dialog: Dialog,
    private router: Router
  ) {}

  ngDoCheck() {
    if (
      this.issueArray.includes(this.otherOptionId) &&
      !this.popupForm?.get('issueDescription')
    ) {
      this.popupForm.addControl(
        'issueDescription',
        new UntypedFormControl('', [
          Validators.required,
          CommonService.cannotContainSpace,
          Validators.minLength(3),
        ])
      );
    } else if (
      !this.issueArray.includes(this.otherOptionId) &&
      this.popupForm?.get('issueDescription')
    ) {
      this.popupForm.removeControl('issueDescription');
    }
  }
  ngOnInit(): void {
    this.assetDetails = this.data;
    this.popupForm.get('assetId')?.setValue(this.assetDetails.assetId);
    this.assetType = this.assetDetails?.assetType;
    const endPoint = `?ticketType=REPAIR/REPLACEMENT&assetTypeId=${this.assetType}`;
    this.commonHttpService.getSubCategories(endPoint).subscribe((response) => {
      this.issueList = [...response.data].map((item) => {
        if (item.subCategory === 'Other') {
          this.otherOptionId = item.id;
        }
        item.isChecked = false;
        return item;
      });
    });
  }
  onCheckOutForRepair() {
    this.popupForm.addControl(
      'uploadedFileKeys',
      new FormControl(this.fileKeys)
    );

    if (this.popupForm.get('markAssetFor')?.value === 'Send to Vendor') {
      this.popupForm.addControl(
        'vendorId',
        new FormControl(this.selectedVendor)
      );
    } else if (
      this.popupForm.get('markAssetFor')?.value === 'In-house Repair'
    ) {
      this.popupForm.addControl(
        'assigneeId',
        new FormControl(this.selectedAssignee)
      );
    }
    this.popupForm.removeControl('markAssetFor');
    const dialogRef = this.dialog.open<string>(
      GenericConfirmationPopUpComponent,
      {
        panelClass: 'center-dialog-box',
        height: '11.75rem',
        backdropClass: 'backdrop',
        disableClose: true,
        data: {
          header: 'Do you want to submit the form?',
          description: 'This action cannot be undone',
          primaryBtn: 'Submit Form',
          secondaryBtn: 'Go Back',
          image: 'cautionImage',
        },
      }
    );
    dialogRef.closed.subscribe((response: any) => {
      if (response.buttonText === 'Submit Form') {
        this.raiseIssue(this.popupForm.value);
      }
    });
  }

  raiseIssue(payload: any) {
    let assetData: any;
    if (this.popupForm.get('assigneeId')?.value) {
      assetData = {
        'Asset Details': {
          'Asset Name': this.assetDetails.assetName,
          'Asset ID': this.assetDetails.assetCode,
        },
        'Issue Description': {
          'Reason for Repair': this.issues,
          Description: this.popupForm.get('transactionReason')?.value,
        },
        'Assignee Details': {
          Assignee: this.assigneeName,
        },
      };
    } else {
      assetData = {
        'Asset Details': {
          'Asset Name': this.assetDetails.assetName,
          'Asset ID': this.assetDetails.assetCode,
        },
        'Issue Description': {
          'Reason for Repair': this.issues,
          Discription: this.popupForm.get('transactionReason')?.value,
        },
        'Vendor Details': {
          Vendor: this.vendorName,
        },
      };
    }
    this.commonHttpService.checkInOrCheckOut(payload).subscribe({
      next: () => {
        const dialogRef = this.dialog.open(GenericPopUpComponent, {
          panelClass: 'center-dialog-box',
          backdropClass: 'backdrop',
          width: '50%',
          data: {
            headerIcon: 'success',
            title: 'Check Out Successful',
            subtitle:
              'Asset has been checked out for maintenance successfully.',
            data: assetData,
            secondaryButtonText: 'View Asset Details',
            primaryButtonText: 'Proceed to Dashboard',
          } as IPopupConfig,
        });
        dialogRef.closed.subscribe((result) => {
          this.disableButton = true;
          if (result === 'Proceed to Dashboard') {
            this.router.navigate([ROUTES.ASSET_TEAM_DASHBOARD]);
          } else if (result === 'View Asset Details') {
            this.router.navigate([
              ROUTES.ASSET_DETAILS + this.assetDetails.assetId,
            ]);
          }
          this.closeDialog();
        });
      },
      error: (error) => {
        const dialogRef = this.dialog.open(GenericPopUpComponent, {
          panelClass: 'center-dialog-box',
          backdropClass: 'backdrop',
          width: '50%',
          data: {
            headerIcon: 'error',
            title: 'Check Out Unsuccessful',
            subtitle:
              error?.error?.error?.errorList ||
              'Looks like you lost your connection. Please check your connection and try again. ',
            data: assetData,
            secondaryButtonText: 'Try Again',
            primaryButtonText: 'View Asset Details',
          } as IPopupConfig,
        });
        dialogRef.closed.subscribe((result) => {
          if (result === 'Try Again') {
            this.raiseIssue(this.popupForm.value);
          }
        });
      },
    });
  }
  closeDialog() {
    this.popupForm.reset();
    this.dialogRef.removePanelClass('dialog-box');
    this.dialogRef.addPanelClass('close-dialog');
    const current = this;
    setTimeout(() => {
      current.dialogRef.close();
    }, 1000);
  }

  getTypeOfRepair(repairType: string) {
    this.popupForm.get('markAssetFor')?.setValue(repairType);
    repairType === 'In-house Repair'
      ? this.getAssigneeList()
      : this.getVendorList();
    if (this.popupForm.get('markAssetFor')?.value === 'Send to Vendor') {
      this.popupForm
        .get('transactionStatus')
        ?.setValue(TRANSACTION_STATUS.CHECKOUT_OUT_FOR_REPAIR);
    } else if (
      this.popupForm.get('markAssetFor')?.value === 'In-house Repair'
    ) {
      this.popupForm
        .get('transactionStatus')
        ?.setValue(TRANSACTION_STATUS.CHECKOUT_IN_HOUSE_REPAIR);
    }
    this.disableButton = true;
  }
  getVendorList() {
    this.displayVendorList = true;
    this.displayAssigneeList = false;
    this.commonHttpService.getAllVendorNames().subscribe({
      next: (response) => {
        this.vendorNames = response.data;
      },
    });
  }
  getOtherIssue(otherIssue: string) {
    this.popupForm.get('issueDescription')?.setValue(otherIssue.trim());
    this.otherOptionText = otherIssue;
  }
  getAssigneeList() {
    this.displayAssigneeList = true;
    this.displayVendorList = false;
    this.formService.getAdmins().subscribe((res: any) => {
      res.data.forEach((user: any) => {
        this.assigneeNames.push(user.firstName + ' ' + user.lastName);
        this.assigneeList.push({
          assigneeId: user.userId,
          assigneeName: user.firstName + ' ' + user.lastName,
        });
      });
    });
  }
  onSelectVendor(vendor: string) {
    this.vendorName = vendor.split('  ')[0];
    this.selectedVendor = vendor.split('  ')[1];
    this.disableButton = false;
  }
  onSelectAssignee(assigneeName: string) {
    this.assigneeList.forEach((assignee: any) => {
      if (assignee.assigneeName === assigneeName) {
        this.selectedAssignee = assignee.assigneeId;
        this.assigneeName = assigneeName;
      }
    });
    this.disableButton = false;
  }
  onSelectIssue(issue: string) {
    this.popupForm.get('issue')?.setValue(issue);
  }
  close() {
    this.toggleRepairConfirmModal = false;
    this.popupForm.reset();
    this.progressInfos[0] = {};
    this.dialogRef.removePanelClass('dialog-box');
    this.dialogRef.addPanelClass('close-dialog');
    const current = this;
    setTimeout(() => {
      current.dialogRef.close();
    }, 1000);
  }
  getValue(value: string, type: string) {
    if (type === 'subCategory') {
      this.issueArray = [...value[0]];
      this.popupForm.get('subCategories')?.setValue(this.issueArray);
      this.issues = value[1];
    }
  }

  getIndex(index: number) {
    const endPoint = `?ticketType=REPAIR/REPLACEMENT&assetTypeId=${this.assetType}`;
    this.commonHttpService.getSubCategories(endPoint).subscribe((response) => {
      this.issueList = [...response.data].map((item) => {
        item.isChecked = false;
        return item;
      });
    });
  }

  async selectedFiles(files: IFileInfo[]) {
    if (files.length) {
      for (const fileData of files) {
        await this.uploadFile([fileData]);
        // await this.uploadFile([fileData], files);
      }
    }
  }

  async uploadFile(files: IFileInfo[]) {
    this.uploadInProgress = true;

    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (this.shouldUploadFile(file) && !this.isAlreadyUploaded(file)) {
        await this.uploadAndHandleFile(file);
      } else {
        files[index].showProgressBar = false;
      }
    }
  }

  shouldUploadFile(file: IFileInfo): boolean {
    return (
      file.progressStatus !== PROGRESS_STATUS.FAILED &&
      file.progressValue !== 100
    );
  }

  async uploadAndHandleFile(file: IFileInfo) {
    this.uploadInProgress = true;

    const subscription = this.commonService.getProgress().subscribe((value) => {
      file.progressValue = value;
      file.showRemoveFileBtn = false;
    });

    try {
      const fileInfo = [
        {
          originalname: file.originalname,
          mimetype: file.mimetype,
          size: file.size,
        },
      ];
      const loadRequest: Observable<Object> =
        this.commonHttpService.uploadFiles(fileInfo);
      this.uploadInProgress = false;
      subscription?.unsubscribe();
      loadRequest.subscribe({
        next: async (response: any) => {
          if (this.shouldUploadFile(file) && !this.isAlreadyUploaded(file)) {
            file.progressValue = 50;
            const s3response = await this.handleFileUpload(
              response.presignedUrls,
              [file]
            );

            if (response.presignedUrls.length === s3response.length) {
              file.progressValue = 100;
              file.showRemoveFileBtn = false;
              file.progressStatus = PROGRESS_STATUS.SUCCESS;

              const tempFileKeys = response.presignedUrls[0].key;
              this.fileKeys.push(tempFileKeys);
            }
          }
        },
        error: () => {
          file.showRemoveFileBtn = true;
          file.progressStatus = PROGRESS_STATUS.FAILED;
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  isAlreadyUploaded(file: IFileInfo): boolean {
    return file.progressValue === 100;
  }

  handleFileUpload(
    preSignedUrls: { key: string; presignedUrl: string }[],
    files: IFileInfo[]
  ) {
    return this.commonHttpService.uploadFilesToPresignedUrls(
      preSignedUrls,
      files
    );
  }
}
