<div class="d-flex justify-content-between align-items-center">
  <nav class="pages">
    <ul class="pagination">
      <li class="page-item">
        <app-button
          type="button"
          theme="tertiary"
          size="xsmall"
          class="page-link"
          [disabled]="currentPageNumber === 1"
          (click)="prev()"
        >
          <img
            class="left-arrow"
            src="assets/20x20/back.svg"
            alt=""
            [ngClass]="{ disabled: currentPageNumber === 1 }"
          />
          Previous
        </app-button>
      </li>

      <div class="page-number">
        <li
          class="page-item"
          *ngFor="let page of pager.pages; trackBy: trackByFn; let i = index"
        >
          <ng-container *ngIf="currentPageNumber === page; else notActive">
            <app-button
              type="button"
              theme="secondary"
              size="xsmall"
              class="page-link custom-disabled active"
            >
              {{ page }}
            </app-button>
          </ng-container>
          <ng-template #notActive>
            <app-button
              type="button"
              theme="tertiary"
              size="xsmall"
              class="page-link notActive"
              [borderNone]="true"
              (click)="getData(page)"
              [ngClass]="{ 'custom-disabled': page === -1 }"
            >
              {{ page === -1 ? "..." : page }}
            </app-button>
          </ng-template>
        </li>
      </div>

      <li class="page-item">
        <app-button
          type="button"
          theme="tertiary"
          size="xsmall"
          class="page-link"
          [disabled]="currentPageNumber === totalPages"
          (click)="next()"
        >
          Next
          <img
            class="right-arrow"
            src="assets/20x20/next.svg"
            alt=""
            [ngClass]="{ disabled: currentPageNumber + 1 > totalPages }"
          />
        </app-button>
      </li>
    </ul>
  </nav>
</div>
