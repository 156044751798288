<div class="main-container">
  <div class="center-div">

    <div class="heading-support">

      <p class="supporting-text">Test User Login</p>
    </div>
    <form
    class="form-container"
      [formGroup]="testSignInForm"
      (ngSubmit)="login()"
    >
    <div class="field">
      <label for="" class="label">First Name *</label>
      <app-text-field
        [required]="true"
        for="firstName"
        id="firstName"
        type="firstName"
        name="firstName"
        formControlName="firstName"
        label=""
        placeholder="Please enter first name"
      >
      </app-text-field>
    </div>
    <div class="field">
      <label for="" class="label">Last Name *</label>
      <app-text-field
        [required]="true"
        for="lastName"
        id="lastName"
        type="lastName"
        name="lastName"
        formControlName="lastName"
        label=""
        placeholder="Please enter last name"
      >
      </app-text-field>
    </div>

      <div class="field">
        <label for="" class="label">Email Address *</label>
        <app-text-field
          [required]="true"
          for="email"
          id="email"
          type="email"
          name="email"
          formControlName="email"
          label=""
          placeholder="Please enter your email address"
        >
        </app-text-field>
      </div>

      <app-button
        class="spacing margin-top"
        type="submit"
        size="xsmall"
        theme="primary"
        >Submit</app-button
      >
    </form>
  </div>


</div>
