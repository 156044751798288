import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  ASSET_SORT_COLUMNS,
  ILoaneeSuggestions,
  ISearchData,
} from '@common/interfaces';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { Store } from '@ngrx/store';
import { maintainFilterType, maintainSearchInput, reset } from 'store/search-state/search.action';
import { selectFilterBy, selectSearchString } from 'store/search-state/search.selector';
import { ISearchState, searchState } from 'store/search-state/search.state';


@Component({
  selector: 'app-search-section',
  templateUrl: './search-section.component.html',
  styleUrls: ['./search-section.component.scss'],
  styles: ['.highlight { color: #000}'],
})
export class SearchSectionComponent implements OnInit {
  @Input() searchOptions!: { title: string; value: string }[];
  @Output() searchData = new EventEmitter<ISearchData>();
  index = '';
  searchStr!: string;
  activeCol!: any;
  suggestionList!: ILoaneeSuggestions[];
  filterBy!: any
  placeholder = "Enter the search text";
  placeholders: any = {
    "TICKET_NUMBER": "Enter the ticket number to search",
    "TICKET_DATE": "Enter the ticket date to search",
    "ASSET_NAME": "Enter the asset name to search",
    "ASSET_TAG": "Enter the asset tag to search",
    "LOANEE": "Enter the loanee to search",
    "ASSIGNED_TO": "Enter the assignee's name for search",
    "TICKET_RAISED_BY": "Enter the name of the person who raised the ticket"
  };
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private httpService: CommonHttpService,
    private domSanitizer: DomSanitizer,
    private store: Store<ISearchState>
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {

      if (params['assetType']) {
        this.store.dispatch((maintainSearchInput({ searchStr: params['assetType'] })))
        this.store.dispatch((maintainFilterType({
          filterBy: 'ASSET_NAME'
        })))
      }
      const searchStr = this.store.select(selectSearchString)
      searchStr?.subscribe((response) => {
        this.searchStr = response;
      })

      const filterBy = this.store.select(selectFilterBy);
      filterBy?.subscribe((response) => {
        this.filterBy = response
      })

      this.activeCol =
        this.searchOptions.filter(
          (option) => option.value === this.filterBy
        ) || false;

      this.search()
    });
  }

  changeIndex(event: any) {
    this.index = event;
    this.placeholder = this.placeholders[this.index] || ''
  }


  search() {
    this.searchData.emit({
      filterBy:
        this.index || this.activeCol[0]?.value || this.searchOptions[0].value,
      searchStr: this.searchStr,
    });
    if (this.searchStr !== undefined) {
      this.store.dispatch((maintainSearchInput({ searchStr: this.searchStr })))
      this.store.dispatch((maintainFilterType({
        filterBy: this.index || this.activeCol[0]?.value || this.searchOptions[0].value
      })))
    }
  }

  @HostListener('document:keyup.enter', ['$event']) onEnter() {
    this.search();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const suggestionList = document.getElementById('suggestionList');
    if (suggestionList && !suggestionList.contains(target)) {
      this.suggestionList = [];
    }
  }

  removeClick() {
    this.searchStr = '';
    this.activeCol = this.searchOptions;
    this.store.dispatch(reset())

    this.search();
  }

  searchLoanee() {
    if (this.index === ASSET_SORT_COLUMNS.LOANEE) {
      this.httpService.getUserByEmail(this.searchStr).subscribe({
        next: (value) => {
          this.suggestionList = Object(value)
            .data.slice(0, 4)
            .map((val: any) => ({
              id: val.id,
              name: `${val.firstName} ${val.lastName}`,
              email: val.email,
              employeeId: val.employeeId,
            }));
        },
      });
    }
  }

  getHighlightedText(text: string, highlight: string) {
    const regex = new RegExp(`(${highlight})`, 'gi');
    const highlightedText = text.replace(
      regex,
      '<span style="color: #000;">$1</span>'
    );
    return this.domSanitizer.bypassSecurityTrustHtml(highlightedText);
  }

  onSuggestionSelect(suggestion: ILoaneeSuggestions) {
    this.searchStr = suggestion.email;
    this.suggestionList = [];
    this.search();
  }
}
