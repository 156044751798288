<div
  class="toaster-container"
  [scrollTop]="alertElement.scrollIntoView({ behavior: 'smooth', block: 'start' })"
  [ngClass]="{ 'has-margin': config.hasMargin }"
  [@basicAnimation]
  #alertElement
>
  <div
    class="alert"
    [ngStyle]="{ 'background-color': config.color }"
    [ngClass]="{
      'success-border': config.status === 'success',
      'fail-border': config.status === 'fail'
    }"
  >
    <div class="alert-content">
      <img src="assets/12x12/{{ config.icon }}.svg" alt="as" class="image" />
      <div class="alert-box-text">
        <span class="text" [ngClass]="{ fail: config.status === 'fail' }">{{
          config.content
        }}</span>
        <a
          href="#"
          *ngIf="config.linkText && config.ticketId"
          class="view-ticket"
          (click)="$event.preventDefault(); navigateToTicket(config.ticketId)"
          >{{ config.linkText }}</a
        >
      </div>
    </div>
    <button
      class="close-button"
      [ngStyle]="{ 'background-color': config.color }"
    >
      <img
        class="icon"
        src="assets/24x24/close.svg"
        (click)="closeAlertBox()"
      />
    </button>
  </div>
</div>
<!-- <div
  class="alert"
  [ngStyle]="{ 'background-color': background }"
  [ngClass]="{
    'success-border': status === 'success',
    'fail-border': status === 'fail'
  }"
>
  <div class="alert-content">
    <img src="assets/12x12/{{ icon }}.svg" alt="as" class="image" />
    <ng-content></ng-content>
  </div>
  <button class="close-button" [ngStyle]="{ 'background-color': background }">
    <img class="icon" src="assets/24x24/close.svg" (click)="closeAlertBox()" />
  </button>
</div> -->
