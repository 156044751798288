import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-ticket-generate-alert-modal',
  templateUrl: './ticket-generate-alert-modal.component.html',
  styleUrls: ['./ticket-generate-alert-modal.component.scss'],
})
export class TicketGenerateAlertModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  ticketDetails!: any;

  constructor() { }

  ngOnInit(ticket?: any): void {
    if (ticket) {
      this.ticketDetails = ticket.popupAlertConfig;
    }
  }

  close() {
    this.closeModal.emit('ticket-generate-alert-modal');
  }
}
