<div class="sidenav-container">
  <div class="top-section">
    <div
      class="nav-section"
      *ngFor="let option of navigationOption"
      routerLinkActive="activeOption"
    >
      <a
        class="options"
        [routerLink]="option.route"
        routerLinkActive="activeNavigation"
        (click)="clearSessionStorage()"
      >
        <img [src]="option.img" alt="" class="image" width="19" />
        <span class="text">{{ option.label }}</span>
      </a>
    </div>
    <cdk-accordion>
      <cdk-accordion-item
        #accordionItem="cdkAccordionItem"
        role="button"
        [attr.aria-expanded]="accordionItem.expanded"
        [expanded]="helpExpanded"
      >
        <div class="nav-section">
          <div
            class="options"
            (click)="accordionItem.toggle()"
            [ngClass]="accordionItem.expanded ? 'active' : ''"
            [ngClass]="accordionItem.expanded ? 'accordion-header-text' : ''"
          >
            <img [src]="questionMarkIcon" alt="" class="image" width="19" />
            <div class="first-accordion-item">
              <span class="text">Help</span>
              <div>
                <img
                  *ngIf="!accordionItem.expanded"
                  [src]="accordionIcon"
                  alt=""
                  class="accordion-image"
                />
                <img
                  *ngIf="accordionItem.expanded"
                  [src]="accordionOpenIcon"
                  alt=""
                  class="accordion-image"
                />
              </div>
            </div>
          </div>
          <div
            role="region"
            [style.display]="accordionItem.expanded ? '' : 'none'"
            *ngFor="let helpOption of helpMenuOptions"
            routerLinkActive="activeOption"
            class="nav-section"
            [ngClass]="accordionItem.expanded ? 'accordion-element' : ''"
          >
            <a
              class="options"
              [routerLink]="helpOption.route"
              routerLinkActive="activeNavigation"
            >
              <img
                [src]="helpOption.img"
                alt=""
                class="image help-option-image"
              />
              <span class="text">{{ helpOption.label }}</span>
            </a>
          </div>
        </div>
      </cdk-accordion-item>
    </cdk-accordion>
  </div>
  <div class="bottom-section">
    <div class="row">
      <div class="user-details">
        <img
          [src]="userData.photo_url"
          alt="user"
          class="user-image"
          (error)="userData.photo_url = 'assets/40x40/profile-photo.svg'"
        />
        <div class="supporting-text">
          <a (click)="goToEmployeeDetails()" class="user-name"
            >{{ userData.firstName | titlecase }}
            {{ userData.lastName | titlecase }}</a
          >
          <span [title]="userData.email">{{
            userData.email.length > 27
              ? (userData.email | slice : 0 : 27) + "..."
              : userData.email
          }}</span>
        </div>
      </div>
      <div class="bottom-corner">
        <app-button
          [disabled]="false"
          size="xsmall"
          theme="tertiary"
          (click)="logOut()"
        >
          <img src="assets/16x16/logout.svg" alt="log out" class="image" />
          Log Out
        </app-button>
      </div>
    </div>
  </div>
</div>
