<div class="asset-team-container">
  <ng-container *stessaHasPermission="'BULK-UPLOAD__ALL_TYPES_STATS'">
    <h1 class="heading">Types of Lists for Bulk Upload</h1>
    <div class="dash-section-wrapper">
      <div class="data-container" [@basicAnimation]>
        <div class="row">
          <div class="col">
            <h3>User List</h3>
            <h1>{{ cardCounts.userListCount }}</h1>
          </div>
          <div class="col border"></div>
          <div class="col">
            <h3>Asset List</h3>
            <h1>{{ cardCounts.assetListCount }}</h1>
          </div>
          <div class="col border"></div>
          <div class="col">
            <h3>Asset Allocation List</h3>
            <h1>{{ cardCounts.assetAllocationListCount }}</h1>
          </div>
          <div class="col border"></div>
          <div class="col">
            <h3>Vendor List</h3>
            <h1>{{ cardCounts.vendorListCount }}</h1>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="loader-container">
    <div class="row">
      <div
        class="col dash-section-wrapper"
        [@basicAnimation]
        *stessaHasPermission="'BULK-UPLOAD__UPLOAD_LIST'"
      >
        <h3>Upload List</h3>
        <div class="icon-section">
          <img src="assets/32x32/upload.svg" alt="" width="33" />
        </div>
        <div class="link" (click)="triggerModal('bulk-upload-modal')">
          <a>Click here to upload list</a>
        </div>
      </div>
      <div
        class="col dash-section-wrapper"
        [@basicAnimation]
        *stessaHasPermission="'BULK-UPLOAD__DOWNLOAD_LIST'"
      >
        <h3>Download List</h3>
        <div class="icon-section">
          <img src="assets/32x32/download.svg" alt="" width="33" />
        </div>
        <div class="link" (click)="triggerModal('bulk-download-modal')">
          <a>Click here to download list / template</a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- bulk upload alert modal -->
<!-- <app-modal [open]="toggleBulkUploadAlertModal" width="43.75rem">
  <app-bulk-upload-alert-modal (closeModal)="close()" #bulkUploadAlertModal>
  </app-bulk-upload-alert-modal>
</app-modal> -->
