import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { ROUTES } from '@common/interfaces';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let url: string = state.url;
    return this.checkUserRole(next, url);
  }

  logOut() {
    localStorage.clear();
  }

  checkUserRole(route: ActivatedRouteSnapshot, url: any) {
    const userData = JSON.parse(localStorage.getItem('userInfo') as string);
    const token = localStorage.getItem('token') as string;
    if (!userData) {
      this.router.navigate([ROUTES.SIGN_IN])
      return false
    }
    if (Object.keys(userData)?.length && route?.data['role'] && token) {
      return true;
    }
    this.router.navigate([ROUTES.SIGN_IN]);
    return false;
  }
}
