<div class="comments-section">
  <div *ngFor="let commentData of allComments; let i = index">
    <div class="line-of-date" *ngIf="setDateForCommentSection(commentData, i)">
      <hr />
      <span class="date">
        {{ commentData?.createdAt | date : "dd MMMM, yyyy" }}</span
      >
      <hr />
    </div>
    <div
      *ngIf="commentData?.user?.id == userId"
      class="comments"
      [ngClass]="{
        sender: true
      }"
    >
      <div class="comments-name-time">
        <h4 class="name">
          {{ commentData?.user?.firstName }}
          {{ commentData?.user?.lastName }}
        </h4>
        <small class="time" *ngIf="commentData?.createdAt">
          {{ commentData?.createdAt | date : "hh:mm a" }}
        </small>
      </div>
      <p class="comment">
        {{ commentData.comment }}
      </p>
    </div>
    <div
      *ngIf="commentData.user.id != userId"
      class="comments"
      [ngClass]="{ rec: true }"
    >
      <div class="comments-name-time">
        <h4 class="name">
          {{ commentData?.user?.firstName }}
          {{ commentData?.user?.lastName }}
        </h4>
        <small class="time" *ngIf="commentData?.createdAt">
          {{ commentData?.createdAt | date : "hh:mm a" }}
        </small>
      </div>
      <p class="comment">
        {{ commentData.comment }}
      </p>
    </div>
  </div>
</div>
