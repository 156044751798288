<div class="file-container">
  <label class="label">
    <input type="file" class="file-input" [multiple]="multiple" #fileField />
    <div class="browse-icon">
      <div class="icon-bg">
        <img src="assets/46x46/file-upload.svg" alt="" />
      </div>
      <div class="file-upload">
        <div class="file-upload-text">
          <span class="upload">Click to upload</span>
          <span class="drag-drop">or drag and drop</span>
        </div>
        <div class="valid-file"><ng-content></ng-content></div>
      </div>
    </div>
  </label>
</div>
