import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ITicketSnackbarConfig, ROUTES } from '@common/interfaces';


@Component({
  selector: 'app-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss'],
  animations: [
    trigger('basicAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class AlertBoxComponent implements OnInit {
  constructor(private router: Router) { }
  @Input() config!: ITicketSnackbarConfig;
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  private isAdmin: boolean =
    JSON.parse(localStorage.getItem('userInfo') || '{}')?.roles[0] ===
    'ADMIN';
  ngOnInit(): void {
    setTimeout(() => {
      this.closeAlertBox();
    }, 3000);
  }

  closeAlertBox() {
    this.close.emit(false);
  }

  navigateToTicket(ticketId: string) {
    const route = `${ROUTES.TICKET_DETAILS}${ticketId}`;
    this.router.navigate([route]);
  }
}
