import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactAdminComponent } from './contact-admin/contact-admin.component';
import { UiComponentsModule } from '@shared/ui-components';
import { ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [ContactAdminComponent],
  imports: [CommonModule, UiComponentsModule, ReactiveFormsModule],
  exports: [ContactAdminComponent],
})
export class FormsModule {}
