import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-bulk-upload-alert-modal',
  templateUrl: './bulk-upload-alert-modal.component.html',
  styleUrls: ['./bulk-upload-alert-modal.component.scss'],
})
export class BulkUploadAlertModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  uploadCounts!: any[];
  isAllFailed: boolean = false;
  constructor() { }

  ngOnInit(response?: any[]): void {
    if (response) {
      const failedCount = response.filter(
        (each) => each.status === 'fail'
      ).length;
      this.isAllFailed = response.length === failedCount ? true : false;
      this.uploadCounts = response;
    }
  }

  close() {
    this.closeModal.emit('ticket-generate-alert-modal');
  }
}
